import Query from "@arcgis/core/rest/support/Query";
import Point from "@arcgis/core/geometry/Point";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            selectedParcel: 'atlas/layer-manager/selectedParcel',
        }),
    },
    methods: {
        ...mapActions({
            getSelectedParcel: 'atlas/layer-manager/getSelectedParcel'
        }),
        queryFeat(layer, cadastralNumber, siruta) {
            const query = new Query({
                outFields: ["*"],
                where: "cadastral_number = '"+ cadastralNumber + "' AND uat_name = '" + siruta +"'" ,
                returnGeometry: true,
            });

            return  new Promise((resolve) => {
                layer.queryFeatures(query)
                .then((featureSet) => {
                    const result = featureSet.features;
                    let pointResult = null;
                    if(result.length) {
                        result.forEach(element => {
                            if (element.geometry.type ===  'point') {
                                pointResult = element.geometry;
                            }
                        });
                        if (pointResult) {
                            let pt = new Point({
                                latitude: pointResult.latitude,
                                longitude: pointResult.longitude
                            })
                            resolve(pt)
                        }
                    }
                })
                .finally(()=> {
                    resolve();
                })
            });
        },
        checkRequestedLayerForPreviewParcel() {
            const cadastralNumber = this.$route.query.cadastral_number;
            const siruta = this.$route.query.siruta;
            const app_type_id = this.$route.query.app_type;
            if (cadastralNumber && siruta && app_type_id) {
                const matchedLayer = Object.values(this.initialSystemLayers).find(v => v.application_type == app_type_id);

                let matchedLayerLoaded;
                if (matchedLayer) {
                    matchedLayerLoaded = this.loadedPinpointLayers.some(v => v.url == matchedLayer.feature_service_url);

                    if (!matchedLayerLoaded) {
                        this.showLayer(matchedLayer);
                    }
                }
            }
        },
        loadAtlasPreviewParcel() {
            const cadastralNumber = this.$route.query.cadastral_number;
            const siruta = this.$route.query.siruta;
            if (cadastralNumber && siruta) {
                const promises = [];
                let selectedParcel = null;
                for (let j = this.loadedPinpointLayers.length - 1; j >= 0; j--) {
                    const layer = this.loadedPinpointLayers[j];
                    const prms =  new Promise((resolve, reject) => {
                        layer.load()
                        .then( async ()=> {
                            const result = await this.queryFeat(layer, cadastralNumber, siruta);
                            if (result) selectedParcel = result;
                            resolve();
                        })
                        .catch((error) => reject(error))
                    })
                    promises.push(prms);
                }
                Promise.all(promises)
                .then(()=> {
                    if (selectedParcel) {
                        let opts = {
                            duration: 4000
                        };
                        this.mapObj.view.goTo({
                            target: selectedParcel,
                            zoom: 10
                        }, opts);
                    }
                    else {
                        this.getSelectedParcel();
                        const area = {
                            area: this.selectedParcel.coordinates,
                            title: this.selectedParcel.cadastral_number,
                            notes: this.selectedParcel.cadastral_number,
                            text: this.selectedParcel.text,
                        }
                        this.loadArea(area, true);
                    }
                })
            }
        },
    }
}
