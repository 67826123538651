import { AtlasService } from '@/modules/atlas/atlas-service';
import Errors from '@/shared/error/errors';

// action types
export const GET_GIS_TOKEN = 'getGisToken';

// mutation types
export const SET_GIS_TOKEN = 'setGisToken';

const state = {
    token: null,
};

const getters = {
    token: (state) => state.token,
};

const actions = {
    async [GET_GIS_TOKEN](context, isStaff) {
        context.commit('shared/activateLoading', 'gis-token/get', { root: true });
        try {
            const response = await AtlasService.getGisToken(isStaff);
            context.commit(SET_GIS_TOKEN, response.token);
            return response.token;
        } catch(error) {
            Errors.handle(error);
            return error;
        } finally {
            context.commit('shared/deactivateLoading', 'gis-token/get', { root: true });
        }
    },
};

const mutations = {
    [SET_GIS_TOKEN](state, token) {
        state.token = token;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
