<template>
    <div class="parcel-search" :hidden="hideSidebarSection">
        <hr/>
        <div v-if="!isAtlasPublicView">
            <p>
                <i class="fas fa-search"></i>
                {{ $t("ATLAS.PARCEL_SEARCH.TITLE") }}
            </p>
        </div>
        <form class="form" novalidate="novalidate" id="st_parcel_search_form">
            <parcel-search-form
                v-if="loaded"
                :isMobileDevice="isMobileDevice"
                :isSidebar="true"
                ref="parcelSearchForm"
            />
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ParcelSearchForm from '@/modules/atlas/components/parcel-search/ParcelSearchForm.vue';

export default {
    name: 'ParcelSearchSidebarContainer',
    components: {
        ParcelSearchForm
     },
     data() {
        return {
            model: null,
            loaded: false,
        };
    },
    computed: {
        ...mapGetters({
            isAtlasPublicView: 'shared/isAtlasPublicView',
        }),
        isMobileDevice() {
            return KTUtil.isMobileDevice()
        },
        isLayerManager() {
            return this.$route.name == 'layerManager';
        },
        hideSidebarSection() {
            return !this.isAtlasPublicView && !this.isLayerManager;
        },
    },
    methods: {
        ...mapActions({
            initState: 'atlas/parcels/doInit',
        }),
    },
    async created() {
        await this.initState();
        this.loaded = true;
    },
};
</script>

<style lang="scss" scoped>
    .parcel-search {
        margin: 12px 6%;
    }
</style>
