<template>
    <div :class="['arcgis-app-item', selectedAppId == app.id ? 'atlas-app-active' :'' ]">
        <div class="arcgis-app-name"
            :class="{'cursor-pointer': true}"
            @click="openApp(app)"
        >
            <i :class="['fas', 'fa-map', 'layer-icon',  selectedAppId == app.id ? 'atlas-app-active' :'']"></i>
            <span>{{ app.app_name }}</span>
        </div>
        <div class="arcgis-app-actions">
            <b-dropdown
                v-if="isStaff"
                class="button-more-action"
                variant="link"
                no-caret
                menu-class="button-more-action-menu"
            >
                <template #button-content>
                    <i class="fas fa-ellipsis-h"></i>
                </template>
                <b-dropdown-item-button @click="editApp">{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.EDIT') }}</b-dropdown-item-button>
                <b-dropdown-item-button @click="deleteApp">{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.DELETE') }}</b-dropdown-item-button>
            </b-dropdown>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "AppItem",
    props: {
        app: {
            type: Object,
            required: true,
        },
        selectedAppId: {
          type: String,
          default: () => null,
      },
        isInputFocus: {
            type: String,
        },
        openFocus: {
            type: String,
        },
    },
    data() {
        return {
            type: 'primary',
            model: null,
        }
    },
    computed: {
        ...mapGetters({
            isStaff: 'auth/isStaff',
            loading: 'shared/loading',
        }),
        isLoading() {
            return false;
        },
        dropdownStyleClasses(){
            const classes = [];
            if (this.isLoading) {
                classes.push('spinner spinner-right');
            }
            return classes;
        },
    },
    mounted() {
    },
    methods: {
        ...mapActions({
            showArcgisAppEditModal: 'atlas/arcgis-apps/showAppEditModal',
            deleteArcgisApp: 'atlas/arcgis-apps/deleteArcgisApp',
            selectArcgisApp: 'atlas/arcgis-apps/selectArcgisApp',
        }),
        openApp(app) {
            // this.selectArcgisApp(this.app);
            window.open(app.app_url, '_blank');
        },
        editApp() {
            this.showArcgisAppEditModal(this.app);
        },
        deleteApp() {
            this.$alert({
                type: 'error',
                    title: this.$t('ATLAS.LAYER_MANAGER.DELETE_ARCGIS_APP_MODAL_TITLE'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                       this.deleteArcgisApp(this.app);
                    }
                });
        },
    }
}
</script>

<style lang="scss">
    .atlas-app-active {
        color:#286AE4;
    }
</style>
