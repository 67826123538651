import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class DecisionField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            (params = {}) => {
                return new Promise((resolve) => {
                    if (params.hasOwnProperty('parent_id') && params.parent_id === '') {
                        resolve([]);
                    } else {
                        const query = params?.parent_id ? `purpose_id eq '${params.parent_id}'` : '';
                        ApiService.query('decisions', { 
                            params: {
                                query,
                                limit: 100
                            }
                        }).then(({ data }) => {
                            resolve(data);
                        });
                    }
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    ...record,
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
