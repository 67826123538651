import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';
import { storeAsync } from '@/app-module';

export class SimpleLayerField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        const store = storeAsync();
        return new RelationToOneField(
            name,
            label,
            (params) => {
                const layers = store.getters['atlas/layer-manager/layers'];
                const creationRequestId = layers.find((el) => el.id === params.parent_id)?.creation_request_id;
                return new Promise((resolve) => {
                    if (creationRequestId) {
                        ApiService.query('layers/me', {params: { query: `creation_request_id eq ${creationRequestId}`}})
                        .then(({ data }) => {
                            resolve(data[0].components)
                        });
                    } else  resolve([]);
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
