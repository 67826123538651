import { AtlasService } from '@/modules/atlas/atlas-service';
import Errors from '@/shared/error/errors';

// action types
export const ADD_COMMENT = 'addComment';
export const DELETE_COMMENT = 'deleteComment';
export const GET_COMMENTS = 'getComments';
export const SHOW_COMMENTS = 'showComments';
export const HIDE_COMMENTS = 'hideComments';
export const RESET_COMMENTS = 'resetComments';
export const SET_NEW_COMMENT_COORDINATES = 'setNewCommentCoordinates';
export const SET_GIS_EXTENT = 'setExtent';

// mutation types
export const SET_COMMENTS = 'setComments';
export const SET_COMMENTS_VISIBILITY = 'setCommentsVisibility';
export const SET_PARENT_ID = 'setParentId';
export const SET_SELECTED_COMMENTS = 'selectComments';
export const ADD_TO_SELECTED_COMMENTS = 'addToSelectedComments';
export const RESET_SELECTED_COMMENTS  = 'resetSelectedComments';
export const SET_COORDINATES = 'setCoordinates';
export const UPDATE_GIS_EXTENT = 'updateGisExtent';

const state = {
    comments: [],
    selectedComments: [],
    commentsVisible: false,
    parentId: null,
    newCommentCoordinates: null,
    commentsExtent: null,
};

const getters = {
    comments: (state) => state.comments,
    commentsVisible: (state) => state.commentsVisible,
    parentId: (state) => state.parentId,
    selectedComments: (state) => state.selectedComments,
    newCommentCoordinates: (state) => state.newCommentCoordinates,
    commentsExtent: (state) => state.commentsExtent,
};

const actions = {
    [SHOW_COMMENTS](context) {
        context.commit(SET_COMMENTS_VISIBILITY, true);
    },
    [HIDE_COMMENTS](context) {
        context.commit(SET_COMMENTS_VISIBILITY, false);
        context.commit(RESET_SELECTED_COMMENTS);
    },
    [RESET_COMMENTS](context) {
        context.commit(RESET_SELECTED_COMMENTS);
    },
    [GET_COMMENTS](context, data) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/getLayerComments', { root: true });

            const {parentId, extent} = data;

            if(parentId) {
                context.commit(SET_PARENT_ID, parentId);
            }

            AtlasService.getLayerComments(parentId, extent)
                .then(async (response) => {
                    if(parentId) {
                        // add to current selected comments thread
                        context.commit(SET_SELECTED_COMMENTS, response.data);
                    } else {
                        // add to root comments
                        context.commit(SET_COMMENTS, response.data);
                    }
                    resolve(response.data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/getLayerComments', { root: true });
                });
        });
    },
    [ADD_COMMENT](context, data) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/addComment', { root: true });

            AtlasService.addLayerComment(data)
                .then(async (comment) => {
                    if(data.parent_id) {
                        // add to existing comment thread
                        context.commit(SET_PARENT_ID, data.parent_id);
                    } else {
                        // add to fresh comment thread
                        context.commit(SET_PARENT_ID, comment.id);
                    }
                    resolve(comment);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/addComment', { root: true });
                });
        });
    },
    [DELETE_COMMENT](context, data) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/deleteComment', { root: true });

            const {id, isRootComment} = data;

            AtlasService.deleteLayerComments(id)
                .then(async (result) => {
                    if(isRootComment) {
                        context.commit(RESET_SELECTED_COMMENTS);
                    }
                    resolve(result);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/deleteComment', { root: true });
                });
        });
    },
    [SET_NEW_COMMENT_COORDINATES](context, coords) {
        context.commit(SET_COORDINATES, coords);
    },
    [SET_GIS_EXTENT](context, extent) {
        context.commit(UPDATE_GIS_EXTENT, extent);
    },
};

const mutations = {
    [SET_COMMENTS](state, comments) {
        state.comments = comments;
    },
    [SET_COMMENTS_VISIBILITY](state, isVisible) {
        state.commentsVisible = isVisible;
    },
    [SET_PARENT_ID](state, parentId) {
        state.parentId = parentId;
    },
    [SET_SELECTED_COMMENTS](state, comments) {
        const rootComment = [state.comments.find(v => v.id == state.parentId)];
        state.selectedComments = rootComment.concat(comments);
    },
    [ADD_TO_SELECTED_COMMENTS](state, comment) {
        state.selectedComments = state.selectedComments.append(comment);
    },
    [RESET_SELECTED_COMMENTS](state) {
        state.parentId = null;
        state.selectedComments = [];
        state.newCommentCoordinates = null;
    },
    [SET_COORDINATES](state, coords) {
        state.newCommentCoordinates = coords;
    },
    [UPDATE_GIS_EXTENT](state, extent) {
        state.commentsExtent = extent;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
