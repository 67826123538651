<template>
    <div>
        <form
            class="form"
            novalidate="novalidate"
            :id="formId"
            v-if="layer"
        >
            <div v-if="!isLayerContainer">
                <SimpleLayerEdit
                    :tableFields="fields"
                    :items="items"
                    :containerItems="containerItems"
                    :layers="layers"
                    :layer="layer"
                />
            </div>
            <div class="data-list row" v-else>
                <div class="col data-table" :class="[isAddLayerSelected || isRowSelected || isEditMode? 'col-4' : 'col-12']">
                    <div class="">
                        <button
                            v-if="isRowSelected || isEditMode"
                            class="expand-btn btn btn-sm px-0 btn-icon btn-bg-light btn-text-primary btn-hover-primary"
                            v-b-tooltip.hover.top
                            :title="$t('GENERAL.EXTEND')"
                            @click.prevent="expandLayersTable()"
                        >
                            <i class="fas fa-angle-double-right text-primary "></i>
                        </button>
                    </div>
                    <b-table
                        v-if="layer.layer_type === 'container'"
                        :items="containerItems"
                        :fields="containerFieldsToggle"
                        :hover="true"
                        @row-clicked="onRowClicked"
                        selectable
                        select-mode="single"
                        class="st-data-table mb-0"
                        thead-tr-class="st-data-table-head-row"
                        tbody-class="st-data-table-body"
                        tbody-tr-class="st-data-table-body-row"
                        head-variant="light"
                        table-variant="light"
                        ref="layers_table"
                        responsive
                    >
                        <template #cell()="data">
                            <span v-if="!!data.field.booleanType"
                                :class="{'label-success label custom-label': data.value}"
                            >
                                <i v-if="data.value" class="fas fa-check text-light"></i>
                            </span>
                            <span v-else class="d-block text-truncate"> {{data.value}}</span>
                        </template>
                        <template #cell(actions)="data">
                            <span v-for="(action,i) in actions" v-bind:key="i">
                                <b-button
                                    :class="`btn btn-icon btn-light btn-hover-${action.type} st-btn-data-table`"
                                    @click="doAction(action, data)"
                                    v-b-tooltip.hover="{ variant: 'info' }"
                                    :title="action.tooltipText"
                                    :disabled="!!action.disabled || data.item.status === 'Nou'"
                                >
                                    <span :class="`st-data-table-icon svg-icon svg-icon-md  svg-icon-${action.type}`">
                                        <i :class="`fas fa-${action.icon}`"></i>
                                    </span>
                                </b-button>
                            </span>
                        </template>
                    </b-table>

                    <st-button
                        v-if="isLayerContainer && !isEditLayerActive"
                        size="md"
                        variant="link"
                        :callback="addLayer"
                    >
                        <i class="fa fa-plus text-primary font-size-xs" v-if="!isAddLayerSelected"></i>
                        <span>{{buttonText}}</span>
                    </st-button>
                </div>
                <div class="col col-8 data-table"
                     v-if="isRowSelected || !isLayerContainer">
                    <div v-if="items.length" class="ml-6 pl-6">
                         <b-table :items="items" :fields="fields"></b-table>
                    </div>
                    <div v-else class="text-center my-6">
                        <span class="font-size-h5">
                            {{ $t('ATLAS.SUBLAYER_MANAGER.EMPTY_SUBLAYERS_TEXT') }}
                        </span>
                    </div>
                </div>
                <div class="col col-8 pl-8" v-if="isEditMode">
                    <ContainerSimpleLayerForm
                        :id="formId"
                        :selectedLayer="layer"
                        :selectedComponent="selectedComponent"
                        :editMode="isEditMode"
                        ref="container_simple_layer_form"
                    />
                </div>
                <transition name="fade">
                    <div v-if="isAddLayerSelected" :class="[isAddLayerSelected ? 'col-8' : 'col-6']">
                        <ContainerSimpleLayerForm
                            :id="formId"
                            :selectedLayer="layer"
                            ref="container_simple_layer_form"
                        />
                </div>
                </transition>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Message from '@/shared/message/message';
import { generateRandomId } from "@/core/helpers/globalMethods";
import ContainerSimpleLayerForm from "@/modules/atlas/components/layer-manager/ContainerSimpleLayerForm";
import SimpleLayerEdit from "@/modules/atlas/components/layer-manager/SimpleLayerEdit";
import { VBTooltip } from 'bootstrap-vue'

export default {
  inheritAttrs: false,
  name: "LayerEditModalForm",
  components: {
        ContainerSimpleLayerForm,
        SimpleLayerEdit
  },
  props: {
    isVisible: {
        type: Boolean,
        default: false,
    },
    isSaveActive: {
        type: Boolean,
        default: false
    },
    isEditLayerActive: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      formId: `st_modal_edit_layer_form-${generateRandomId()}`,
      containerFields: [
          { key: 'name', label: this.$t('ATLAS.EDIT_MODAL.LAYER_NAME') },
          { key: 'access_level', label: this.$t('ATLAS.EDIT_MODAL.ACCESS_LEVEL') },
          { key: 'owner_org_name', label: this.$t('ATLAS.EDIT_MODAL.OWNER') },
          { key: 'uat_name', label: this.$t('ATLAS.EDIT_MODAL.UAT') },
          { key: 'ru_scope', label: this.$t('ATLAS.EDIT_MODAL.RU_SCOPE') },
          { key: 'status', label: this.$t('ATLAS.EDIT_MODAL.STATUS') },
          { key: 'is_template_layer', label: this.$t('ATLAS.EDIT_MODAL.TEMPLATE') ,  booleanType: true},
          { key: 'actions', label: this.$t('GENERAL.TABLE.ACTIONS') },
      ],
      fields: [
          { key: 'name', label: this.$t('ATLAS.EDIT_MODAL.SUBLAYER_NAME') },
          { key: 'access_level', label: this.$t('ATLAS.EDIT_MODAL.ACCESS_LEVEL') },
          { key: 'type', label: this.$t('ATLAS.EDIT_MODAL.TYPE') },
        ],
        actions: [
           {
                name: 'edit',
                icon: 'edit',
                tooltipText: this.$t('ACCOUNTS.LIST.EDIT'),
                customIcon: false,
                type: 'primary',
            },
            {
                name: 'delete',
                tooltipText: this.$t('INSTITUTION.FUNCTIONS.TOOLTIPS.DELETE'),
                icon: 'trash-alt',
                customIcon: false,
                type: 'danger'
            }
        ],
        selectedLayer: null,
        isRowSelected: false,
        isAddLayerSelected: false,
        isEditMode: false,
        containerFieldsToggle: [],
        selectedComponent: {},
        polling: null,
        creationStatus: null,
    };
  },
  directives:{'b-tooltip': VBTooltip},
  computed: {
    ...mapGetters({
        loading: "shared/loading",
        isLayerEditModalVisible: 'atlas/layer-manager/isLayerEditModalVisible',
        layer: 'atlas/layer-manager/editingLayer',
        accountDetails: "auth/accountDetails",
        layers: 'atlas/layer-manager/layers',
        isStaff: 'auth/isStaff',
    }),
    isLayerContainer() {
        return this.layer?.layer_type === 'container'
    },
    // Sublayers
    items() {
        let layer = [];
        let sublayers = [];
        if (this.layer.layer_type === 'simple') {
            layer = this.layer;
            sublayers = this.layer?.sublayers;
        } else {
            layer = this.selectedLayer;
            sublayers = this.selectedLayer?.sublayers;
        }

        if (!sublayers) return []
        return sublayers.map((el) => ({
            id: el.id,
            name: el.name,
            access_level: layer.access_level,
            type: 'Poligon',
        }))
    },
    // Layers
    containerItems() {
      if (!this.layer.components) return [];
      return this.layer.components.map((el) => {
        const layer = {
          id: el.id,
          name: el.name,
          access_level: el.access_level,
          type: 'Poligon',
          owner_org_name: el.owner_org_name,
          uat_name: el.uat_name || '',
          ru_scope: this.enumLabel('ru_scope',el.ru_scope),
          sublayers: el.sublayers,
          status: this.enumLabel('status',el.status),
          is_template_layer: el?.is_template_layer ?? '',
        };
        return layer;
      });
    },
    isLoading() {
      return this.loading['atlas-layer-manager/update'];
    },
    buttonText() {
        return !this.isAddLayerSelected ?
            this.$t('ATLAS.BUTTONS.ADD_SIMPLE_LAYER') :
            this.$t('ATLAS.BUTTONS.CLOSE_SIMPLE_LAYER')
    }
  },
  watch: {
    isAddLayerSelected(value) {
        this.$emit('update:isSaveActive', value);
        if(!value) {
            if ( this.isRowSelected) {
                this.containerFieldsToggle = this.containerFields.slice(0,3);
            } else {
                this.$nextTick(()=>{
                    this.containerFieldsToggle = this.containerFields;
                })
            }
        } else {
            this.$refs['layers_table'].clearSelected();
            this.isRowSelected = false;
        }
    },
    isRowSelected(value) {
        if(!value) {
            this.containerFieldsToggle = this.containerFields;
        } else {
            this.isAddLayerSelected = false;
            this.isEditMode = false;
            this.$nextTick(()=>{
                this.containerFieldsToggle = this.containerFields.slice(0,3);
            })
        }
    },
    isEditMode(value) {
        if(value) {
            this.containerFieldsToggle = this.containerFields.slice(0,3);
        } else {
            this.containerFieldsToggle = this.containerFields;
        }
    }
  },
  created() {
        this.selectedLayer = null;
        this.containerFieldsToggle = this.containerFields;
  },
  methods: {
    ...mapActions({
        hideEditModal: "atlas/layer-manager/hideEditModal",
        addLayerToContainer: "atlas/layer-manager/addLayerToContainer",
        updateLayerToContainer: "atlas/layer-manager/updateLayerToContainer",
        doUpload: "atlas/layer-manager/upload",
        getLayers: "atlas/layer-manager/getLayers",
        doDeleteLayer: 'atlas/layer-manager/deleteSimpleLayer',
        updateLayerFromContainer: 'atlas/layer-manager/updateLayerFromContainer',
        getLayerCreationStatus: "atlas/layer-manager/getLayerCreationStatus",
    }),
    beforeDestroy() {
        if (this.polling) {
            clearInterval(this.polling);
        }
    },
    async doSubmit() {
        const formRef = this.$refs.container_simple_layer_form;
        const fv = formRef.fv;
        const uniqueName = this.containerItems.some((el) => {
            // In edit mode, same name is accepted
            if (this.isEditMode && el.id === formRef.model.id) return false;
            return el.name === formRef.model.name
        });
        if (uniqueName) {
           fv.resetField('name');
           fv.addField('name', {
                validators: {
                    callback: {
                        message: this.$t('ATLAS.EDIT_MODAL.DUPLICATE_LAYER_NAME'),
                        callback: (input)=> {
                            return !this.containerItems.some((el) => el.name === input.value);
                        },
                    },
                }
            });
            fv.revalidateField('name');
        }
        const validate = await formRef.fv.validate();
        if (validate === "Valid") {
            if (this.isEditMode) {
                this.doUpdateSimpleLayer(formRef);
            } else {
                this.doAddLayer(formRef);
            }
        }
    },
    doUpdateSimpleLayer(formRef) {
        const formModel = formRef.model;
        const formFields = formRef.fields;
        formModel.layer_type = 'simple';
        let { id, ...layer} = formModel;
        if (!layer.uat_name) {
            layer.uat_name = formFields.uat_id.options.find(f => f.value === layer.uat_id)?.text;
        }
        if (!layer.owner_org_name) {
            layer.owner_org_name = formFields.owner_org_id.options.find(f => f.value === layer.owner_org_id)?.text;
        }
        this.updateLayerFromContainer({ id, layer})
        .then((data)=> {
            const index = this.layer.components.findIndex(comp => comp.id === data.id);
            this.$set(this.layer.components, index, data);
            this.$notify({
                type: 'success',
                message: this.$t('GENERAL.UPDATED_SUCCESS'),
            });
            this.getLayers(this.isStaff);
        })
    },
    async doAddLayer(formRef) {
        const formModel = formRef.model;
        const formFields = formRef.fields;
        formModel.layer_type = 'simple';
        let { id, ...layer} = formModel;
        if (!layer.owner_org_name) {
            layer.owner_org_name = formFields.owner_org_id.options.find(f => f.value === layer.owner_org_id)?.text;
        }
        try {
            const creationRequest = await this.addLayerToContainer(layer);

            this.polling = setInterval(() => {
                this.checkLayerCreationStatus(creationRequest.data.id);
            }, 1000);
        } catch (error) {
            formRef.fv.resetForm();
            this.isAddLayerSelected = true;
        }
    },
    async checkLayerCreationStatus(id) {
      try {
        const { status } = await this.getLayerCreationStatus(id);
        this.creationStatus = status;

        if (status === "completed") {
            this.isAddLayerSelected = false;

            await this.getLayers(this.isStaff);

            const refreshedUpdatedLayer = this.layers.find((v) => v.id == this.layer.id);
            this.$set(this.layer, 'components', refreshedUpdatedLayer.components);

            Message.success('GENERAL.CREATED_SUCCESS');
        }
      } catch (error) {
            clearInterval(this.polling);
      } finally {
        if (this.creationStatus !== "new" && this.creationStatus !== "pending") {
            clearInterval(this.polling);
        }
      }
    },
    onRowClicked(item, index, event) {
        this.selectedLayer = item;
        this.isRowSelected = true;
        this.$nextTick(()=>{
           this.containerFieldsToggle = this.containerFields.slice(0,3);
        })
    },
    addLayer() {
        this.isAddLayerSelected = !this.isAddLayerSelected;
        this.$nextTick(()=>{
            this.containerFieldsToggle = this.containerFields.slice(0,3);
        })
    },
    doAction(action, data) {
        this[`${action.name}Layer`](data);
    },
    deleteLayer(data) {
        this.$alert({
            type: 'error',
                title: this.$t('ATLAS.LAYER_MANAGER.DELETE_MODAL_TITLE'),
                text: this.$t('ATLAS.LAYER_MANAGER.DELETE_MODAL_SUBTITLE'),
                confirmButtonText: this.$t('GENERAL.YES'),
                cancelButtonText: this.$t('GENERAL.NO'),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.doDeleteLayer(data.item)
                    .then(()=> {
                        this.$delete(this.layer.components, data.index);
                        this.getLayers(this.isStaff);
                    })
                }
            });
    },
    editLayer(data) {
        this.$emit('update:isEditLayerActive', true);
        this.isEditMode = true;
        this.selectedComponent = data.item;
    },
    expandLayersTable() {
        this.isRowSelected = false;
        this.isEditMode = false;
        this.$emit('update:isEditLayerActive', false);
        this.$refs['layers_table'].clearSelected();
    },
    enumLabel (name, value) {
        return this.$t(`ATLAS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
    }
  },
};
</script>
<style lang="scss">
    .expand-btn {
        position: absolute;
        top: 0px;
        right: -20px;
        border: 1px solid #E2E6EA;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background-color: #ffffff;
        z-index: 100;
    }
</style>
