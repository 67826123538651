<template>
    <div class="arcgis-apps">
        <div :class="['arcgis-apps-header', isVisibiltySettingsVisible ? 'd-flex justify-content-between' :'' ]">
            <div class="arcgis-apps-title">
                <i
                    :class="groupVisibilityClass"
                    @click="toggleGroupVisibility"></i>
                <span>{{ $t(`ATLAS.LAYER_MANAGER.${id.toUpperCase()}_LABEL`) }}</span>

                <st-button
                    v-if="isStaff"
                    v-show="isVisible"
                    size="small"
                    style="margin-left:1rem;"
                    :callback="showAddArcgisAppModal"
                >
                    <i class="fa fa-plus"></i>
                    {{ $t('ATLAS.ARCGIS_APPS_MODAL.ADD_NEW_APPLICATION') }}
                </st-button>
            </div>
        </div>

        <div class="arcgis-apps-body" v-cloak v-show="isVisible">
            <template v-for="app in apps">
                <arcgis-app-item
                    :app="app"
                    :key="app.id"
                    :group="id"
                    :selectedAppId="selectedAppId"
                    :isInputFocus.sync="isInputFocus"
                    :openFocus = "openFocus"
                />
            </template>
            <div v-if="!apps.length" class="ml-6">{{ $t('ATLAS.LAYER_MANAGER.EMPTY_ARCGIS_APPS_TEXT') }}</div>
        </div>
    </div>
</template>
<script>

import ArcgisAppItem from "@/modules/atlas/components/arcgis-apps-manager/ArcgisAppItem";

import { mapGetters, mapActions } from 'vuex';
export default {
  name: "ArcgisApps",
  components: {
      ArcgisAppItem,
  },
  props: {
      id: {
          type: String,
          required: true,
      },
      selectedArcgisApp: {
          type: Object,
          default: () => {},
      },
      apps: {
        type: Array,
        default: () => [],
      },
  },
  data() {
      return {
          isVisible: true,
          isContainerOpen: {},
          isInputFocus: '',
          openFocus: ''
      }
  },
  computed: {
    ...mapGetters({
        isStaff: "auth/isStaff",
        isVisibiltySettingsVisible: 'atlas/sidebar/isVisibiltySettingsVisible',
    }),
    groupVisibilityClass() {
        return this.isVisible ? 'fas fa-caret-down' : 'fas fa-caret-right';
    },
    selectedAppId() {
        return this.selectedArcgisApp?.id ?? null;
    },
  },
  watch: {
      isInputFocus(val) {
        this.openFocus = val;
      }
  },
  methods: {
      ...mapActions({
          doOpenContainer: 'atlas/layer-manager/openContainer',
          showAddArcgisAppModal: 'atlas/arcgis-apps/showAppAddModal',
      }),
      toggleGroupVisibility() {
          this.isVisible = !this.isVisible;
      },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
</style>
