<template>
  <st-modal
    ref="modal"
    hide-header-delimiter
    hide-footer-delimiter
    hideable
    :size="modalSize"
    :title="title"
    id="intersection_modal"
  >
    <template #body>
        <div v-if="!isUrbanRegulationsSelected">
            <div class="font-size-h6 text-dark-50" v-if="pointOfIntersection">
                {{ `${pointOfIntersection.x}, ${pointOfIntersection.y}` }}
            </div>
            <div class="row">
                <template v-for="(intersectedFeature, index) in intersectedLayers">
                    <div v-if="!intersectedFeature.isIndexedSystemLayer" class="col-3" :key="index">
                        <IntersectionDetailsCard :item="intersectedFeature"/>
                    </div>
                </template>
            </div>
        </div>
        <div v-else>
            <IntersectionUrbanRegulationCard
                v-if="!display"
                :urbanismRegulations="urbanismRegulations"
                :selectedSublayer="selectedSublayer"
                @selectedRegulation="selectedUrbanismRegulation"
            ></IntersectionUrbanRegulationCard>
            <IntersectionUrbanRegulationCardDetails
                v-if="display"
                :urbanismRegulation="selectedRegulation"
            ></IntersectionUrbanRegulationCardDetails>
        </div>
    </template>
    <template #footer>
        <div class="d-flex justify-content-start w-100">
             <st-button
                variant="link"
                class="font-size-h6"
                :callback="hide"
            > {{ $t('GENERAL.BUTTON.CANCEL') }}
            </st-button>
        </div>
    </template>
  </st-modal>
</template>

<script>
import { mapGetters } from "vuex";
import IntersectionDetailsCard from "./IntersectionDetailsCard";
import IntersectionUrbanRegulationCard from "./IntersectionUrbanRegulationCard";
import IntersectionUrbanRegulationCardDetails from "./IntersectionUrbanRegulationCardDetails";

export default {
  name: "IntersectionDetailsModal",
  components: {
    IntersectionDetailsCard,
    IntersectionUrbanRegulationCard,
    IntersectionUrbanRegulationCardDetails
  },
  props: {
      isUrbanRegulationsSelected: {
          type: Boolean,
          required: true,
      },
      selectedSublayer: {
        type: Object,
        default: () => {}
      }
  },
  computed: {
    ...mapGetters({
      intersectedLayers: "atlas/layer-intersection/layers",
      pointOfIntersection: "atlas/layer-intersection/pointOfIntersection",
      urbanismRegulations: "atlas/layer-intersection/urbanismRegulations",
    }),
    title() {
        return !this.isUrbanRegulationsSelected ?
            this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.HEADER'):
            this.selectedSublayer?.display_name ?? this.selectedSublayer.rlu_id;
    },
    modalSize() {
      return (!this.isUrbanRegulationsSelected || this.display) ? 'xxl' : 's';
    },
    display() {
      return Object.keys(this.selectedRegulation).length;
    }
  },
  data() {
    return {
      selectedRegulation: {}
    }
  },
  methods: {
    show() {
      this.$refs["modal"].show();
    },
    hide() {
      if (Object.keys(this.selectedRegulation).length) {
        this.selectedRegulation = {};
      } else {
        this.$refs["modal"].hide();
      }
    },
    selectedUrbanismRegulation(item) {
      this.selectedRegulation = item;
    }
  },
};
</script>

