import layerManagerStore from '@/modules/atlas/store/layer-manager-store';
import sidebarStore from '@/modules/atlas/store/sidebar-store';
import gisTokenStore from '@/modules/atlas/store/gis-token-store';
import interestAreasStore from '@/modules/atlas/store/interest-areas-store';
import layerIntersectionStore from '@/modules/atlas/store/layer-intersection-store';
import visibilitySettingsStore from '@/modules/atlas/store/visibility-settings-store';
import commentsStore from '@/modules/atlas/store/comments-store';
import arcgisAppsStore from '@/modules/atlas/store/arcgis-apps-store';
import ancpiStore from '@/modules/atlas/store/ancpi-store';

export default {
    namespaced: true,
    modules: {
        'layer-manager': layerManagerStore,
        sidebar: sidebarStore,
        token: gisTokenStore,
        'interest-areas': interestAreasStore,
        'layer-intersection': layerIntersectionStore,
        'visibility-settings': visibilitySettingsStore,
        'comments': commentsStore,
        'arcgis-apps': arcgisAppsStore,
        'parcels': ancpiStore,
    },
};
