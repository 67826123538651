<template>
  <div>
    <!-- <div class="row">
      <div class="col-12">
        <label> {{ fields.app_id.label }} * </label>
        <div class="form-group">
          <st-input-text
            v-model="model[fields.app_id.name]"
            :ref="fields.app_id.name"
            :name="fields.app_id.name"
          />
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <label> {{ fields.app_name.label }} * </label>
        <div class="form-group">
          <st-input-text
            v-model="model[fields.app_name.name]"
            :ref="fields.app_name.name"
            :name="fields.app_name.name"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label> {{ fields.app_url.label }} * </label>
        <div class="form-group">
          <st-input-text
            v-model="model[fields.app_url.name]"
            :ref="fields.app_url.name"
            :name="fields.app_url.name"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label> {{ fields.access_level.label }} * </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.access_level.name]"
            :ref="fields.access_level.name"
            :field="fields.access_level"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { createFormValidation } from "@/shared/utils/create-form-validation";
import { FormSchema } from "@/shared/form/form-schema";
import { ArcgisAppModel } from "@/modules/atlas/models/arcgis-app-model";

const { fields } = ArcgisAppModel;

const formSchema = new FormSchema([
  // fields.app_id,
  fields.app_url,
  fields.app_name,
  fields.access_level,
]);

export default {
  inheritAttrs: false,
  name: "ArcgisAppForm",
  props: {
    id: {
      type: String,
      required: true,
    },
    editedApp: {
      type: Object,
      default: () => ({ }),
    },
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: {
        app_id: '',
        app_url: '',
        app_name: '',
        access_level: 'private',
      },
      fields,
      fv: null,
    };
  },
  // watch: {},
  created() {
    this.model = formSchema.initialValues(this.editedApp);
  },
  mounted() {
    this.fv = createFormValidation(this.id, this.rules);
  },
};
</script>
