import { camelCaseToDash } from '@/core/helpers/globalMethods';
import * as rendererJsonUtils from "@arcgis/core/renderers/support/jsonUtils";
import LabelClass from "@arcgis/core/layers/support/LabelClass";


const stringFieldOptionMap = {
    textbox: 'text-box',
    textarea: 'text-area',
    richtext: 'rich-text',
 }
 const mediaTypes = {
    piechart: 'pie-chart',
    barchart: 'bar-chart',
    columnchart: 'column-chart',
    linechart: 'line-chart',
    image: 'image'
 }

export default {
    data() {
        return {

        }
    },
    methods: {
        configurePopupTemplate({layersLoaded, layerList}) {
            const promises = [];
            const serviceDataLayers = {}
            layersLoaded.forEach(layer => {
                const prms = new Promise((resolve,reject) => {
                    this.getLayerPopupInfo({layer, gisToken: this.token})
                    .then((data)=> {
                        serviceDataLayers[layer.gis_layer_id] = {
                          layerId: layer.id,
                          layers: data.layers,
                          currentLayerId: layer.gis_layer_id
                        }
                        resolve()
                    })
                    .catch((error) => reject(error));
                })
                promises.push(prms);
            });
            Promise.all(promises)
            .finally(()=> {
                Object.keys(serviceDataLayers).forEach((layerLoaded) => {
                    const loadedLayer = layerList[layerLoaded];

                    Object.keys(loadedLayer ?? {}).forEach((layer) => {
                        const index = parseInt(layer);
                        const selectedServiceDataLayers = serviceDataLayers[layerLoaded].layers;
                        if (selectedServiceDataLayers) {
                            const content = selectedServiceDataLayers.find(item => item.id == index)?.popupInfo;

                            if(!content) {
                                return;
                            }

                            let fieldInfos;
                            if(content.popupElements?.length > 0 && content.popupElements[0]?.type == 'fields') {
                                fieldInfos =  content.popupElements[0].fieldInfos;
                            } else {
                                fieldInfos =  content.fieldInfos;
                            }

                            let mediaInfos;
                            if(content.popupElements?.length > 0 && content.popupElements[0]?.type == 'media') {
                                mediaInfos =  content.popupElements[0].mediaInfos;
                            } else {
                                mediaInfos =  content.mediaInfos;
                            }

                            const mappedFieldInfos = fieldInfos.map((field) => {
                                if (field?.format?.dateFormat) field.format.dateFormat = camelCaseToDash(field.format.dateFormat)
                                return {
                                    ...field,
                                    stringFieldOption: stringFieldOptionMap[field.stringFieldOption]
                                }
                            });

                            const contentList = [{
                                    type: "fields",
                                    fieldInfos: mappedFieldInfos
                            }];

                            if (mediaInfos.length) {
                                mediaInfos.forEach(media => {
                                    media.type = mediaTypes[media.type]
                                });
                                const mediaInfoObj = {
                                    type: "media",
                                    mediaInfos
                                }
                                contentList.push(mediaInfoObj);
                            }

                            loadedLayer[layer].popupTemplate = {
                                title: content.title,
                                content: contentList,
                                actions: null
                            }
                        }
                    })
                });
            });
        },
        configureStyles(atlasLayer, sublayerList) {
            this.getLayerPopupInfo({layer: atlasLayer, gisToken: this.token})
            .then((data)=> {
                const layerDefinitions = data?.layers ?? [];

                layerDefinitions.forEach((layerDef) => {
                    if(!layerDef.layerDefinition?.drawingInfo) return;

                    if(layerDef.layerDefinition.drawingInfo.renderer) {
                        sublayerList[layerDef.id].renderer = rendererJsonUtils.fromJSON(layerDef.layerDefinition.drawingInfo.renderer);
                    }

                    if(layerDef.layerDefinition.drawingInfo.labelingInfo) {
                        let labelArr = [];
                        layerDef.layerDefinition.drawingInfo.labelingInfo.forEach((labelJson) => {
                            labelArr.push(LabelClass.fromJSON(labelJson));
                        })

                        sublayerList[layerDef.id].labelingInfo = labelArr;
                    }

                    if(layerDef.layerDefinition.drawingInfo.transparency) {
                        sublayerList[layerDef.id].opacity = 1 - (parseInt(layerDef.layerDefinition.drawingInfo.transparency) / 100);
                    }
                });
            })
        },
    }
}
