<template>
  <b-card class="st-section mt-6">
    <div class="st-section-header">
      <div class="st-section-header-title">
        {{ `${infoLayerText}${item.name}` }}
      </div>
      <div class="st-section-header-actions d-none">
        <st-button variant="link" size="sm" :callback="view">
          <i class="fas fa-eye" />
        </st-button>
      </div>
    </div>
    <div class="st-section-body">
      <div class="info-body">
        <div class="info-body-title font-weight-bold mb-2">
          {{ `${$t("ATLAS.INTERSECTION_MANAGER.DETAILS_TEXT")}:` }}
        </div>
        <div class="row mb-2" v-if="item.isApplication">
          <div class="col-6">
            {{ $t("ATLAS.INTERSECTION_MANAGER.APPLICATION_TYPE") }}
          </div>
          <div class="col-6">{{ item.application_type || "Imobil" }}</div>
        </div>
        <div class="row mb-2" v-if="item.isApplication">
          <div class="col-6">
            {{ $t("ATLAS.INTERSECTION_MANAGER.APPLICATION_STATUS") }}
          </div>
          <div class="col-6">{{ applicationStatus(item) }}</div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            {{ $t("ATLAS.INTERSECTION_MANAGER.SIRUTA_LABEL") }}
          </div>
          <div class="col-6">{{ item.uat_id }}</div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            {{ $t("ATLAS.INTERSECTION_MANAGER.UAT_LABEL") }}
          </div>
          <div class="col-6">{{ item.uat_name }}</div>
        </div>
        <div class="row mb-2" v-if="!item.isApplication">
          <div class="col-12">
            {{
              `${$t("ATLAS.INTERSECTION_MANAGER.URBANISM_REGULATION_LABEL")}:`
            }}
          </div>
           <div
            class="col-12 pl-8"
            v-for="(sublayer, index) in item.sublayers"
            :key="index"
            >
            <div class="mt-2" v-if="sublayer.rlu_id">
                {{sublayer.rlu_id }}
            </div>
        </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment";

export default {
  name: "IntersectionDetailsCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      appConfig: "shared/appConfig",
    }),
    infoLayerText() {
      if (this.item?.isApplication) return "";
      return `${this.$t("ATLAS.INTERSECTION_MANAGER.INFO_LAYER_TEXT")} `;
    },
    infoItemText() {
      if (this.item?.authorization_date)
        return this.$t("ATLAS.INTERSECTION_MANAGER.CU_INFO_LAYER_TEXT");
      if (this.item?.authorization_request_date)
        return this.$t(
          "ATLAS.INTERSECTION_MANAGER.AUTHORIZATION_INFO_LAYER_TEXT"
        );
      return this.$t("ATLAS.INTERSECTION_MANAGER.INFO_LAYER_TEXT");
    },
  },
  methods: {
    view() {
        // TODO: View handler
    },
    formattedDate(date) {
      return moment(new Date(date)).format(this.appConfig.DATE_FORMAT);
    },
    applicationStatus(item) {
      const status = item?.application_status?.toUpperCase();
      return this.$t(`ATLAS.INTERSECTION_MANAGER.APPLICATION_STATUSES.${status}`);
    },
    applicationIssuedDate(item) {
      const date = item?.application_issued_date || item?.application_request_date;
      return this.formattedDate(date);
    }
  },
};
</script>

<style>
</style>
