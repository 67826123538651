import Errors from '@/shared/error/errors';
import { AncpiService } from "@/shared/services/ancpi.service";

// action types
export const DO_INIT_STATE = 'doInit';
export const DO_SEARCH_PARCEL = 'searchParcel';
export const DO_REMOVE_PARCEL = 'doRemoveParcel';
export const DO_SET_PARCEL_SEARCH_VISIBILITY = "doSetParcelSearchVisibility";
export const DO_SET_PARCEL_SEARCH_EXPAND_REF = "doSetParcelSearchExpandRef";
export const DO_SET_PARCEL_LAYER_REF = "doSetParcelLayerRef";

// mutations
export const INIT_STATE = 'init';
export const SET_PARCEL = "setParcel";
export const REMOVE_PARCEL  = "removeParcel"
export const SET_PARCEL_SEARCH_VISIBILITY = "setParcelSearchVisibility"
export const SET_PARCEL_SEARCH_EXPAND_REF  = "setParcelSearchExpandRef"
export const SET_PARCEL_LAYER_REF  = "setParcelLayerRef"

const getDefaultState = (state) => {
    return {
        parcels: state.parcels || [],
        parcelSearchVisible: false,
        parcelSearchExpandRef: null,
        parcelLayerRef: null,
    };
};

const state = getDefaultState({});

const getters = {
    parcels: (state) => state.parcels,
    parcelLayerRef: (state) => state.parcelLayerRef,
    parcelSearchVisible: (state) => state.parcelSearchVisible,
    parcelSearchExpandRef: (state) => state.parcelSearchExpandRef,
};

const actions = {
    async [DO_INIT_STATE](context) {
        const persistedParcelsSearchHistory = await AncpiService.getPersistedSearchHistory();
        context.commit(INIT_STATE, { parcels: persistedParcelsSearchHistory });
    },
    async [DO_SEARCH_PARCEL](context, payload) {
        context.commit("shared/activateLoading", "atlas/searchParcel", { root: true });
        try {
            const {topo_number, siruta_code, county_name, uat_name} = payload;
            const parcel = await AncpiService.searchParcel({
                topo_number,
                siruta_code,
            });
            if (parcel?.data?.coordinates?.length) {
                context.commit(SET_PARCEL, {...parcel.data, county_name, uat_name});
            }
            return parcel;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit("shared/deactivateLoading", "atlas/searchParcel", { root: true });
        }
    },
    [DO_SET_PARCEL_SEARCH_VISIBILITY](context, payload) {
        context.commit(SET_PARCEL_SEARCH_VISIBILITY, payload);
    },
    [DO_SET_PARCEL_SEARCH_EXPAND_REF](context, payload) {
        context.commit(SET_PARCEL_SEARCH_EXPAND_REF, payload);
    },
    [DO_SET_PARCEL_LAYER_REF](context, payload) {
        context.commit(SET_PARCEL_LAYER_REF, payload);
    },
    [DO_REMOVE_PARCEL](context, payload) {
        context.commit(REMOVE_PARCEL, payload);
    },
};

const mutations = {
    [INIT_STATE](state, payload) {
        Object.assign(state, getDefaultState(payload));
    },
    [SET_PARCEL](state, data) {
        const parcel = state.parcels.find(
            (el) => el.topo_number == data.topo_number && el.siruta_code == data.siruta_code
        );
        if (!parcel) {
            state.parcels?.push({
                county_name: data.county_name,
                uat_name: data.uat_name,
                topo_number: data.topo_number,
                siruta_code: data.siruta_code,
                coordinates: data.coordinates,
            });
        }
        AncpiService.persistSearchHistory(state.parcels);
    },
    [REMOVE_PARCEL](state, data) {
        state.parcels = state.parcels.filter(
            (el) => !(el.topo_number == data.topo_number && el.siruta_code == data.siruta_code)
        );
        AncpiService.persistSearchHistory(state.parcels);
    },
    [SET_PARCEL_SEARCH_VISIBILITY](state, isVisible) {
        state.parcelSearchVisible = isVisible;
    },
    [SET_PARCEL_SEARCH_EXPAND_REF](state, elementRef) {
        state.parcelSearchExpandRef = elementRef;
    },
    [SET_PARCEL_LAYER_REF](state, layerRef) {
        state.parcelLayerRef = layerRef;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
