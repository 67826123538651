<template>
    <div v-if="items.length" class="d-flex btn-container">
        <st-button 
            class="mr-4 mb-4 item"
            v-for="(urbanismRegulation, index) in items"
            :key="index"
            :callback="() => pickRegulation(urbanismRegulation)"
        >
            {{headerText(urbanismRegulation)}}
        </st-button>
    </div>
</template>

<script>

export default {
    name: "IntersectionUrbanRegulationCard",
    props: {
        urbanismRegulations: {
            type: Array,
            default: () => []
        },
        selectedSublayer: {
            type: Object,
            default: () => {}
        },

    },
    computed: {
        items() {
            return this.urbanismRegulations.filter((el) => el.content_html);
        }
    },
    data() {
        return {
            RLU_MAPPING_NAMES: {
                RLU_RT: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_RT'),
                RLU_RJ: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_RJ'),
                RLU_RE: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_RE'),
                RLU_POT: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_POT'),
                RLU_CUT: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_CUT'),
                RLU_RDA: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_RDA'),
            },
            urbanismRegulation: {},
        }
    },
    methods: {
        headerText(item) {
            return item?.display_name ?? this.RLU_MAPPING_NAMES[item.category_key] ?? item.category_key;
        },
        pickRegulation(item) {
            this.$emit('selectedRegulation', item);
        }
    }
};
</script>

<style scoped>
.btn-container {
    flex-wrap: wrap;
}
.item {
    width: 105px;
}
</style>