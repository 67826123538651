<template>
    <st-section :header="$t('APPLICATION.TERRITORIAL_ATLAS.HEADER')">
        <template #body>
            <p v-if="!viewMode">{{ $t('APPLICATION.TERRITORIAL_ATLAS.SUBTITLE') }}</p>
            <div class="atlas-map-placeholder" v-if="!showMap">
                <span>{{ $t('APPLICATION.TERRITORIAL_ATLAS.PLACEHOLDER') }}</span>
            </div>
            <div class="preview-atlas-map-wrapper" v-show="showMap">
                <st-map
                    ref="atlasMap"
                    :showLayers="showLayers"
                    :showPrintBtn="true"
                ></st-map>
            </div>
            <b-row class="mt-6">
                <b-col :cols="colsLeft">
                    <div class="form-group">
                        <label class="mb-4 d-block">
                            {{ $t('APPLICATION.TERRITORIAL_ATLAS.REAL_ESTATES_LABEL') }}
                        </label>
                        <template v-for="opt in realEstatesOptions">
                            <div :key="opt.cadastral_number" class="st-ta-item d-inline-flex">
                                <div class="form-check d-inline align-middle">
                                    <input class="form-check-input"
                                        type="checkbox"
                                        v-model="selectedRealEstates"
                                        :value="opt.cadastral_number"
                                        :id="opt.cadastral_number"
                                        @change="onChangeParcels(selectedRealEstates)"
                                    >
                                    <label class="form-check-label ml-2" :for="opt.cadastral_number">
                                        {{opt.text}}
                                    </label>
                                </div>
                                <div class="d-inline ml-4 cursor-pointer" @click="redirectToAtlasPage(opt)">
                                    <i class="fa fa-eye text-primary align-middle"></i>
                                </div>
                                <div class="d-inline ml-4 cursor-pointer" @click="downloadAsShp(opt)">
                                    <i class="fa fa-download text-primary align-middle"></i>
                                </div>
                            </div>
                        </template>
                        <template v-for="fileLayer in gisFilesLayers">
                            <div :key="fileLayer.file_id" class="st-ta-item d-inline-flex">
                                <div class="form-check d-inline align-middle">
                                    <input class="form-check-input"
                                        type="checkbox"
                                        v-model="gisFilesCheckboxes"
                                        :value="fileLayer.file_id"
                                        :id="fileLayer.file_id"
                                        @change="onChangeGisLayers(gisFilesCheckboxes)"
                                    >
                                    <label class="form-check-label ml-2" :for="fileLayer.filename">
                                        {{fileLayer.filename}}
                                    </label>
                                </div>
                            </div>
                        </template>
                    </div>
                </b-col>
                <b-col :cols="colsRight" class="real-estate-container">
                    <st-button
                        variant="light"
                        customClass="text-primary shadow-sm p-3 mb-5 bg-white rounded"
                        :callback="generateMap"
                        :disabled="!selectedRealEstates.length && !gisFilesCheckboxes.length"
                    >
                        <i class="fas fa-sync-alt text-primary"></i>
                        {{ $t('APPLICATION.TERRITORIAL_ATLAS.GENERATE_BUTTON') }}
                    </st-button>
                </b-col>
            </b-row>
         </template>
    </st-section>
</template>

<script>
import shpwrite from 'shp-write';
import { mapActions, mapGetters } from "vuex";
import Polygon from "@arcgis/core/geometry/Polygon";
import * as projection from "@arcgis/core/geometry/projection";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import /* webpackChunkName: "atlas" */ StMap from '@/shared/components/StMap.vue';

export default {
    name: 'TerritorialAtlas',
    components: {
        StMap
    },
    props: {
        realEstates: {
            type: Array,
            default: () => [],
        },
        gisFilesLayers: {
            type: Array,
            default: () => [],
        },
        showLayers: {
            type: Boolean,
            default: false,
        },
        viewMode: {
            type: Boolean,
            default: false,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        isRealEstateTargetUsed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedRealEstates: [],
            showMap: false,
            parcels: [],
            initialCreateLoad: true,
            colsLeft: 10,
            colsRight: 2,
            gisFilesCheckboxes: [],
            gisFilesSelected: [],
        }
    },
    watch: {
        realEstates: {
            deep: true,
            immediate: true,
            handler(newArray) {
                if (!this.initialCreateLoad || newArray.length) {
                    this.selectedRealEstates = newArray.map((el) => (el.cadastral_number));
                    this.parcels = newArray.map((el) => ({
                        cadastral_number: el.cadastral_number,
                        text: `${el.cadastral_number} ${el.territorial_administrative_unit}`,
                        siruta: el.territorial_administrative_unit,
                        coordinates: el.coordinates,
                        territorial_administrative_unit: el.territorial_administrative_unit,
                        territorial_administrative_unit_id: el.territorial_administrative_unit_id,
                    }));
                }
            }
        },
        gisFilesLayers: {
            deep: true,
            immediate: true,
            handler(newArray) {
                if (!this.initialCreateLoad || newArray.length) {
                    this.gisFilesSelected = newArray;
                    this.gisFilesCheckboxes = newArray.map((el) => (el.file_id));
                }
            }
        },
        mapGenerationFields : {
            deep: false,
            immediate: false,
            handler(newValue, oldValue) {
                const newRealEstates = newValue.realEstates.map( v => v.cadastral_number).sort().join(',');
                const oldRealEstates = oldValue.realEstates.map( v => v.cadastral_number).sort().join(',');
                const newGisFiles = newValue.gisFilesLayers.map( v => v.file_id).sort().join(',');
                const oldGisFiles = oldValue.gisFilesLayers.map( v => v.file_id).sort().join(',');

                if (!this.initialCreateLoad && newRealEstates == oldRealEstates && newGisFiles == oldGisFiles) {
                    return;
                }

                this.generateMap();
            }
        },
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            realEstate: 'applications/form/realEstate',
            uatIdsFromRealEstates: 'applications/form/getUatIdsFromRealEstates',
            realEstateTargetStatus: 'applications/form/realTargetStatus',
            selectedApplicationType: "applications/form/selectedApplicationType",
        }),
        realEstatesOptions() {
            return this.realEstates.map((el) => ({
                cadastral_number: el.cadastral_number,
                text: `${el.cadastral_number} ${el.territorial_administrative_unit}`,
                siruta: el.territorial_administrative_unit,
                coordinates: el.coordinates,
                territorial_administrative_unit: el.territorial_administrative_unit,
                territorial_administrative_unit_id: el.territorial_administrative_unit_id,
            })) || [];
        },
        mapGenerationFields () {
            return {
                realEstates: this.realEstates,
                gisFilesLayers: this.gisFilesLayers,
            }
        },
    },
    methods: {
        ...mapActions({
           updateRealEstateTargetStatus: 'applications/form/updateRealEstateTargetStatus',
           saveSelectedParcel: 'applications/atlas/setSelectedParcel'
        }),
        generateMap() {
            if(!this.selectedRealEstates.length && !this.gisFilesCheckboxes.length) {
                this.showMap = false;
                return;
            }
            this.initialCreateLoad = false;
            this.showMap = true;
            this.updateRealEstateTargetStatus(false);
            this.$nextTick(() => {
                this.$refs.atlasMap.loadMap(
                    this.parcels,
                    this.gisFilesSelected,
                )
                .finally(() => {
                    this.$emit('map-mounted');
                });
            });
        },
        onChangeParcels(checked) {
            this.parcels = this.realEstatesOptions.filter(el => checked.includes(el.cadastral_number));
        },
        onChangeGisLayers(checked) {
            this.gisFilesSelected = this.gisFilesLayers.filter(el => checked.includes(el.file_id));
        },
        redirectToAtlasPage(selectedItem) {
            this.saveSelectedParcel(selectedItem);
            let routeData = this.$router.resolve({
                name: 'atlas',
                query: {
                    cadastral_number: selectedItem.cadastral_number,
                    siruta: selectedItem.siruta,
                    app_type: this.record?.application_type_id ?? this.selectedApplicationType,
                }
            });
            window.open(routeData.href, '_blank');
        },
        downloadAsShp(selectedItem) {
            const coordinates = selectedItem.coordinates.map(v => [v.x, v.y]);
            const stereo70Polygon = new Polygon({
                type: 'polygon',
                rings: [coordinates],
                spatialReference: { wkid: 3844},
            });

            projection
            .load()
            .then(() => {
                let outSpatialReference = new SpatialReference({
                    wkid: 4326, // WGS84
                });
                const convertedPolygon = projection.project(
                    stereo70Polygon,
                    outSpatialReference
                );

                shpwrite.download({
                    crs: {
                        type: "name",
                        properties: {
                            name: "EPSG:4326"
                        }
                    },
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry:{
                                type:"Polygon",
                                coordinates: [convertedPolygon.rings]
                            },
                            properties: {
                                cadastral_number: selectedItem.cadastral_number,
                                name: selectedItem.siruta,
                            },
                        }
                    ]
                },
                {
                    folder: `${selectedItem.siruta}_${selectedItem.cadastral_number}`,
                    types: {
                        polygon: this.$t( "REAL_ESTATE.REAL_ESTATES"),
                    }
                });
            });
        },
    },
    created() {
        this.colsLeft = this.viewMode ? 9 : 10;
        this.colsRight =  this.viewMode ? 3 : 2;
        if (this.realEstates.length || this.viewMode) {
            this.initialCreateLoad = false;
            this.parcels = this.realEstates.map((el) => ({
                cadastral_number: el.cadastral_number,
                text: `${el.cadastral_number} ${el.territorial_administrative_unit}`,
                siruta: el.territorial_administrative_unit,
                coordinates: el.coordinates,
                territorial_administrative_unit: el.territorial_administrative_unit,
                territorial_administrative_unit_id: el.territorial_administrative_unit_id,
            }));
        }
        if(this.gisFilesLayers?.length || this.viewMode) {
            this.initialCreateLoad = false;
            // this.gisFilesSelected = this.gisFilesLayers;
        }
        this.generateMap();
    },
}
</script>


<style lang="scss" scoped>
    .st-application-real-estate-list ::v-deep{
        .custom-control-inline {
            margin-right: 1.5rem;
        }
        .custom-control-label {
            background-color: #f0f0f0 !important;
            border: 1px solid rgba(60, 60, 60, 0.26);
            border-radius: 4px;
            color: #333;
            line-height: 1.5;
            padding: 0 0.25em;
        }
    }
    .st-ta-item {
        padding: 4px 8px;
        border: 1px solid rgb(226, 230, 234);
        margin-left: 4px;
        border-radius: 4px;
        .form-check-label {
            margin-top: 2px !important;
        }
    }
</style>
