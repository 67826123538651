import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import BooleanField from '@/shared/fields/boolean-field';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`ATLAS.FIELDS.${name.toUpperCase()}`);

const fields = {
    title: new StringField('title', label('title'), {
        required: true,
    }),
    notes: new StringField('notes', label('notes'), {
        required: false,
    }),
    surface: new StringField('surface', label('surface'), {}),
    perimeter: new StringField('title', label('title'), {}),
    notification_enabled: new BooleanField(
        'notification_enabled',
        label('notification_enabled'),
    ),
};

export class InterestAreaModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
