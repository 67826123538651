<template>
    <div class="mx-4">
        <form
            class="form"
            novalidate="novalidate"
            :id="id"
        >
            <div v-if="maxInterestZoneReached" class="alert alert-custom alert-light-warning fade show" role="alert">
                    <div class="alert-icon"><i class="fas fa-exclamation-triangle"></i></div>
                    <div class="alert-text text-center">
                        <span class="font-size-sm">
                            {{ $t('ERRORS.TOO_MANY_INTEREST_AREAS') }}
                        </span>
                    </div>
                </div>
            <div class="row mb-4">
                <div class="col-md-8 d-flex justify-content-between w-100">
                    <p class="text-dark-50">{{ $t('ATLAS.INTEREST_AREAS_MANAGER.AREA') }}:</p>
                    <p>{{polygon.surface}} km<sup>2</sup></p>
                </div>
                 <div class="col-md-8 d-flex justify-content-between w-100">
                    <p class="text-dark-50">{{ $t('ATLAS.INTEREST_AREAS_MANAGER.PERIMETER') }}:</p>
                    <p>{{polygon.perimeter}} km</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8">
                    <label> {{ fields.title.label }} * </label>
                    <div class="form-group">
                        <st-input-text
                            :name="fields.title.name"
                            :ref="fields.title.name"
                            v-model="model[fields.title.name]"
                            :disabled="maxInterestZoneReached"
                        />
                    </div>
                </div>
                 <div class="col-12 ">
                    <label> {{ fields.notes.label }}  </label>
                    <div class="form-group">
                        <b-form-textarea
                            v-model="model[fields.notes.name]"
                            :ref="fields.notes.name"
                            :name="fields.notes.name"
                            :placeholder="$t('ATLAS.INTEREST_AREAS_MANAGER.NOTES_PLACEHOLDER')"
                            rows="5"
                            :disabled="maxInterestZoneReached"
                        ></b-form-textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                    <b-form-checkbox
                        switch
                        v-model="model[fields.notification_enabled.name]"
                        :ref="fields.notification_enabled.name"
                        :name="fields.notification_enabled.name"
                        :disabled="maxInterestZoneReached"
                        size="lg"
                    >
                        <label> {{ fields.notification_enabled.label }} </label>
                    </b-form-checkbox>
                    </div>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-between w-100">
            <st-button
                variant="link"
                class="font-size-h6 px-5 py-3 my-3 mr-3"
                :callback="close"
            > {{ $t('GENERAL.BUTTON.CANCEL') }}
            </st-button>
            <st-button
                variant="primary"
                class="font-size-h6 px-5 py-3 my-3 mr-3"
                :callback="managePolygon"
                :disabled="maxInterestZoneReached"
            >
                <span v-if="editMode"> {{ $t('GENERAL.BUTTON.UPDATE') }}</span>
                <span v-else> {{ $t('GENERAL.BUTTON.ADD') }}</span>
            </st-button>
        </div>
  </div>
</template>
<script>
import Polygon from "@arcgis/core/geometry/Polygon";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as projection from "@arcgis/core/geometry/projection";
import Message from '@/shared/message/message';
import { mapGetters, mapActions} from 'vuex';
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { FormSchema } from "@/shared/form/form-schema";
import { InterestAreaModel } from "@/modules/atlas/models/interest-area-model";

const { fields } = InterestAreaModel;

const formSchema = new FormSchema([
  fields.title,
  fields.notes,
  fields.notification_enabled,
]);

export default {
    name: 'InterestAreaForm',
    props: {
        id: {
            type: String,
            required: true,
        },
        polygon: {
            type: Object,
            default: () => ({ }),
        },
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            fv: null,
        };
    },
    computed: {
        ...mapGetters({
            areas: 'atlas/interest-areas/areas',
        }),
        maxInterestZoneReached() {
            return !this.editMode && this.areas.length > 4
        }
    },
    created() {
        const modelObj  = !this.editMode ? { notification_enabled: true, notes: ''} : this.polygon;
        this.model = formSchema.initialValues(modelObj);
    },
    mounted() {
        this.fv = createFormValidation(this.id, this.rules);
    },
    methods: {
        ...mapActions({
            addArea: 'atlas/interest-areas/addArea',
            editArea: 'atlas/interest-areas/editArea',
        }),
        close() {
            this.$emit('close');
        },
        async managePolygon() {
           const validate = await this.fv.validate();
           if (validate === 'Valid') {
               if (this.editMode) {
                    const payload = {
                        ...this.model,
                        surface: this.polygon.surface,
                        perimeter: this.polygon.perimeter,
                        area: this.polygon.area,
                    }
                this.editArea({payload, id: this.polygon.id})
                .then(() => {
                    this.close();
                    Message.success('GENERAL.UPDATED_SUCCESS');
                })
                } else {
                    projection
                    .load()
                    .then(() => {
                        const polygonObj = new Polygon({
                            hasZ: true,
                            hasM: true,
                            rings: this.polygon.area.map(
                                v => [v.x, v.y]
                            ),
                            spatialReference: { wkid: 102100 },
                        });

                        let outSpatialReference = new SpatialReference({
                            wkid: 31700, //STEREO 70 / ancpi
                        });

                        let projectedpolygonObj = projection.project(
                            polygonObj,
                            outSpatialReference
                        );

                        let projectedArea = [];
                        projectedpolygonObj.rings[0].forEach(el => {
                            projectedArea.push({x: el[0], y: el[1]})
                        });

                        const payload = {
                            ...this.model,
                            surface: this.polygon.surface,
                            perimeter: this.polygon.perimeter,
                            area: projectedArea
                        }

                        this.addArea(payload)
                        .then(() => {
                                this.close();
                                Message.success('GENERAL.CREATED_SUCCESS');
                        })
                    })
                    .catch((error) => {
                        console.error("Failed to load projection ", error);
                    });
               }
           }
        }
    }
};
</script>
