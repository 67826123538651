<template>
  <div class="interest-areas">
    <portal to="atlas-sidebar-title">
      <div class="d-flex align-items-center justify-content-between mr-4">
        <span>{{ $t("ATLAS.INTERSECTION_MANAGER.TITLE") }}</span>
        <st-button variant="link" :callback="showDetailsView" v-if="intersectedLayers.length && !isAtlasPublicView">
            {{ $t("ATLAS.INTERSECTION_MANAGER.EXPAND_LINK") }}
        </st-button>
      </div>
    </portal>
    <div class="add-layer-container" v-if="measuredLayerLoading">
      <IntersectionManagerMeasuredLayerForm />
    </div>
    <div class="layer-container" v-else>
      <div class="coordinates" v-if="pointOfIntersection">
        {{ `${pointOfIntersection.x}, ${pointOfIntersection.y}` }}
      </div>
      <div v-if="!layersLoading">
          <template v-for="layer in intersectedLayers">
              <div v-if="!layer.isIndexedSystemLayer"
                :key="layer.uid"
                class="info-container"
              >
                <div class="info-header">
                    {{ `${infoLayerText(layer)}${layer.name}` }}
                </div>
                <div class="info-body">
                  <div class="info-body-title font-weight-bold mb-2">
                    {{ `${$t("ATLAS.INTERSECTION_MANAGER.DETAILS_TEXT")}:` }}
                  </div>
                  <div class="row mb-2" v-if="layer.isApplication">
                    <div class="col-6">
                      {{ $t("ATLAS.INTERSECTION_MANAGER.APPLICATION_TYPE") }}
                    </div>
                    <div class="col-6">{{ layer.application_type || "Imobil" }}</div>
                  </div>
                  <div class="row mb-2" v-if="layer.isApplication">
                    <div class="col-6">
                      {{ $t("ATLAS.INTERSECTION_MANAGER.APPLICATION_STATUS") }}
                    </div>
                    <div class="col-6">{{ applicationStatus(layer) }}</div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      {{ $t("ATLAS.INTERSECTION_MANAGER.SIRUTA_LABEL") }}
                    </div>
                    <div class="col-6">
                      <span v-if="layer.uat_id ">{{ layer.uat_id }}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      {{ $t("ATLAS.INTERSECTION_MANAGER.UAT_LABEL") }}
                    </div>
                    <div class="col-6">{{ layer.uat_name }}</div>
                  </div>
                  <div class="row mb-2" v-if="!layer.isApplication">
                    <div class="col-12">
                      {{ `${$t( "ATLAS.INTERSECTION_MANAGER.URBANISM_REGULATION_LABEL"  )}:` }}
                      <IntersectionUrbanRegulation
                          :layer="layer"
                          @showIntersectionModal="showIntersectionModal"
                      />
                    </div>
                  </div>
                </div>
              </div>
          </template>
        </div>
      <div
        v-else
        class="d-flex h-100 w-100 justify-content-center align-items-center"
      >
        <span>{{ $t("ATLAS.INTERSECTION_MANAGER.HELP_TEXT") }}</span>
      </div>
    </div>
    <IntersectionDetailsModal
      ref="intersectionModal"
      :isUrbanRegulationsSelected="isUrbanRegulationsSelected"
      :selectedSublayer="selectedSublayer"
    ></IntersectionDetailsModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import IntersectionDetailsModal from "./IntersectionDetailsModal.vue";
import IntersectionUrbanRegulation from "./IntersectionUrbanRegulation.vue";
import moment from "moment";
import IntersectionManagerMeasuredLayerForm from "./IntersectionManagerMeasuredLayerForm.vue";

export default {
  name: "IntersectionManager",
  components: {
    IntersectionDetailsModal,
    IntersectionUrbanRegulation,
    IntersectionManagerMeasuredLayerForm
  },
  data() {
      return {
          isUrbanRegulationsSelected: false,
          selectedSublayer: {}
      }
  },
  props: {
    isAtlasPublicViewSelected: {
        type: Boolean,
        default: false,
    },
  },
  computed: {
    ...mapGetters({
      intersectedLayers: "atlas/layer-intersection/layers",
      pointOfIntersection: "atlas/layer-intersection/pointOfIntersection",
      isAtlasPublicView: "atlas/sidebar/isAtlasPublicView",
      appConfig: "shared/appConfig",
      currentUser: "auth/currentUser",
      layersLoading: "atlas/layer-intersection/layersLoading",
      measuredLayerLoading: "atlas/layer-intersection/measuredLayerLoading",
    }),
  },
  methods: {
    ...mapActions({
      initStore: "atlas/layer-intersection/doInit",
      getUrbanismRegulations: "atlas/layer-intersection/getUrbanismRegulations",
      getUrbanismRegulationZone: "atlas/layer-intersection/getUrbanismRegulationZone",
    }),
    infoLayerText(item) {
      if (item?.isApplication) return "";
      return `${this.$t("ATLAS.INTERSECTION_MANAGER.INFO_LAYER_TEXT")} `;
    },
    showDetailsView() {
      this.isUrbanRegulationsSelected = false;
      this.$refs.intersectionModal.show();
    },
    formattedDate(date) {
      return moment(new Date(date)).format(this.appConfig.DATE_FORMAT);
    },
    applicationStatus(item) {
      const status = item?.application_status?.toUpperCase();
      return this.$t(`ATLAS.INTERSECTION_MANAGER.APPLICATION_STATUSES.${status}`);
    },
    applicationIssuedDate(item) {
      const date = item?.application_issued_date || item?.application_request_date;
      return this.formattedDate(date);
    },
    async showIntersectionModal(data) {
        this.isUrbanRegulationsSelected = true;
        const details = await this.getUrbanismRegulationZone({key: data.rlu_id, isPublicView: this.isAtlasPublicViewSelected});

        if (details?.length === 1 && details[0]?.display_name) {
          data.display_name = details[0].display_name;
        }

        this.selectedSublayer = data;

        const payload = {
          isPublicView: this.isAtlasPublicViewSelected,
          key: data.rlu_id
        }

        if (this.currentUser.user_type_name === 'citizen') {
          payload.isPublicView = true;
        }

        await this.getUrbanismRegulations(payload);

        this.$refs.intersectionModal.show();
    }
  },
  async created() {
    this.initStore();
  }
};
</script>
<style lang="scss" scoped>
.layer-container {
  padding: 0;
  overflow: auto;
  min-height: 25rem;
}
.coordinates {
  padding: 0 1.5rem;
  font-size: 1.5rem;
  color: #b5b5c3; //$gray-500
  margin-bottom: 1.5rem;
}
.info-container {
  padding: 1.5rem;
  border-bottom: 1px solid #b5b5c3;
}
.info-header {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
.interest-areas {
    overflow: auto;
    height: calc(100% - 4rem) !important;
}
</style>
