<template>
    <div class="layer-group">
        <div :class="['layer-group-header', isVisibiltySettingsVisible ? 'd-flex justify-content-between' :'' ]">
            <div class="layer-group-title">
                <i
                    :class="groupVisibilityClass"
                    @click="toggleGroupVisibility"></i>
                <span>{{ $t(`ATLAS.LAYER_MANAGER.${id.toUpperCase()}_LABEL`) }}</span>
            </div>
            <div class="mr-40 order-header" v-if="isVisibiltySettingsVisible && !isSimpleLayers" >
                   <span>{{ $t(`ATLAS.VISIBILITY_MANAGER.ORDER_COLUMN_HEADER`) }}</span>
            </div>
        </div>

        <div class="layer-group-body" v-cloak v-show="isVisible">
            <div v-if="showOrganisationDrowdown">
                <div>
                    <Loader  v-if="loading['shared/getOrganisations']"/>
                    <form class="form" novalidate="novalidate" id="st_org_layers_organisation_filter">
                        <div class="row pb-6">
                            <div class="col">
                                <div class="form-group">
                                    <label class="mb-4">
                                        {{$t('INSTITUTION.FIELDS.ORGANISATION')}}
                                    </label>
                                    <b-form-select
                                        v-model="selectedOrganisation"
                                        :options="organisations"
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">
                                                {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <template v-for="layer in layers">
                <layer-item
                    :layer="layer"
                    :key="layer.id"
                    :isContainerOpen.sync="isContainerOpen"
                    :group="id"
                    :layerSelected="layerSelected"
                    :isInputFocus.sync="isInputFocus"
                    :openFocus = "openFocus"
                    :isSimpleLayers="isSimpleLayers"
                    :isOrganisationSection="isOrganisationSection"
                    :isPrivateSection="isPrivateSection"
                    @showLayerViewModal="showLayerViewModal"
                />
                <div v-if="layer.components && layer.components.length"
                        class="container-layer-components"
                        :key="`${layer.id}_comp`">
                    <template v-for="layerComponent in layer.components">
                        <layer-item
                            v-show="openContainers.includes(layerComponent.container_id)"
                            :layer="layerComponent"
                            :key="layerComponent.id"
                            :group="id"
                            :container="layer"
                            :layerSelected.sync="layerSelected"
                            :isInputFocus.sync="isInputFocus"
                            :openFocus = "openFocus"
                            @showLayerViewModal="showLayerViewModal"
                        />
                    </template>
                </div>
            </template>
            <div v-if="!filteredLayers.length" class="ml-6">{{ $t('ATLAS.LAYER_MANAGER.EMPTY_LAYER_TEXT') }}</div>
        </div>
        <layer-view-modal ref="layer-view-modal"/>
    </div>
</template>
<script>
import LayerItem from "@/modules/atlas/components/layer-manager/LayerItem";
import LayerViewModal from './LayerViewModal';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "LayerGroup",
  components: {
      LayerItem,
      LayerViewModal
  },
  props: {
      id: {
          type: String,
          required: true,
      },
      layers: {
          type: Array,
          default: () => [],
      },
      selectedLayer: {
          type: Object,
          default: () => {},
      },
      isSimpleLayers: {
          type: Boolean,
          default: false
      },
      isOrganisationSection: {
          type: Boolean,
          default: false
      },
      isPrivateSection: {
          type: Boolean,
          default: false
      },
      isOrgSelected: {
          type: String,
      },
  },
  data() {
      return {
          isVisible: true,
          isContainerOpen: {},
          openContainers: [],
          isInputFocus: '',
          openFocus: '',
          organisations: [],
          selectedOrganisation: null,
          filteredLayers: [],
      }
  },
  computed: {
    ...mapGetters({
        selectedLayers: 'atlas/layer-manager/selectedLayers',
        openedContainers: 'atlas/layer-manager/openContainers',
        containerLayers: 'atlas/layer-manager/containerLayers',
        isVisibiltySettingsVisible: 'atlas/sidebar/isVisibiltySettingsVisible',
        isSuperAdmin: 'auth/isSuperAdmin',
        loading: 'shared/loading',
    }),
    groupVisibilityClass() {
        return this.isVisible ? 'fas fa-caret-down' : 'fas fa-caret-right';
    },
    layerSelected() {
        return this.selectedLayer?.id ?? '';
    },
    showOrganisationDrowdown() {
        return this.isSuperAdmin && this.isOrganisationSection;
    },
  },
  watch: {
      isContainerOpen(value) {
        if (value.container) {
            this.openContainers.push(value.id);
        } else {
            this.openContainers = this.openContainers.filter(e => e !== value.id);
        }
        this.doOpenContainer(this.openContainers)
      },
      isInputFocus(val) {
        this.openFocus = val;
      },
      selectedOrganisation(orgId) {
        this.$emit('update:isOrgSelected', orgId);
      },
  },
  methods: {
      ...mapActions({
          doOpenContainer: 'atlas/layer-manager/openContainer',
          getOrganisations: 'shared/getOrganisations',
      }),
      toggleGroupVisibility() {
          this.isVisible = !this.isVisible;
      },
      showLayerViewModal(data) {
          this.$refs['layer-view-modal'].show(data);
      },
  },
  mounted() {
    if (this.isVisibiltySettingsVisible) {
        this.openContainers = this.containerLayers.map( container => container.id);
    }
    this.openContainers = [...this.openContainers,...this.openedContainers];

    this.filteredLayers = this.layers;
  },
  created() {
    if(this.showOrganisationDrowdown) {
        this.getOrganisations()
        .then((result)=>{
            this.organisations = result.data.filter(v => v.status=='active').map(
                (org) => ({
                    text: org.name,
                    value: org.id,
                })
            );
        });
    }
  },
}
</script>
<style lang="scss" scoped>
.container-layer-components {
    padding-left: 1rem;
}
</style>
