<template lang="">
    <div class="d-flex flex-column flex-root">
        <div class="mx-0 d-flex flex-row flex-column-fluid page">
            <div
                id="kt_aside"
                ref="kt_aside"
                class="aside aside-left aside-fixed d-flex flex-column flex-row-auto aside-public-view"
                :class="[ {'show-toggle': isHovering}]"
                @mouseover="mouseEnter"
                @mouseleave="mouseLeave"
            >
                <Brand :isAtlasPublicView="isAtlasPublicView"/>
                <div id="kt_aside_menu_wrapper"
                    class="aside-menu-wrapper flex-column-fluid"
                >
                    <div
                        id="kt_aside_menu"
                        ref="kt_aside_menu"
                        class="aside-menu st-aside-menu my-4"
                        data-menu-vertical="1"
                        data-menu-dropdown-timeout="500"
                    >
                    <div class="aside-menu st-aside-menu ">
                        <left-sidebar :isAtlasPublicViewSelected="true"/>
                    </div>
                    </div>
                </div>
            </div>
            <div id="kt_wrapper"
                class="d-flex flex-column flex-row-fluid wrapper kt-wrapper-public-view"
            >
                <div id="kt_content"
                    class="content d-flex flex-column flex-column-fluid content-atlas-public-view"
                >
                    <div class="atlas-map-wrapper">
                        <atlas-map ref="atlasMap" v-if="gisTokenLoaded && layersLoaded" :isMobileDevice="isMobileDevice">
                            <template #left-sidebar>
                                <left-sidebar :isAtlasPublicViewSelected="true"/>
                            </template>
                        </atlas-map>
                        <transition name="slide-fade-right">
                            <right-sidebar v-if="isRightSidebarVisible" id="right-sidebar" :isMobileDevice="isMobileDevice"/>
                        </transition>
                        <transition name="slide-fade-enter">
                            <parcel-search-modal v-if="isParcelSearchVisible" id="atlas-parcel-search" :isMobileDevice="isMobileDevice"/>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import LeftSidebar from "@/modules/atlas/components/LeftSidebar";
import RightSidebar from "@/modules/atlas/components/RightSidebar";
import { mapGetters, mapActions } from "vuex";
import GlobalComponents from "@/shared/globalComponents";
import HtmlClass from '@/shared/services/htmlclass.service';
import StLayoutAside from '@/assets/js/layout/base/aside.js';
import StLayoutAsideMenu from '@/assets/js/layout/base/aside-menu.js';
import Brand from '@/modules/layout/components/brand/Brand.vue';
import ParcelSearchModal from '@/modules/atlas/components/parcel-search/ParcelSearchModal';
import KTUtil from '@/assets/js/components/util';

export default {
    name: "AtlasPublicView",
    components: {
        AtlasMap: () => import (/* webpackChunkName: "atlas" */ "@/modules/atlas/components/AtlasMap.vue"),
        LeftSidebar,
        RightSidebar,
        ParcelSearchModal,
        Brand
    },
    data() {
        return {
            gisTokenLoaded: false,
            insideTm: 0,
            outsideTm: 0,
            isHovering: false,
        };
    },
    computed: {
        ...mapGetters({
            isLeftSidebarVisible: "atlas/sidebar/isVisible",
            isRightSidebarVisible: "atlas/layer-manager/isSublayerSidebarVisible",
            isAtlasPublicView: "atlas/sidebar/isAtlasPublicView",
            toggleState: "atlas/sidebar/toggleState",
            isParcelSearchVisible: "atlas/parcels/parcelSearchVisible",
            layersLoaded:'atlas/layer-manager/layersLoaded',
        }),
        ...mapGetters([
            'layoutConfig',
            'getClasses'
        ]),
        isMobileDevice() {
            return KTUtil.isMobileDevice()
        },
        asideMenuClass() {
            const classes = this.getClasses('aside_menu');
            if (typeof classes !== 'undefined') {
                return classes.join(' ');
            }
            return null;
        },
    },
    methods: {
        ...mapActions({
            initSidebarState: "atlas/sidebar/doInit",
            initLayersState: "atlas/layer-manager/doInit",
            initParcelSearchHistory: 'atlas/parcels/doInit',
            getGisToken: "atlas/token/getGisToken",
            getSystemLayers: "atlas/layer-manager/getSystemLayers",
        }),
         /**
         * Use for fixed left aside menu, to show menu on mouseenter event.
         */
         mouseEnter() {
            if (this.layoutConfig('aside.self.minimize.hoverable')) {
                this.isHovering = true;
                // check if the left aside menu is fixed
                if (document.body.classList.contains('aside-fixed')) {
                    if (this.outsideTm) {
                        clearTimeout(this.outsideTm);
                        this.outsideTm = null;
                    }

                    // if the left aside menu is minimized
                    if (document.body.classList.contains('aside-minimize')) {
                        document.body.classList.add('aside-minimize-hover');

                        // show the left aside menu
                        document.body.classList.remove('aside-minimize');
                    }
                }
            }
        },

        /**
         * Use for fixed left aside menu, to show menu on mouseenter event.
         */
        mouseLeave() {
            if (this.layoutConfig('aside.self.minimize.hoverable')) {
                const bodyClassList = document.body.classList;
                const isMinimizable = [...bodyClassList].some((el) => ['aside-minimize', 'aside-minimize-hover'].includes(el));
                if (!isMinimizable) {
                    this.isHovering = false;
                }
                if (document.body.classList.contains('aside-fixed')) {
                    if (this.insideTm) {
                        clearTimeout(this.insideTm);
                        this.insideTm = null;
                    }

                    if (document.querySelector('.aside-menu .scroll')) {
                        document.querySelector('.aside-menu .scroll').scrollTop = 0;
                    }

                    // if the left aside menu is expand
                    if (document.body.classList.contains('aside-minimize-hover')) {
                        // hide back the left aside menu
                        document.body.classList.remove('aside-minimize-hover');
                        document.body.classList.add('aside-minimize');
                    }
                }
            }
        },
    },
    async created() {
        Vue.use(GlobalComponents);
        await this.getGisToken(false);
        await this.initSidebarState(true);
        await this.initLayersState();
        await this.getSystemLayers(false);
        await this.initParcelSearchHistory();

        this.gisTokenLoaded = true;
    },
    beforeMount() {
        HtmlClass.init(this.layoutConfig());
    },
    mounted() {
        this.$nextTick(() => {
            // Init Aside
            StLayoutAside.init(this.$refs.kt_aside);

            // Init Aside Menu
            StLayoutAsideMenu.init(this.$refs.kt_aside_menu);
        });
    },
};
</script>
<style lang="scss">
.atlas-map-wrapper {
    height: 100%;
}
.slide-fade-enter-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-active {
    transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(-100%);
}
.slide-fade-leave-to {
    opacity: 0;
}
.slide-fade-right-enter-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-right-leave-active {
    transition: all 0.3s ease;
}
.slide-fade-right-enter,
.slide-fade-right-leave-to {
    transform: translateX(100%);
}
.slide-fade-right-leave-to {
    opacity: 0;
}
.kt-wrapper-public-view {
    padding: 0 !important;
}
.aside-public-view {
    width: 300px !important;
    left: -350px;
}
.content-atlas-public-view {
    padding: 0;
}
</style>

