import { AtlasService } from '@/modules/atlas/atlas-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';
import Vue from 'vue';

// action types
export const DO_INIT_STATE = 'doInit';
export const SAVE_INTERSECTED_LAYERS = 'saveIntersectedLayers';
export const SAVE_POINT_OF_INTERSECTION = 'savePointOfIntersection';
export const NEW_AREA = 'newInterestArea';
export const SHOW_NEW_AREA = 'showNewInterestArea';
export const ADD_POLYGON = 'addArea';
export const UPDATE_AREA = 'editArea';
export const DELETE_AREA = 'deleteArea';
export const SHOW_EDIT_MODAL = 'showEditModal';
export const HIDE_EDIT_MODAL = 'hideEditModal';

// mutation types
export const INIT_STATE = 'init';
export const SET_LAYERS = 'setLayers';
export const SET_POINT_OF_INTERSECTION = 'setPointOfIntersection';
export const GET_INTEREST_AREAS = 'getInterestAreas';
export const SET_AREAS = 'setAreas';
export const SET_NEW_AREA = 'setNewArea';
export const SET_VISIBLE_NEW_AREA_MODAL = 'setVisibleNewAreaModal';
export const SET_NEW_POLIGON = 'setNewPolygon';
export const REMOVE_AREA = 'removeArea';
export const ADD_AREA = 'addArea';
export const SET_EDIT_MODAL_VISIBILITY = 'setEditModalVisibility';
export const SET_EDITING_AREA = 'setEditingArea';
export const REMOVE_EDITING_AREA = 'removeEditingArea';
export const UPDATE_EDITED_AREA = 'updateEditedArea';

const getDefaultState = () => ({
    layers: [],
    pointOfIntersection: null,
    areas: [],
    newAreaSelected: false,
    isNewAreaModalVisible: false,
    polygon: {},
    isAreaEditModalVisible: false,
    editingArea: null
});

const state = getDefaultState();

const getters = {
    layers: (state) => state.layers,
    pointOfIntersection: (state) => state.pointOfIntersection,
    areas: (state) => state.areas,
    newAreaSelected: (state) => state.newAreaSelected,
    isNewAreaModalVisible: (state) => state.isNewAreaModalVisible,
    polygon: (state) => state.polygon, 
    isAreaEditModalVisible: (state) => state.isAreaEditModalVisible,
    editingArea: (state) => state.editingArea,
};

const mutations = {
    [INIT_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_LAYERS](state, layers) {
        state.layers = layers;
    },
    [SET_POINT_OF_INTERSECTION](state, point) {
        state.pointOfIntersection = point;
    },
    [SET_AREAS](state, areas) {
        state.areas = areas;
    },
    [SET_NEW_AREA](state, value) {
        state.newAreaSelected = value;
    },
    [SET_VISIBLE_NEW_AREA_MODAL](state, status) {
        state.isNewAreaModalVisible = status;
    },
    [SET_NEW_POLIGON](state, polygon) {
        state.polygon = polygon;
    },
    [ADD_AREA](state, area) {
        state.areas.push(area);
    },
    [REMOVE_AREA](state, area) {
        const index = state.areas.findIndex((item) => item.id === area.id);
        Vue.delete(state.areas, index);
    },
    [SET_EDIT_MODAL_VISIBILITY](state, isVisible) {
        state.isAreaEditModalVisible = isVisible;
    },
    [SET_EDITING_AREA](state, area) {
        state.editingArea = area;
    },
    [REMOVE_EDITING_AREA](state) {
        state.editingArea = null;
    },
    [UPDATE_EDITED_AREA](state, data) {
        const areaIndex = state.areas.findIndex((area) => area.id === data.id);
        Vue.set(state.areas, areaIndex, data);
    }
};

const actions = {
    [DO_INIT_STATE](context) {
        context.commit(INIT_STATE);
    },
    [SAVE_INTERSECTED_LAYERS](context, layers) {
        context.commit(SET_LAYERS, layers);
    },
    [SAVE_POINT_OF_INTERSECTION](context, point) {
        context.commit(SET_POINT_OF_INTERSECTION, point);
    },
    async [GET_INTEREST_AREAS](context) {
        context.commit('shared/activateLoading', 'atlas-interest-areas/get', { root: true });
        try {
            const response = await AtlasService.getAreas();
            context.commit(SET_AREAS, response.data);
            return response;

        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-interest-areas/get', { root: true });
        }
    },
    [NEW_AREA](context, value) {
        context.commit(SET_NEW_AREA, value);
    },
    [SHOW_NEW_AREA](context, value) {
        const {status, selectedPolygon } = value
        context.commit(SET_VISIBLE_NEW_AREA_MODAL, status);
        context.commit(SET_NEW_POLIGON, selectedPolygon);
    },
    [ADD_POLYGON](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-interest-areas/addArea', { root: true });
            AtlasService.addArea(payload)
                .then((data) => {
                    context.commit(ADD_AREA, data.data);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-interest-areas/addArea', { root: true });
                });
        });
    },
    [DELETE_AREA](context, area) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-interest-areas/deleteArea', { root: true });
            AtlasService.deleteArea(area)
                .then(() => {
                    context.commit(REMOVE_AREA, area);
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-interest-areas/deleteArea', { root: true });
                });
        });
    },
    [UPDATE_AREA](context, data) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-interest-areas/updateArea', { root: true });
            AtlasService.updateArea(data)
                .then((response) => {
                    context.commit(UPDATE_EDITED_AREA, response);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-interest-areas/updateArea', { root: true });
                });
        });
    },
    [SHOW_EDIT_MODAL](context, area) {
        context.commit(SET_EDIT_MODAL_VISIBILITY, true);
        context.commit(SET_EDITING_AREA, area);
    },
    [HIDE_EDIT_MODAL](context) {
        context.commit(SET_EDIT_MODAL_VISIBILITY, false);
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
