<template>
    <div>
        <portal to="atlas-sublayer-sidebar-title">
          <span v-b-popover.hover="description">{{ layer.name }}</span>
        </portal>
        <div class="sublayer-container">
          <div class="row sublayer-column-header">
            <div class="col-8 text-left p-0">{{ $t('ATLAS.SUBLAYER_MANAGER.SUBLAYER_NAME') }}</div>
            <div class="col-4 text-right p-0">{{ $t('ATLAS.SUBLAYER_MANAGER.SUBLAYER_ACTIONS') }}</div>
          </div>
          <template v-if="selectedLayer.sublayers && selectedLayer.sublayers.length">
            <div class="sublayer-item row" v-for="sublayer in selectedLayer.sublayers" :key="sublayer.id">
              <div class="col-8 sublayer-name cursor-pointer" @click="zoomToSublayer($event, sublayer)">
                  {{ sublayer.name }}
              </div>
              <div class="col-4 sublayer-actions">
                <i class="fa fas view-sublayer-details-icon fa-map-marked-alt"
                  @click="sublayerClicked($event, sublayer)"
                ></i>
                <b-form-checkbox
                  :checked="sublayer.visible"
                  @change="toggleSublayer($event, sublayer.gis_id)"
                  switch
                  size="sm"
                ></b-form-checkbox>
                <!-- TODO: uncomment once functionality is implemented -->
                <!-- <i class="fas fa-map-marked-alt"></i>
                <i class="fas fa-eye"></i> -->
              </div>
            </div>
            <div class="feature-reduction-toggle row" v-if="showFeatureReductionOptions(selectedLayer)">
                <div class="col-8 sublayer-name cursor-pointer">
                    {{ $t('ATLAS.SUBLAYER_MANAGER.CLUSTERING_CHK_LABEL') }}
              </div>
              <div class="col-4 sublayer-actions">
                <b-form-checkbox
                    :disabled="heatmapTracker[selectedLayer.gis_layer_id]"
                    v-model="featureReductionTracker[selectedLayer.gis_layer_id]"
                    @change="toggleFeatureReduction(selectedLayer)"
                    switch
                    size="md"
                ></b-form-checkbox>
              </div>
            </div>
            <div class="feature-reduction-toggle row" v-if="showFeatureReductionOptions(selectedLayer)">
                <div class="col-8 sublayer-name cursor-pointer">
                    {{ $t('ATLAS.SUBLAYER_MANAGER.HEATMAP_CHK_LABEL') }}
              </div>
              <div class="col-4 sublayer-actions">
                <b-form-checkbox
                    :disabled="featureReductionTracker[selectedLayer.gis_layer_id]"
                    v-model="heatmapTracker[selectedLayer.gis_layer_id]"
                    @change="toggleHeatmap(selectedLayer)"
                    switch
                    size="md"
                ></b-form-checkbox>
              </div>
            </div>
            <div class="atlas-floating-sublayer-details" v-show="getActiveSublayerId">
              <div class="sublayer-column-header">
                <div class="text-center mb-4">
                  {{getActiveSublayerName}}
                </div>

                <div class="text-center">
                  <div class="text-center d-flex justify-content-between">
                    <st-button
                        size="small"
                        variant="link"
                        :callback="suggestScale"
                    >
                      {{ $t('ATLAS.SUBLAYER_MANAGER.SUGGEST_SCALE_RANGE_TEXT') }}
                    </st-button>
                  </div>
                  <div class="text-center" id="scale-range-slider"></div>
                </div>
                <div class="text-center pb-4">
                    {{ $t('ATLAS.SUBLAYER_MANAGER.OPACITY_SLIDER_TEXT') }}
                </div>
                <div class="text-center" id="opacity-slider"></div>
                <div class="d-flex justify-content-between mx-2 my-2">
                  <st-button
                      size="medium"
                      variant="link"
                      :callback="hideActiveSublayer"
                  > {{ $t('GENERAL.BUTTON.CANCEL') }}
                  </st-button>
                  <st-button
                      size="medium"
                      variant="primary"
                      :callback="doSubmit"
                      v-if="isStaff"
                  >
                    <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                  </st-button>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="text-center">{{ $t('ATLAS.SUBLAYER_MANAGER.EMPTY_SUBLAYERS_TEXT') }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ScaleRangeSlider from "@arcgis/core/widgets/ScaleRangeSlider";
import scaleRange from "@arcgis/core/smartMapping/heuristics/scaleRange";
import Slider from "@arcgis/core/widgets/Slider";
// import * as heatmapRendererCreator from "@arcgis/core/smartMapping/renderers/heatmap.js";
// import HeatmapRenderer from "@arcgis/core/renderers/HeatmapRenderer.js";

const GEOM_TYPE_POINT = 'point';
const ESRI_GEOM_TYPE_POINT = 'esriGeometryPoint';

const CLUSTER_LABEL_CONFIG = [{
    labelExpressionInfo: {
        expression: "return ($feature.cluster_count);"
    },
    deconflictionStrategy: "none",
    labelPlacement: "center-center",
    symbol: {
        type: "text",
        color: "black",
        font: {
            size: "8px"
        },
        // haloSize: 1,
        // haloColor: "white"
    }
}];

const CLUSTER_SETTINGS = {
  type: "cluster",
  // maxScale: 10000,
  clusterRadius: 88,
  clusterMinSize : "32px",
  clusterMaxSize: "68px",
  labelsVisible: true,
  labelingInfo: CLUSTER_LABEL_CONFIG,
};

const heatmapRenderer = {
    type: 'heatmap',
    radius: 12,
    // maxDensity: 1,
    // minDensity: 0.1,
};

export default {
    name: 'SublayerManager',
    data() {
      return {
        activeLayerId: null,
        activeSublayer: null,
        activeFeatureLayer: null,
        scaleRangeSlider: null,
        opacitySlider: null,
        heatmapTracker: {},
        featureReductionTracker: {},
        featureReductionSettings: CLUSTER_SETTINGS,
      }
    },
    computed: {
      ...mapGetters({
        layer: 'atlas/layer-manager/layer',
        selectedLayers: 'atlas/layer-manager/selectedLayers',
        getSublayerVisibilityById: 'atlas/layer-manager/getSublayerVisibilityById',
        gisView: 'atlas/layer-manager/activeGisView',
        isStaff: "auth/isStaff",
      }),
      selectedLayer() {
          let sublayers = {}
          if (this.selectedLayers?.[this.layer.gis_layer_id]?.sublayers) {
            sublayers = this.layer.sublayers.map((layer) => {
                return {
                  ...layer,
                  visible: this.selectedLayers[this.layer.gis_layer_id]?.sublayers[layer.gis_id]?.visible
                }
            });
          }
          if (!this.selectedLayerVisibility) {
            sublayers = this.layer.sublayers.map((layer) => {
              return {
                ...layer,
                visible: false
              }
            });
          }

          return {...this.layer, sublayers}
      },
      selectedLayerVisibility() {
        return this.selectedLayers[this.layer.gis_layer_id].visible;
      },
      description() {
        let name = `${this.$t(`ATLAS.LAYER_MANAGER.${this.layer.group.toUpperCase()}_LABEL`)} / `;
        name += this.layer.container_name ? `${this.layer.container_name} / ` : '';
        name += this.layer.name;
        return name;
      },
      getActiveSublayerId() {
        return this.activeSublayer ?
          this.activeSublayer.gis_id ? this.activeSublayer.gis_id : '0'
          : '';
      },
      getActiveSublayerName() {
        return this.activeSublayer ? this.activeSublayer.name : '';
      },
    },
    watch: {
      layer(selectedLayer) {
        if(this.activeLayerId !== selectedLayer.id) {
          this.activeLayerId = selectedLayer.id;
          this.activeSublayer = null;
          this.activeFeatureLayer = null;
          this.scaleRangeSlider.visible = false;
        }
      },
    },
    methods: {
      ...mapActions({
        showSublayer: 'atlas/layer-manager/showSublayer',
        hideSublayer: 'atlas/layer-manager/hideSublayer',
        updateSublayer: "atlas/layer-manager/updateSublayer",
      }),
      toggleFeatureReduction(selectedLayer) {
        Object.values(this.gisView?.layerList[selectedLayer.gis_layer_id] || {}).forEach(element => {
            if(element.geometryType != GEOM_TYPE_POINT) return;

            if(this.featureReductionTracker[this.layer.gis_layer_id]) {
                // maximize scale when viewing in cluster mode
                if(element.originalMinScale === undefined) {
                    element.originalMinScale = element.minScale;
                }
                element.minScale = 0;
                element.featureReduction = this.featureReductionSettings;
            } else {
                // restore original scale when featureCluster is off
                if(element.originalMinScale != null) {
                    element.minScale = element.originalMinScale;
                }
                element.featureReduction = null;
            }
        });
      },
      toggleHeatmap(selectedLayer) {
        Object.values(this.gisView?.layerList[selectedLayer.gis_layer_id] || {}).forEach(element => {
            if(element.geometryType != GEOM_TYPE_POINT) return;

            if(this.heatmapTracker[this.layer.gis_layer_id]) {
                // set heatmap renderer
                element.originalRenderer = element.renderer;
                element.renderer = heatmapRenderer;
            } else if(element.originalRenderer) {
                // restore basic renderer if set
                element.renderer = element.originalRenderer;
            }
        });
      },
      showFeatureReductionOptions(selectedLayer) {
        return (selectedLayer.sublayers || []).some(v => v.visible == true && v.sublayer_type == ESRI_GEOM_TYPE_POINT);
      },
      toggleSublayer(checked, sublayerId) {
        const payload = {
          layerId: this.layer.gis_layer_id,
          sublayerId,
        };
        this[checked ? 'showSublayer' : 'hideSublayer'](payload);
      },
      async sublayerClicked(evt, subLayer) {
        if (!this.layer.feature_service_url) {return;}

        if (this.activeSublayer && this.activeSublayer.gis_id == subLayer.gis_id) {
          this.hideActiveSublayer();
          return;
        }

        if(!subLayer.visible || !this.gisView?.layerList[this.layer.gis_layer_id]) {
          await this.showSublayer({
            layerId: this.layer.gis_layer_id,
            sublayerId: subLayer.gis_id,
          });
        }

        this.activeFeatureLayer = this.gisView?.layerList[this.layer.gis_layer_id][subLayer.gis_id];

        this.activeFeatureLayer?.load().then(() => {
          this.scaleRangeSlider.layer = this.activeFeatureLayer;
          this.scaleRangeSlider.minScale = this.activeFeatureLayer.minScale;
          this.scaleRangeSlider.maxScale = this.activeFeatureLayer.maxScale;
          this.scaleRangeSlider.visible = true;

          this.opacitySlider.values = [(1 - this.activeFeatureLayer.opacity) * 100];

          this.activeSublayer = subLayer;
        });
      },
      async zoomToSublayer(evt, subLayer) {
        if(!subLayer.visible || !this.gisView?.layerList[this.layer.gis_layer_id]) {
          await this.showSublayer({
            layerId: this.layer.gis_layer_id,
            sublayerId: subLayer.gis_id,
          });
        }

        if (!this.layer.feature_service_url) {return;}

        this.activeFeatureLayer = this.gisView?.layerList[this.layer.gis_layer_id][subLayer.gis_id];

        this.activeFeatureLayer?.load().then(() => {
          const sublayerExtent = this.activeFeatureLayer.projectedFullExtent ?? this.activeFeatureLayer.fullExtent;
          this.gisView?.view.goTo(
            {target: sublayerExtent}
          );
        });
      },
      loadScaleRangeSliderWidget() {
        this.scaleRangeSlider = new ScaleRangeSlider({
          container: document.getElementById("scale-range-slider"),
          region: "RO",
          view: this.gisView?.view,
          visible: false,
        });
        this.scaleRangeSlider.visibleElements = {
            preview: false  // thumbnail preview of map will not be displayed
        }

        const that = this;
        this.scaleRangeSlider.watch(["minScale", "maxScale"], function(value, oldValue, name) {
            if(that.activeFeatureLayer) {
                that.activeFeatureLayer[name] = value;
            }
        });
      },
      loadOpacitySlider() {
        this.opacitySlider = new Slider({
          container: "opacity-slider",
          min: 0,
          max: 100,
          precision: 0,
          values: [ 50 ],
          snapOnClickEnabled: false,
          visibleElements: {
            labels: true,
            rangeLabels: true
          }
        });

        this.opacitySlider.on('thumb-drag', (evt) => {
          this.activeFeatureLayer.opacity = (100 - evt.value) / 100;
        })
      },
      suggestScale() {
        if(!this.activeFeatureLayer || !this.activeFeatureLayer.loaded) {return;}

        scaleRange({
          layer: this.activeFeatureLayer,
          view: this.gisView?.view,
        }).then((res) => {
          this.scaleRangeSlider.minScale = res.minScale;
          this.scaleRangeSlider.maxScale = res.maxScale;

          this.activeFeatureLayer.minScale = res.minScale;
          this.activeFeatureLayer.maxScale = res.maxScale;
        });
      },
      async doSubmit() {
        this.updateSublayer({
          layer: this.layer,
          sublayer: {
            ...this.activeSublayer,
            minScale: this.activeFeatureLayer.minScale,
            maxScale: this.activeFeatureLayer.maxScale,
            transparency: (1 - this.activeFeatureLayer.opacity) * 100,
          }
        })
      },
      hasSublayerSelected() {
        return !!this.activeSublayer;
      },
      hideActiveSublayer() {
        this.activeSublayer = null;
        this.activeFeatureLayer.load().then((res) => {
          this.scaleRangeSlider.minScale = res.minScale;
          this.scaleRangeSlider.maxScale = res.maxScale;
        });
        this.activeFeatureLayer = null;
      },
    },
    mounted() {
        this.loadScaleRangeSliderWidget();
        this.loadOpacitySlider();
    }
}
</script>
<style lang="scss" scoped>
    .sublayer-container {
      padding: 1.25rem;
      overflow-y: auto;
      height: 100%;
      .sublayer-column-header {
        font-size: 0.825rem;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 0 1rem 0;
      }
      .sublayer-item {
        line-height: 2.25rem;
        margin: 0;
        .sublayer-name {
          padding: 0;
          text-align: left;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .feature-reduction-toggle{
        line-height: 2.25rem;
        margin: 24px 0 0 0;
      }
      .sublayer-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 0 0 1rem;
        text-align: right;
        .custom-control {
          height: 1rem;
        }
        i {
          margin-bottom: 0.3rem;
          color: #286AE4;
          font-size: 1rem;
          cursor: pointer;
          width: 2rem;
        }
      }
      .view-sublayer-details-icon {
        margin-right:1.25rem
      }
      .atlas-floating-sublayer-details {
        position: absolute;
        padding-top: 20px;
        top:10%;
        right:320px;
        width: 320px;
        background-color: #F2F3F7;
        border-right: 1px solid lightgray;
        margin-left: -3px;
        box-shadow: -1px 4px 4px 0 rgb(0 0 0 / 16%);
        z-index: 1;
        #scale-range-slider {
          margin: 12px 6px;
        }
        #opacity-slider {
          margin: 12px 0;
        }
      }
    }
</style>
