<template>
    <div :class="['layer-item', layerTypeClass, layerSelected == layer.id ? 'atlas-layer-active' :'' ]">
        <div class="layer-name"
            :class="{'cursor-pointer': layer.layer_type === 'simple'}"
            @click="openLayerDetails(layer.id)"
        >
            <i :class="[layerIconClass, layerIconColorClass, 'layer-icon', layerSelected == layer.id ? 'atlas-layer-active' :'']"></i>
            <span class="layer-label">{{ layer.name }}</span>
        </div>
        <div v-if="showPublishStatus" class="align-middle ml-4">
            <span :class="`label label-md label-inline label-light-${layer.type}`">
                {{ $t(`ATLAS.PUBLISH_STATUS.${layer.publish_status.toUpperCase()}`) }}
            </span>
        </div>
        <div v-if="isStaff && layer.is_template_layer" class="align-middle ml-4">
             <span :class="`label label-md label-inline label-light-primary`">
                {{ $t(`ATLAS.FIELDS.TEMPLATE_LAYER_ID`) }}
            </span>
        </div>
        <div class="layer-actions visibility-section" v-if="isVisibiltySettingsVisible">
            <div :class="['visibility-column', layerTypeClass ]">
                <div class="row">
                    <div class="col-6 px-0">
                        <st-input-text
                            :name="layer.id"
                            v-model="model"
                            type="number"
                            @onFocus="onFocusChange(layer.id)"
                            :disabled= "isSimpleLayers"
                        />
                    </div>
                    <div class="col-4 px-0">
                        <b-button
                            :class="`btn btn-icon btn-light btn-hover-primary st-btn-data-table`"
                            @click="doAction(layer)"
                            v-if="openFocus  === layer.id"
                        >
                            <span :class="`st-data-table-icon svg-icon svg-icon-md  svg-icon-primary`">
                                <i class="fas fa-save"></i>
                            </span>
                        </b-button>
                    </div>
                    <div class="col-2"></div>
                </div>
            </div>
        </div>
        <div class="layer-actions" v-else>
            <b-form-checkbox
                switch
                :checked="isLayerSelected"
                @change="toggleLayer"
                size="lg"
                v-if="hasLayers"
            ></b-form-checkbox>
            <b-dropdown
                v-if="isStaff"
                :disabled="!canManageLayerItem && !canPublish && !canRefresh && !canView"
                class="button-more-action"
                variant="link"
                no-caret
                menu-class="button-more-action-menu"
            >
                <template #button-content>
                    <i class="fas fa-ellipsis-h"></i>
                </template>
                <!-- TODO: uncomment once functionalities are implemented -->
                <!-- <b-dropdown-item-button>{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.SHARE') }}</b-dropdown-item-button> -->
                <b-dropdown-item-button
                    v-if="canPublish"
                    @click="publishLayer">
                    {{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.PUBLISH')}}
                </b-dropdown-item-button>
                <b-dropdown-item-button
                    v-if="canManageLayerItem"
                    @click="editLayer"
                >
                    {{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.EDIT') }}
                </b-dropdown-item-button>
                <!-- <b-dropdown-item-button>{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.EXPORT') }}</b-dropdown-item-button> -->
                <b-dropdown-item-button
                    v-if="canManageLayerItem"
                    @click="deleteLayer"
                >
                    {{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.DELETE') }}
                </b-dropdown-item-button>
                <b-dropdown-item-button
                    v-if="canRefresh"
                    :disabled="isLoading"
                    @click="resyncLayer()"
                    :class="dropdownStyleClasses"
                >
                    {{ $t('GENERAL.ACTIONS.DO_UPDATE') }}
                </b-dropdown-item-button>
                <b-dropdown-item-button
                    v-if="canView"
                    @click="viewLayerDetails()"
                    :class="dropdownStyleClasses"
                >
                    {{ $t('GENERAL.ACTIONS.VIEW') }}
                </b-dropdown-item-button>
            </b-dropdown>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Roles from '@/security/roles';

const LAYER_TYPE = {
    simple: 'simple',
    container: 'container'
};

const LAYER_TYPE_CLASS = {
    simple: 'simple-layer',
    container: 'container-layer'
};

const SIMPLE_LAYER_GROUPS = ['private', 'organisation'];

const LAYER_TYPE_ICON_CLASS = {
    simple: 'fas fa-layer-group',
    container: 'fas fa-folder',
    containerOpen: 'fas fa-folder-open'
};
const LAYER_COLOR_ICON_CLASS = {
    private: 'layer-icon-light-red',
    protected: 'layer-icon-light-yellow',
    public: 'layer-icon-light-green'
};

export default {
    name: "LayerItem",
    props: {
        layer: {
            type: Object,
            required: true,
        },
        isContainerOpen: {
            type: Object,
        },
        isInputFocus: {
            type: String,
        },
        openFocus: {
            type: String,
        },
        layerSelected:{
            type: String,
            default: '0'
        },
        group:{
            type: String,
            required: true,
        },
        container:{
            type: Object,
            default: () => ({ }),
        },
        isSimpleLayers: {
          type: Boolean,
          default: false
        },
        isOrganisationSection: {
            type: Boolean,
            default: false
        },
        isPrivateSection: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            layerType: this.layer.layer_type,
            containerOpen: false,
            layerTypeClass: LAYER_TYPE_CLASS[this.layer.layer_type],
            type: 'primary',
            model: null,
        }
    },
    computed: {
        ...mapGetters({
            getLayerVisibilityById: 'atlas/layer-manager/getLayerVisibilityById',
            openedContainers: 'atlas/layer-manager/openContainers',
            isVisibiltySettingsVisible: 'atlas/sidebar/isVisibiltySettingsVisible',
            atlasPermissions: 'atlas/layer-manager/atlasPermissions',
            isSuperAdmin: 'auth/isSuperAdmin',
            currentUser: 'auth/currentUser',
            isStaff: 'auth/isStaff',
            loading: 'shared/loading',
        }),
        layerIconClass() {
            if (this.containerOpen) {
                return LAYER_TYPE_ICON_CLASS.containerOpen;
            }
            return LAYER_TYPE_ICON_CLASS[this.layerType];
        },
        layerIconColorClass() {
            return LAYER_COLOR_ICON_CLASS[this.layer.access_level];
        },
        isLayerSelected() {
            return this.getLayerVisibilityById(this.layer.gis_layer_id);
        },
        hasLayers() {
            if (this.layer.layer_type === 'simple') return true;
            return !!this.layer.components.length;
        },
        canPublish() {
            return (this.layer.layer_type === 'simple' &&
                    !this.layer?.container_id &&
                    this.layer?.status &&
                    !this.layer?.is_published &&
                    (this.atlasPermissions.hasPermissionToPublish || this.isSuperAdmin)
                );
        },
        showPublishStatus() {
            return (this.layer.layer_type === 'simple' && SIMPLE_LAYER_GROUPS.indexOf(this.group) !== -1);
        },
        isLoading() {
            return this.loading['atlas-layer-manager/reSyncLayers'];
        },
        dropdownStyleClasses() {
            const classes = [];
            if (this.isLoading) {
                classes.push('spinner spinner-right');
            }
            return classes;
        },
        canManageLayers() {
            if(this.isSuperAdmin) return true;  // super-admin can manage anything
            if(this.isPrivateSection) return true;  // user can manage own private layers
            // For staff users, ATLAS section is disabled(not "isSimpleLayers" means ATLAS section)
            if(!this.isSimpleLayers) return false;
            // organisation layers depend on permission
            return this.atlasPermissions.hasPermissionToManage;
        },
        canManageLayerItem() {
            if(!this.canManageLayers) return false;
            if(!this.isSuperAdmin) {
                // redundant.. but Atlas admin cannot delete super_admin owned layers.
                if(this.layer.owner_user_type == Roles.values['super_admin']) {
                    return false;
                }
                if(this.isOrganisationSection && this.layer.owner_user_id !== this.currentUser.user_id) {
                    return false;
                }
            }
            return true;
        },
        canRefresh() {
            return this.layer.layer_type === 'simple' &&
                    (this.atlasPermissions.hasPermissionToPublish || this.isSuperAdmin);
        },
        canView() {
            return this.atlasPermissions.hasPermissionToView || !!this.isSuperAdmin;
        },
    },
    mounted() {
        if(this.openedContainers.includes(this.layer.id)) {
            this.containerOpen = true;
        }
        this.model = this.isSimpleLayers ? 0 : this.layer.view_order;
    },
    methods: {
        ...mapActions({
            showLayer: 'atlas/layer-manager/showLayer',
            hideLayer: 'atlas/layer-manager/hideLayer',
            showAllLayers: 'atlas/layer-manager/showAllLayers',
            hideAllLayers: 'atlas/layer-manager/hideAllLayers',
            selectLayer: 'atlas/layer-manager/selectLayer',
            showPublishModal: 'atlas/layer-manager/showPublishModal',
            showEditModal: 'atlas/layer-manager/showEditModal',
            doDeleteLayer: 'atlas/layer-manager/deleteLayer',
            reSyncLayer: 'atlas/layer-manager/reSyncLayer',
            updateLayerVisibilityOrder: 'atlas/visibility-settings/updateLayerVisibilityOrder',
            getLayers: "atlas/layer-manager/getLayers",
        }),
        openLayerDetails(layerId) {
            if (!this.isVisibiltySettingsVisible) {
                if (this.layerType === LAYER_TYPE.container) {
                    this.containerOpen = !this.containerOpen;
                    this.$emit('update:isContainerOpen', {container: this.containerOpen, id: layerId});
                } else {
                    this.selectLayer({...this.layer, group: this.group, container_name: this.container?.name ?? ''});
                }
            }
        },
        toggleLayer(checked) {
            if (this.layer.layer_type === LAYER_TYPE.container) {
                this[checked ? 'showAllLayers': 'hideAllLayers'](this.layer.components);
            } else {
                this[checked ? 'showLayer' : 'hideLayer'](this.layer);
            }
        },
        publishLayer() {
            this.showPublishModal(this.layer);
        },
        editLayer() {
            this.showEditModal({layer: this.layer, isOrgLayer: this.isOrganisationSection});
        },
        deleteLayer() {
            this.$alert({
                type: 'error',
                    title: this.$t('ATLAS.LAYER_MANAGER.DELETE_MODAL_TITLE'),
                    text: this.$t('ATLAS.LAYER_MANAGER.DELETE_MODAL_SUBTITLE'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                       this.doDeleteLayer({layer: this.layer, isOrgLayer: this.isOrganisationSection});
                    }
                });
        },
        resyncLayer() {
            const isSimpleLayer = this.layer.layer_type === 'simple' && !!this.layer.container_id;
            this.reSyncLayer({id: this.layer.id, isSimpleLayer})
        },
        async doAction() {
            await this.updateLayerVisibilityOrder({layer: this.layer, value: parseInt(this.model)})
            this.getLayers(this.isStaff);
        },
        onFocusChange(id) {
            this.$emit('update:isInputFocus', this.layer.id);
        },
        viewLayerDetails() {
            this.$emit('showLayerViewModal', this.layer);
        },
    }
}
</script>

<style lang="scss">
    .atlas-layer-active {
        color:#286AE4;
    }
    .visibility-section {
        .visibility-column {
            width: 150px;
        }
        .container-layer {
            margin-right: 20px;
        }
    }
</style>
