<template>
    <div v-if="urbanismRegulation" class="main-wrapper">
        <div v-html="urbanismRegulation.content_html"></div>
    </div>
</template>

<script>

export default {
    name: "IntersectionUrbanRegulationCardDetails",
    props: {
        urbanismRegulation: {
            type: Object,
        },
    },
    data() {
        return {
            RLU_MAPPING_NAMES :{
                RLU_RT: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_RT'),
                RLU_RJ: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_RJ'),
                RLU_RE: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_RE'),
                RLU_POT: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_POT'),
                RLU_CUT: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_CUT'),
                RLU_RDA: this.$t('ATLAS.INTERSECTION_DETAILS_MODAL.RLU_RDA'),
            }
        }
    },
    methods: {
        headerText(key) {
            return this.RLU_MAPPING_NAMES[key] ?? key;
        },
    },
    mounted() {
        const h1 = this.$el.querySelectorAll("h1");
        h1.forEach(element => {
            const sibling = element.nextSibling;
            const siblingType = sibling.matches('table');

            if (siblingType) {
                sibling.classList.add('hide');
                sibling.style['display'] = 'none';

                element.addEventListener('click', () => {
                    if (sibling.classList.contains('hide')) {
                        sibling.classList.remove('hide');
                        sibling.style['display'] = 'block';
                    } else {
                        sibling.classList.add('hide');
                        sibling.style['display'] = 'none';
                    }
                });
            }
        });
    }
};
</script>

<style scoped>
.main-wrapper >>> table {
  height: auto !important;;
}
</style>
