import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class AtlasPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.publish = permissionChecker.match(
            Permissions.values.atlasPublish,
        );

        this.manage = permissionChecker.match(
            Permissions.values.atlasManage,
        );

        this.view = permissionChecker.match(
            Permissions.values.atlasDetails,
        );
    }
}
