<template>
    <div>
        <st-modal
            ref="modal"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            hide-header-close
            size="xl"
            :id="modalId"
        >
            <template #header>
                <div>
                    <p class="font-size-h6 font-weight-bold mb-0 pb-0">
                        {{ $t('ATLAS.COMPARE_LAYER_MODAL.HEADER') }}
                    </p>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col">
                        <p class="font-size-h6">
                            {{ $t("ATLAS.COMPARE_LAYER_MODAL.SOURCE_LAYER", {layer: sourceLayerName }) }}
                        </p>
                        <label>{{ $t("ATLAS.COMPARE_LAYER_MODAL.SOURCE_LAYER_LABEL") }}</label>
                        <st-data-table
                            :items="publishedLayer"
                            :fields="fieldsPublished"
                            :modelPresenter="presenter"
                            responsive="sm"
                            hover
                        >
                        </st-data-table>
                    </div>
                    <div class="col">
                        <p class="font-size-h6">
                            {{ $t("ATLAS.COMPARE_LAYER_MODAL.TEMPLATE_LAYER") }}: {{destinationLayerName}}
                        </p>
                        <label>{{ $t("ATLAS.COMPARE_LAYER_MODAL.TEMPLATE_LAYER_LABEL") }}</label>
                        <st-data-table
                            :items="templateLayer"
                            :fields="templatePublished"
                            :modelPresenter="presenterTemplate"
                            responsive="sm"
                            hover
                        >
                        </st-data-table>
                    </div>
                </div>
                <div class="mt-6">
                    <h5>{{ $t("GENERAL.STATUS") }}:
                        <span v-if="!status" class="text-success">{{ $t("ATLAS.COMPARE_LAYER_MODAL.STATUS.READY") }}</span>
                        <span v-else class="text-danger">{{ $t("ATLAS.COMPARE_LAYER_MODAL.STATUS.NOT_READY") }}</span>
                    </h5>
                    <b-form-checkbox
                        size="lg"
                        v-model="forcePublish"
                        :disabled="!status"
                        class="mt-4"
                    >
                        {{ $t("ATLAS.COMPARE_LAYER_MODAL.FORCE_PUBLISH") }}
                    </b-form-checkbox>
                </div>
                <div class="pt-6">
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="link"
                            :callback="hide"
                        >
                            {{ $t("GENERAL.BUTTON.CANCEL") }}
                        </st-button>
                        <st-button
                            size="large"
                            variant="primary"
                            :callback="doSubmit"
                            :disabled="disablePublish"
                        >
                            <span>{{ $t("GENERAL.BUTTON.PUBLISH") }}</span>
                        </st-button>
                    </div>
            </div>
            </template>
        </st-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { generateRandomId } from "@/core/helpers/globalMethods";
import { PublishedLayerModel }  from "@/modules/atlas/models/published-layer-model";
import { TemplateLayerModel }  from "@/modules/atlas/models/template-layer-model";
const { publishedFields } = PublishedLayerModel;
const { templateFields } = TemplateLayerModel;

const STATUS = {
    '1': 'ok',
    '2': 'missing',
    '3': 'new'
};
const TYPE = {
    '1': 'success',
    '2': 'danger',
    '3': 'primary'
}
export default {
    inheritAttrs: false,
    name: "CompareLayersModal",
    data() {
        return {
            modalId: `st-modal-layer-publish-${generateRandomId()}`,
            presenter: PublishedLayerModel.presenter,
            presenterTemplate: TemplateLayerModel.presenter,
            forcePublish: false,
        };
    },
    computed: {
        ...mapGetters({
            compareLayersData: 'atlas/layer-manager/compareLayersData',
            comparedLayersResult: 'atlas/layer-manager/comparedLayersResult',
        }),
        fieldsPublished() {
            return [
                publishedFields.name,
                publishedFields.geometryType,
                publishedFields.status.extendField({ labelType: true }),
            ]
        },
        templatePublished() {
            return [
                templateFields.name,
                templateFields.geometryType,
                templateFields.status.extendField({ labelType: true }),
            ]
        },
        publishedLayer() {
            return this.mapLayerData(this.comparedLayersResult.published_layer);
        },
        templateLayer() {
            return this.mapLayerData(this.comparedLayersResult.template_layer);
        },
        status() {
            return this.templateLayer.some((el) => el.status === 'missing');
        },
        sourceLayerName() {
             return this.compareLayersData?.sourceLayer?.name ?? '';
        },
        destinationLayerName() {
            return this.compareLayersData?.destinationLayerName ?? '';
        },
        disablePublish() {
            if (this.forcePublish) return false;
            return this.status;
        }
    },
    methods: {
        ...mapActions({
            hideCompareLayersModal: "atlas/layer-manager/hideCompareLayersModal",
            publishLayer: "atlas/layer-manager/publish",
            getLayerPublishStatus: "atlas/layer-manager/getLayerCreationStatus",
            addPublishedLayerToList: "atlas/layer-manager/addPublishedLayerToList",
        }),
        show() {
            this.$refs["modal"].show();
        },
        hide() {
            this.$refs["modal"].hide();
            this.hideCompareLayersModal();
            this.forcePublish = false;
        },
        mapLayerData(data) {
            if (!data.length) return [];
            return data.map(layer => {
                const status = layer.status
                return { ...layer,
                    value: layer.name,
                    status: STATUS[status],
                    type: TYPE[status],
                    geometryType: layer.geometryType ?? ''
                }
            });
        },
        async doSubmit() {
            const payload = {...this.compareLayersData.sourceLayer, force_publish: this.forcePublish};
            try {
                const { id } = await this.publishLayer(payload);
                if (id) {
                    this.polling = setInterval(() => {
                        this.checkLayerPublishStatus(id, payload.target_component_id);
                    }, 3000);
                }
            } catch (error) {
                this.$notify({
                    type: 'error',
                    message: this.$t('GENERAL.PUBLISHED_FAILED'),
                });
            } finally {
                this.hide();
                this.$alert({
                    type: 'warning',
                    text: this.$t('ATLAS.PUBLISH_STATUS.MESSAGE_CONFIRMATION'),
                    cancelButtonText: this.$t('GENERAL.BUTTON.CONFIRM'),
                    showCancelButton: true,
                    showConfirmButton: false,
                })
            }
        },
        async checkLayerPublishStatus(id, target_component_id) {
            try {
                const { status, ...layer } = await this.getLayerPublishStatus(id);
                this.publishStatus = status;
                if (status === "completed") {
                    this.addPublishedLayerToList({...layer, target_component_id});
                    this.$notify({
                        type: 'success',
                        message: this.$t('ATLAS.PUBLISH_STATUS.MESSAGE_SUCCESS'),
                     });
                    clearInterval(this.polling);
                }
                if (status === "error") {
                    this.$notify({
                        type: 'error',
                        message: this.$t('ATLAS.PUBLISH_STATUS.MESSAGE_FAILED'),
                    });
                    clearInterval(this.polling);
                }
            } catch (error) {
                clearInterval(this.polling);
                this.$notify({
                    type: 'error',
                    message: this.$t('ATLAS.PUBLISH_STATUS.MESSAGE_FAILED'),
                });
            } finally {
                if (this.publishStatus !== "new" && this.publishStatus !== "pending") {
                    clearInterval(this.polling);
                }
            }
        },

    },
    beforeDestroy() {
        if (this.polling) {
            clearInterval(this.polling);
        }
    },
};
</script>
