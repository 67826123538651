<template>
    <div>
        <div class="atlas-right-sidebar"
        :class="{ 'atlas-right-sidebar-public-view': isMobileDevice }"
        >
            <div class="atlas-sidebar-header">
                <div class="atlas-sublayer-sidebar-title">
                    <portal-target name="atlas-sublayer-sidebar-title"></portal-target>
                </div>
                <div class="atlas-sidebar-close" @click="close">
                    <i class="far fa-times-circle text-dark"></i>
                </div>
            </div>
            <sublayer-manager/>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: 'RightSidebar',
    components: {
        SublayerManager: () => import(/* webpackChunkName: "atlas" */ '@/modules/atlas/components/SublayerManager.vue'),
    },
    props: {
        isMobileDevice: Boolean,
    },
    methods: {
        ...mapActions({
            hideSublayerSidebar: 'atlas/layer-manager/hideSublayerSidebar',
        }),
        close() {
            this.hideSublayerSidebar();
        }
    },
}
</script>
<style lang="scss">
    .atlas-right-sidebar {
        position: absolute;
        top:0;
        right:0;
        height: 100%;
        width: 320px;
        background-color: #F2F3F7;
        border-right: 1px solid lightgray;
        margin-left: -3px;
        box-shadow: -1px 4px 4px 0 rgb(0 0 0 / 16%);
        z-index: 1;
        .atlas-sidebar-header {
            height: 3.75rem;
            padding: 0 1.5rem;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #C7CFD6;
            .atlas-sidebar-title {
                font-weight: 500;
            }
            .atlas-sidebar-close {
                margin-left: auto;
                cursor: pointer;
            }
        }
    }
    .atlas-right-sidebar-public-view{
        z-index: 1001;
    }
</style>
