<template>
    <div class="comments-sidebar">
        <div class="comments-sidebar-header">
            <div class="comments-sidebar-title">
                {{ $t('ATLAS.COMMENTS_MANAGER.COMMENTS') }}
            </div>
            <!-- <div class="comments-sidebar-close" @click="close">
                <i class="far fa-times-circle text-dark"></i>
            </div> -->
        </div>

        <div class="comments-container">
            <div class="comments-instructions" v-if="showInstructions">
                {{ $t('ATLAS.COMMENTS_MANAGER.INSTRUCTIONS') }}
            </div>
            <div v-if="comments && comments.length" class="comments-items">
                <div class="comments-item" v-for="item in comments" :key="item.id">
                    <div class="comments-body">
                        <div class="comments-controls">
                            <st-button
                                v-if="item.parent_id"
                                :callback="() => deleteSingleItem(item.id)"
                                variant="link">
                                <i class="fas fa-trash-alt st-btn-text-danger text-danger danger"></i>
                            </st-button>
                            <!-- <st-button
                                v-if="currentUser.user_id==item.owner_user_id"
                                :callback="() => editItem(item)"
                                variant="link">
                                <i class="fas fa-edit st-btn-text-primary"></i>
                            </st-button> -->
                        </div>
                        <div class="comments-sender">{{ item.commenting_user_name }}</div>
                        <div class="comments-message">{{ item.comment }}</div>
                        <div class="comments-date">{{ formatDate(item.created_date) }}</div>
                    </div>
                </div>
            </div>
            <div class="new-comment" v-if="parentIdIsset || newPointIsSelected">

                <div class="row">
                    <div class="col">
                        <div class="form-group st-form-group-textarea mt-6">
                            <b-form-textarea
                                ref="addComment"
                                name="addComment"
                                v-model="addedComment"
                                class="st-form-textarea"
                                rows="5"
                                no-resize
                            >
                            </b-form-textarea>
                        </div>
                    </div>
                </div>
                <div class="new-comment-button">
                    <st-button
                        v-if="parentIdIsset"
                        :disabled="buttonDisabled"
                        :callback="submit"
                        :spinner="isLoading"
                        variant="light"
                        class="w-100 st-btn-text-primary"
                    >
                        {{ $t('ATLAS.COMMENTS_MANAGER.REPLY_TO_COMMENT') }}
                    </st-button>
                    <st-button
                        v-else
                        :disabled="buttonDisabled"
                        :callback="submit"
                        :spinner="isLoading"
                        variant="primary"
                        class="w-100"
                    >
                        {{ $t('ATLAS.COMMENTS_MANAGER.ADD_COMMENT') }}
                    </st-button>
                </div>
                <div class="delete-comments-button">
                    <st-button
                        :disabled="deleteAllBtnDisabled"
                        :callback="deleteAll"
                        :spinner="isLoading"
                        variant="danger"
                        class="w-100 mt-10"
                    >
                        {{ $t('ATLAS.COMMENTS_MANAGER.DELETE_ALL_COMMENTS') }}
                    </st-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';


export default {
    name: 'CommentsSidebar',
    props: {},
    data() {
        return {
            addedComment: '',
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            commentsExtent: 'atlas/comments/commentsExtent',
            parentId: 'atlas/comments/parentId',
            comments: 'atlas/comments/selectedComments',
            newCommentCoordinates: 'atlas/comments/newCommentCoordinates',
            currentUser: 'auth/currentUser',
            isStaff: "auth/isStaff",
        }),
        parentIdIsset() {
            return !!this.parentId;
        },
        newPointIsSelected() {
            return !!this.newCommentCoordinates;
        },
        showInstructions() {
            return !this.parentId && !this.newCommentCoordinates
        },
        buttonDisabled() {
            return this.isLoading || !this.addedComment;
        },
        deleteAllBtnDisabled() {
            return !this.comments?.length || this.isLoading;
        },
    },
    methods: {
        ...mapActions({
            addComment: 'atlas/comments/addComment',
            deleteComment: 'atlas/comments/deleteComment',
            getComments: 'atlas/comments/getComments',
            hideCommentsSidebar: 'atlas/comments/hideComments',
        }),
        submit() {
            this.isLoading = true;
            const originalThreadId = this.parentId;
            this.addComment({
                comment: this.addedComment,
                coordinates: [this.newCommentCoordinates],
                parent_id: originalThreadId,
                commenting_user_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`
            })
            .then((result) => {
                this.addedComment = '';
                this.getComments({parentId: originalThreadId})
                .then(() => {
                    this.isLoading = false;
                    if(!originalThreadId) {
                        // display fresh root comment in sidebar
                        this.getComments({parentId: result.id});
                    }
                })
                .catch(() => {
                    this.isLoading = false;
                })
            })
            .catch(() => {
                this.isLoading = false;
            })
        },
        deleteAll() {
            this.$alert({
                type: 'warning',
                text: this.$t('ATLAS.COMMENTS_MANAGER.CONFIRMATION_MESSAGE_DELETE_ALL'),
                confirmButtonText: this.$t('GENERAL.YES'),
                cancelButtonText: this.$t('GENERAL.NO'),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.deleteComment({id: this.parentId, isRootComment: true})
                    .then(() => {
                        // reload root comments
                        this.getComments({parentId: null, extent: this.commentsExtent}).then(() => {
                            this.close();
                        })
                    });
                }
            });
        },
        deleteSingleItem(id) {
            this.$alert({
                type: 'warning',
                text: this.$t('ATLAS.COMMENTS_MANAGER.CONFIRMATION_MESSAGE_DELETE'),
                confirmButtonText: this.$t('GENERAL.YES'),
                cancelButtonText: this.$t('GENERAL.NO'),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.deleteComment({id})
                    .then(() => {
                        this.getComments({parentId: this.parentId});
                    });
                }
            });
        },
        editItem(item) {
            return false;
        },
        close() {
            this.hideCommentsSidebar();
        },
        formatDate(date) {
           return moment(date).format('YYYY/MM/DD');
        }
    },
}
</script>
<style lang="scss" scoped>
@import './src/assets/sass/pages/atlas/comments-sidebar';
</style>
