export const SYSTEM_LAYERS_ORDER = [
    {
        name: 'Certificat Urbanism',
        feature_service_url: window.VUE_ATLAS_CU_LAYER_PINPOINTS,
        index: 0,
    },
    {
        name: 'Autorizatii constructii',
        feature_service_url: window.VUE_ATLAS_AC_LAYER_PINPOINTS,
        index: 1,
    },
    {
        name: 'Certificat Edificare',
        feature_service_url: window.VUE_ATLAS_CE_LAYER_PINPOINTS,
        index: 2,
    },
    {
        name: 'Avize de Oportunitate',
        feature_service_url: window.VUE_ATLAS_AVIZOP_LAYER_PINPOINTS,
        index: 3,
    },
    {
        name: 'Avize PUG',
        feature_service_url: window.VUE_ATLAS_AVIZPUG_LAYER_PINPOINTS,
        index: 4,
    },
    {
        name: 'Avize PUZ',
        feature_service_url: window.VUE_ATLAS_AVIZPUZ_LAYER_PINPOINTS,
        index: 5,
    },
    {
        name: 'Avize PUD',
        feature_service_url: window.VUE_ATLAS_AVIZPUD_LAYER_PINPOINTS,
        index: 6,
    },
];

export const DEFAULT_LAYER_TYPE = 'simple';

// private layer ownership types, privat vs. organizatie
export const PLOT_PRIVATE = 'owner';
export const PLOT_ORGANISATION = 'organisation';

export const LODS = [
    {
        level: 20,
        resolution: 0.25,
        scale: 500,
    },
    {
        level: 19,
        resolution: 0.5,
        scale: 1000,
    },
    {
        level: 18,
        resolution: 0.1,
        scale: 2000,
    },
    {
        level: 17,
        resolution: 2.5,
        scale: 5000,
    },
    {
        level: 16,
        resolution: 5,
        scale: 10000,
    },
    {
        level: 15,
        resolution: 10,
        scale: 20000,
    },
    {
        level: 14,
        resolution: 12.5,
        scale: 25000,
    },
    {
        level: 13,
        resolution: 25,
        scale: 50000,
    },
    {
        level: 12,
        resolution: 50,
        scale: 100000,
    },
    {
        level: 11,
        resolution: 75,
        scale: 150000,
    },
    {
        level: 10,
        resolution: 152.87405657041106,
        scale: 300000,
    },
    {
        level: 9,
        resolution: 305.74811314055756,
        scale: 600000,
    },
    {
        level: 8,
        resolution: 611.4962262813797,
        scale: 1200000,
    },
];

export const simpleFillSymbol = {
    type: "simple-fill",
    color: [227, 139, 79, 0.3], // Orange, opacity 30%
    outline: {
        color: [255, 255, 255],
        width: 1,
    },
};

export const popupTemplate = {
    title: "{Name}",
    content: "{Description}",
};

export const addressPopupTemplate = {
    title: "{Address}",
    content: "{Description}",
};

export const commentSymbol = {
    type: "simple-marker",
    color: [227, 139, 79, 0.8],
    style: "triangle",
    // size: 12,
    // outline: null
}

export const activeCommentSymbol = {
    type: "simple-marker",
    color:  [255, 0, 255, 0.8],
    style: "triangle",
}

export const newCommentSymbol = {
    type: "simple-marker",
    color:  [255, 255, 0, 0.8],
    style: "triangle",
}

export const marker = {
    type: "simple-marker",
    style: "circle",
    color: "#1855C5",
    size: "16px",
    outline: {
        color: "#75A0F0",
        width: 3,
    },
};

export const COMMENTS_ZOOM_LEVEL = 2;
export const mapLocatorUrl = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer";
export const serviceUrl = "http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer";
export const STEREO70_WKID = 3844;
export const DEFAULT_PARCEL_SEARCH_ZOOM_LEVEL = 10;
export const MEASUREMENT_TYPE_PIN = 'pin';
export const MEASUREMENT_TYPE_AREA = 'area';
export const MEASUREMENT_TYPE_DISTANCE = 'distance';
