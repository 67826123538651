<template lang="">
    <div class="layer-manager" v-show="!isIntersectionManagerVisible">
        <portal to="atlas-sidebar-title">
          <span>{{ $t('ATLAS.LAYER_MANAGER.TITLE') }}</span>
        </portal>
        <div class="layer-manager__search">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fas fa-search"></i>
              </b-input-group-prepend>
              <b-form-input
                type="search"
                v-model="filter"
                :placeholder="$t('ATLAS.LAYER_MANAGER.SEARCH_LAYER')">
                </b-form-input>
            </b-input-group>
        </div>
        <layer-container
            v-if="isLoaded"
            :layers="filteredLayers"
            :simpleLayers="filteredSimpleLayers"
            :organisationLayers="filteredOrgLayers"
            :arcgisApps="arcgisApps"/>
        <template v-if="isStaff">
          <layer-uploader/>
          <layer-publish-form-modal ref="layerPublishModal"/>
          <layer-edit-modal ref="layerEditModal" />
          <compare-layers-modal ref="compareLayersModal"/>
          <arcgis-app-add-form-modal ref="arcgisAppAddFormModal"/>
          <arcgis-app-edit-form-modal ref="arcgisAppEditFormModal"/>
        </template>
    </div>
</template>
<script>
import LayerContainer from "@/modules/atlas/components/layer-manager/LayerContainer";
import LayerUploader from "@/modules/atlas/components/layer-manager/LayerUploader";
import LayerPublishFormModal from "@/modules/atlas/components/layer-manager/LayerPublishFormModal";
import LayerEditModal from "@/modules/atlas/components/layer-manager/LayerEditModal";
import CompareLayersModal from "@/modules/atlas/components/layer-manager/CompareLayersModal";
import ArcgisAppAddFormModal from "@/modules/atlas/components/arcgis-apps-manager/ArcgisAppAddFormModal";
import ArcgisAppEditFormModal from "@/modules/atlas/components/arcgis-apps-manager/ArcgisAppEditFormModal";
import { mapGetters, mapActions } from "vuex";
import { BInputGroupPrepend } from 'bootstrap-vue';

export default {
  name: "LayerManager",
  components: {
    LayerContainer,
    LayerUploader,
    LayerPublishFormModal,
    LayerEditModal,
    BInputGroupPrepend,
    CompareLayersModal,
    ArcgisAppAddFormModal,
    ArcgisAppEditFormModal,
  },
  data() {
    return {
      filter: "",
      isLoaded: false,
    };
  },
  watch: {
    isLayerPublishModalVisible(newValue) {
      if (newValue) {
        this.$refs.layerPublishModal.show();
      } else {
        this.$refs.layerPublishModal.hide();
      }
    },
    isLayerEditModalVisible(newValue) {
      if (newValue) {
        this.$refs.layerEditModal.show();
      } else {
        this.$refs.layerEditModal.hide();
      }
    },
    isCompareLayersModalVisible(newValue) {
      if (newValue) {
        this.$refs.compareLayersModal.show();
      } else {
        this.$refs.compareLayersModal.hide();
      }
    },
    isAppAddModalVisible(newValue) {
      if (newValue) {
        this.$refs.arcgisAppAddFormModal.show();
      } else {
        this.$refs.arcgisAppAddFormModal.hide();
      }
    },
    isAppEditModalVisible(newValue) {
      if (newValue) {
        this.$refs.arcgisAppEditFormModal.show();
      } else {
        this.$refs.arcgisAppEditFormModal.hide();
      }
    },
  },
  computed: {
    ...mapGetters({
      layers: "atlas/layer-manager/layers",
      simpleLayers: "atlas/layer-manager/simpleLayers",
      organisationLayers: "atlas/layer-manager/organisationLayers",
      isLayerPublishModalVisible:"atlas/layer-manager/isLayerPublishModalVisible",
      isLayerEditModalVisible: "atlas/layer-manager/isLayerEditModalVisible",
      isAppAddModalVisible: "atlas/arcgis-apps/isAppAddModalVisible",
      isAppEditModalVisible: "atlas/arcgis-apps/isAppEditModalVisible",
      isStaff: "auth/isStaff",
      isCompareLayersModalVisible:"atlas/layer-manager/isCompareLayersModalVisible",
      isIntersectionManagerVisible:"atlas/sidebar/isIntersectionManagerVisible",
      arcgisApps: 'atlas/arcgis-apps/arcgisApps',
    }),
    filteredLayers() {
      return this.filter ? this.getFilteredLayers() : this.layers;
    },
    filteredSimpleLayers() {
      return this.filter ? this.getFilteredSimpleLayers(this.simpleLayers) : this.simpleLayers;
    },
    filteredOrgLayers() {
      return this.filter ? this.getFilteredSimpleLayers(this.organisationLayers) : this.organisationLayers;
    },
  },
  methods: {
    ...mapActions({
      getLayers: "atlas/layer-manager/getLayers",
      saveSelectedMenu: "atlas/sidebar/saveSelectedMenu",
      getArcgisApps: 'atlas/arcgis-apps/getArcgisApps',
    }),
    getFilteredLayers() {
      const filtered = this.layers.filter((el) => {
        if (el.layer_type === "container") {
          return el.components.some(this.filterByName);
        }
        return this.filterByName(el);
      });
      return filtered.map((el) => {
        if (el.layer_type === "container") {
          return Object.assign({}, el, {
            components: el?.components.filter((subElement) =>
              this.filterByName(subElement)
            ),
          });
        } else {
          return el;
        }
      });
    },
    filterByName(el) {
      return el.name.toLowerCase().includes(this.filter.toLowerCase());
    },
    getFilteredSimpleLayers(layers) {
        return layers.filter((el) => {
            return this.filterByName(el);
        });
    },
  },
  async created() {
    this.saveSelectedMenu("layer-manager");
    await this.getLayers(this.isStaff);
    await this.getArcgisApps(this.isStaff);
    this.isLoaded = true;
  },
};
</script>
<style scoped lang="scss">
.layer-manager {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.5rem;
  height: calc(100% - 4rem);
  &__header {
    height: 3.6rem;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 0 2rem;
  }
  .input-group {
    border-color: #c7cfd6;
    border-width: 1px 0;
    border-style: solid;
    .input-group-text {
      background-color: white;
      border: 0;
      color: #888c9f;
      font-size: 1.5rem;
    }
    .form-control {
      border: 0;
      font-size: 1.35rem;
      padding: 1rem 0.75re 1rem 0;
      height: 55px;
      color: #888c9f;
      border-radius: 0;
    }
  }
}
</style>
