<template>
    <div>
        <st-modal
            ref="modal"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            hide-header-close
            size="sm"
            :id="modalId"
        >
            <template #header>
                <div>
                    <p class="font-size-h6 font-weight-bold mb-0 pb-0">
                        {{ $t('ATLAS.ARCGIS_APPS_MODAL.HEADER_ADD') }}
                    </p>
                </div>
            </template>
            <template #body>
                <form
                    class="form"
                    novalidate="novalidate"
                    id="st_modal_arcgis_app_add_form"
                >
                    <arcgis-app-form
                        v-if="isAppAddModalVisible"
                        :id="modalId"
                        ref="ArcgisAppForm"
                    />
                </form>
                <div class="pt-6">
                    <div class="d-flex justify-content-between">
                    <st-button
                        size="large"
                        variant="link"
                        :callback="hide"
                        :disabled="isLoading"
                    >
                        {{ $t("GENERAL.BUTTON.CANCEL") }}
                    </st-button>
                    <st-button
                        size="large"
                        variant="primary"
                        :callback="doSubmit"
                        :spinner="isLoading"
                    >
                        <span>{{ $t("GENERAL.BUTTON.SAVE") }}</span>
                    </st-button>
                    </div>
                </div>
            </template>
        </st-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { generateRandomId } from "@/core/helpers/globalMethods";
import { FormSchema } from "@/shared/form/form-schema";
import { ArcgisAppModel } from "@/modules/atlas/models/arcgis-app-model";
import ArcgisAppForm from "@/modules/atlas/components/arcgis-apps-manager/ArcgisAppForm";
import Errors from '@/shared/error/errors';

const { fields } = ArcgisAppModel;

const formSchema = new FormSchema([fields.id]);

export default {
  inheritAttrs: false,
  name: "ArcgisAppAddFormModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
      ArcgisAppForm,
  },
  data() {
    return {
      modalId: `st-modal-arcgis-app-add-${generateRandomId()}`,
      rules: formSchema.rules(),
      model: null,
      fields,
      fv: null,
      polling: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "shared/loading",
      isAppAddModalVisible: 'atlas/arcgis-apps/isAppAddModalVisible',
    }),
    isLoading() {
      return this.loading['atlas-arcgis-apps/addArcgisApp'];
    }
  },
  methods: {
    ...mapActions({
      hideAppAddModal: "atlas/arcgis-apps/hideAppAddModal",
      addArcgisApp: "atlas/arcgis-apps/addArcgisApp",
    }),
    async doSubmit() {
      const formRef = this.$refs.ArcgisAppForm;
      const validate = await formRef.fv.validate();
      if (validate === "Valid") {
        const payload = {
            app_name: formRef.model.app_name,
            app_id: formRef.model.app_id,
            app_url: formRef.model.app_url,
            access_level: formRef.model.access_level,
        }

        try {
            this.addArcgisApp(payload);
        } catch (error) {
            Errors.handle(error);
        } finally {
            this.hide();
        }
      }
    },
    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
      this.hideAppAddModal();
    },
  },
};
</script>
