import { render, staticRenderFns } from "./InterestAreasContainer.vue?vue&type=template&id=3109dc61&scoped=true&"
import script from "./InterestAreasContainer.vue?vue&type=script&lang=js&"
export * from "./InterestAreasContainer.vue?vue&type=script&lang=js&"
import style0 from "./InterestAreasContainer.vue?vue&type=style&index=0&id=3109dc61&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3109dc61",
  null
  
)

export default component.exports