<template>
   <div v-if="!isLoading">
        <div
            class="col-12 pl-2"
            v-for="(sublayer, index) in sublayers"
            :key="index"
            >
            <div class="form-group mb-0 row">
                <div class="col-8 col-form-label">
                    {{ names[sublayer.rlu_id] ? names[sublayer.rlu_id] : sublayer.rlu_id }}
                </div>
                <div class="col-4">
                    <st-button variant="link" :callback="() => details(sublayer)">
                        {{$t("ATLAS.INTERSECTION_MANAGER.DETAILS_TEXT")}}
                    </st-button>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "IntersectionUrbanRegulation",
    props: {
        layer: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            names: [],
            isLoading: true
        }
    },
    computed: {
        ...mapGetters({
            isAtlasPublicView: "atlas/sidebar/isAtlasPublicView",
        }),
        sublayers() {
            return this.layer.sublayers.filter((elem) => elem?.rlu_id)
        }
    },
    methods: {
        ...mapActions({
            getUrbanismRegulationZone: "atlas/layer-intersection/getUrbanismRegulationZone",
        }),
        details(sublayer) {
            this.$emit('showIntersectionModal', sublayer)
        },
        name(sublayer) {
            return this.names[sublayer.rlu_id] ?? sublayer.rlu_id;
        },
    },
    async beforeMount() {
        await Promise.all(this.layer.sublayers.map(async (elem) => {
            this.names[elem.rlu_id] = elem.rlu_id;

            if (elem.rlu_id) {
                const details = await this.getUrbanismRegulationZone({key: elem.rlu_id, isPublicView: this.isAtlasPublicView});
                if(details?.length && details[0].display_name) {
                    this.names[elem.rlu_id] = details[0].display_name;
                }
            }
        })).finally(() =>
            this.isLoading = false
        );
    }
}
</script>

