import { AtlasService } from '@/modules/atlas/atlas-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';
import Vue from 'vue';
import { SYSTEM_LAYERS_ORDER } from "@/modules/atlas/utils/constants";
import { AtlasPermissions } from '@/modules/atlas/atlas-permissions';
import { PLOT_ORGANISATION } from "@/modules/atlas/utils/constants";
// action types
export const DO_INIT_STATE = 'doInit';
export const GET_LAYERS = 'getLayers';
export const SHOW_LAYER = 'showLayer';
export const HIDE_LAYER = 'hideLayer';
export const SHOW_SUBLAYER = 'showSublayer';
export const HIDE_SUBLAYER = 'hideSublayer';
export const SHOW_ALL_LAYERS = 'showAllLayers';
export const HIDE_ALL_LAYERS = 'hideAllLayers';
export const CREATE_LAYER = 'createLayer';
export const UPDATE_LAYER = 'updateLayer';
export const DO_UPLOAD = 'upload';
export const GENERATE_TOKEN = 'generateToken';
export const GET_LAYER_CREATION_STATUS = 'getLayerCreationStatus';
export const ADD_LAYER = 'addLayer';
export const SELECT_LAYER = 'selectLayer';
export const HIDE_SUBLAYER_SIDEBAR = 'hideSublayerSidebar';
export const SHOW_PUBLISH_MODAL = 'showPublishModal';
export const HIDE_PUBLISH_MODAL = 'hidePublishModal';
export const SHOW_EDIT_MODAL = 'showEditModal';
export const HIDE_EDIT_MODAL = 'hideEditModal';
export const PUBLISH_LAYER = 'publish';
export const ADD_LAYER_TO_CONTAINER = 'addPublishedLayerToList';
export const DELETE_LAYER = 'deleteLayer';
export const ADD_LAYER_INTO_CONTAINER = 'addLayerToContainer';
export const UPDATE_LAYER_INTO_CONTAINER = 'updateLayerToContainer';
export const DELETE_SIMPLE_LAYER = 'deleteSimpleLayer';
export const UPDATE_LAYER_FROM_CONTAINER = 'updateLayerFromContainer';
export const UPDATE_LAYER_CONTAINER = 'updateLayerContainer';
export const COMPARE_LAYERS = 'compareLayers';
export const SHOW_COMPARE_LAYERS_MODAL = 'showCompareLayersModal';
export const HIDE_COMPARE_LAYERS_MODAL = 'hideCompareLayersModal';
export const OPEN_CONTAINER = 'openContainer';
export const GET_SYSTEM_LAYERS = 'getSystemLayers';
export const UPDATE_SUBLAYER = 'updateSublayer';
export const GET_SELECTED_PARCEL = 'getSelectedParcel';
export const GIS_VIEW_LOADED = 'gisViewLoaded';
export const RE_SYNC_LAYER = 'reSyncLayer';
export const ORGANISATION_SELECTED = 'organisationSelected';

// mutation types
export const INIT_STATE = 'init';
export const SET_SELECTED_LAYER = 'setSelectedLayer';
export const SET_VISIBLE_LAYER = 'setVisibleLayer';
export const REMOVE_VISIBLE_LAYER = 'removeVisibleLayer';
export const SET_VISIBLE_SUBLAYER = 'setVisibleSublayer';
export const REMOVE_VISIBLE_SUBLAYER = 'removeVisibleSublayer';
export const SET_UPLOAD_TOKEN = 'setUploadToken';
export const SET_LAYERS = 'setLayers';
export const SET_LAYER = 'setLayer';
export const REMOVE_SELECTED_LAYER = 'removeSelectedLayer';
export const SET_VISIBLE_LAYERS = 'setVisibleLayers'; // Select layers from container
export const REMOVE_VISIBLE_LAYERS = 'removeVisibleLayers'; // Deselect layers from container
export const SET_PUBLISH_MODAL_VISIBILITY = 'setPublishModalVisibility';
export const SET_PUBLISHING_LAYER = 'setPublishingLayer';
export const REMOVE_PUBLISHING_LAYER = 'removePublishingLayer';
export const SET_EDIT_MODAL_VISIBILITY = 'setEditModalVisibility';
export const SET_EDITING_LAYER = 'setEditingLayer';
export const SET_IS_ORG_LAYER = 'setIsOrgLayer';
export const REMOVE_EDITING_LAYER = 'removeEditingLayer';
export const SET_LAYER_TO_CONTAINER = 'setLayerToContainer';
export const REMOVE_LAYER = 'removeLayer';
export const SET_COMPARE_MODAL_VISIBILITY = 'setCompareLayerModalVisibility';
export const SET_COMPARE_LAYER = 'setCompareLayer';
export const REMOVE_COMPARE_LAYER = 'removeCompareLayer';
export const SET_COMPARED_LAYERS = 'setComparedLayer';
export const SET_LAYER_CREATION_STATUS = 'setLayerCreationStatus';
export const SET_PUBLISH_LAYER_IN_QUEUE = 'setPublishLayerQueue';
export const SET_SIMPLE_LAYERS = 'setSimpleLayers';
export const UPDATE_EDITING_LAYER = 'updateEditingLayer';
export const SET_OPEN_CONTAINER = 'setOpenContainer';
export const SET_SYSTEM_LAYERS = 'setSystemLayers';
export const SET_INITIAL_SYSTEM_LAYERS = 'setInitialSystemLayers';
export const SET_LOADED_LAYERS = 'layersLoaded';
export const SET_PARCEL = 'setParcel';
export const SET_GIS_VIEW = 'setGisView';
export const UPDATE_ORG_LAYERS = 'updateOrgLayers';
export const SET_ORG_SELECTED = 'selectedOrg';


const getDefaultState = (state) => ({
    layer: null,
    layers: [],
    selected: state?.selected || [],
    isSublayerSidebarVisible: false,
    isLayerPublishModalVisible: false,
    isLayerEditModalVisible: false,
    publishingLayer: null,
    editingLayer: null,
    isOrganisationLayer: false,
    isCompareLayersModalVisible: false,
    compareLayersData: null,
    comparedLayersResult: null,
    layerCreationStatus: null,
    publishedLayerQueue: [],
    simpleLayers: [],
    organisationLayers:[],
    openContainers: state?.openContainers || [],
    systemLayers: [],
    initialSystemLayers: [],
    layersLoaded: false,
    parcel: {},
    gisView: {},
    selectedOrg: null,
});

const state = getDefaultState();

const getters = {
    layer: (state) => state.layer,
    layers: (state) => {
        state.layers.forEach((layer) => {
            if (layer.components && layer.components.length) {
                const components = layer.components;
                const templateLayerId = layer.template_layer_id;
                if (templateLayerId) {
                    const templatelayer = components.find((el) => el.id === templateLayerId);
                    if (templatelayer) templatelayer.is_template_layer = true;
                }
            }
        })
        return  state.layers
    },
    simpleLayers: (state) => state.simpleLayers,
    organisationLayers: (state) => state.organisationLayers,
    getLayerVisibilityById: (state) => (layerId) => {
        return state.selected?.[layerId]?.visible ?? false;
    },
    getSublayerVisibilityById: (state) => (layerId, sublayerId) => {
        if (!state.selected?.[layerId]?.visible) return false;
        return state.selected?.[layerId]?.sublayers?.[sublayerId]?.visible ?? true;
    },
    visibleLayers: (state) => {
        const layers = [];
        state.layers.forEach((item) => {
            if (item.layer_type === 'container') {
                layers.push(...item.components);
            } else {
                layers.push(item);
            }
        });
        return layers;
    },
    atlasLayers: (state) => state.layers.filter((el) => el.access_level === 'public'),
    privateLayers: (state) => state.layers.filter((el) => el.access_level === 'private'),
    sharedLayers: (state) => state.layers.filter((el) => el.access_level === 'protected'),
    selectedLayers: (state) => state.selected,
    isSublayerSidebarVisible: (state) => !!state.layer,
    isLayerPublishModalVisible: (state) => state.isLayerPublishModalVisible,
    isLayerEditModalVisible: (state) => state.isLayerEditModalVisible,
    containerLayers: (state) => state.layers.filter((el) => el.layer_type === 'container'),
    publishingLayer: (state) => state.publishingLayer,
    editingLayer: (state) => state.editingLayer,
    isCompareLayersModalVisible: (state) => state.isCompareLayersModalVisible,
    compareLayersData: (state) => state.compareLayersData,
    comparedLayersResult: (state) => state.comparedLayersResult,
    layerCreationStatus: (state) => state.layerCreationStatus,
    publishedLayerQueue: (state) => state.publishedLayerQueue,
    openContainers: (state) => state.openContainers,
    systemLayers: (state) => state.systemLayers,
    initialSystemLayers: (state) => state.initialSystemLayers,
    layersLoaded: (state) => state.layersLoaded,
    selectedParcel: (state) => state.parcel,
    activeGisView: (state) => state.gisView,
    atlasPermissions : (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToPublish: new AtlasPermissions(currentUser).publish,
            hasPermissionToManage: new AtlasPermissions(currentUser).manage,
            hasPermissionToView: new AtlasPermissions(currentUser).view,
        }
    },
    selectedOrg: (state) => state.selectedOrg,
};

const actions = {
    async [DO_INIT_STATE](context) {
        const persistedLayers = await AtlasService.getPersistedLayers();
        const persistedContainers = await AtlasService.getPersistedContainers();
        context.commit(INIT_STATE, { selected: persistedLayers, openContainers: persistedContainers });
    },
    async [SHOW_LAYER](context, layer) {
        context.commit(SET_VISIBLE_LAYER, layer);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [HIDE_LAYER](context, layer) {
        context.commit(REMOVE_VISIBLE_LAYER, layer);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [SHOW_SUBLAYER](context, payload) {
        context.commit(SET_VISIBLE_SUBLAYER, payload);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [HIDE_SUBLAYER](context, payload) {
        context.commit(REMOVE_VISIBLE_SUBLAYER, payload);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [SHOW_ALL_LAYERS](context, layers) {
        await context.commit(SET_VISIBLE_LAYERS, layers);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [HIDE_ALL_LAYERS](context, layers) {
        await context.commit(REMOVE_VISIBLE_LAYERS, layers);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
    async [OPEN_CONTAINER](context, layers) {
        context.commit(SET_OPEN_CONTAINER, layers);
        const openedContainers = await context.getters.openContainers;
        AtlasService.persistContainers(openedContainers);
    },
    async [CREATE_LAYER](context, {payload, addLayerById}) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/create', { root: true });
        const method = addLayerById ? 'createLayerById' : 'createLayer';
        try {
            const response = await AtlasService[method](payload);
            const data = response.data;
            if (addLayerById) {
                return { id: data.id }
            } else {
                const uploadAccessToken = response.headers['x-file-upload-access-token'];
                return { data, uploadAccessToken };
            }
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/create', { root: true });
        }
    },
    async [GET_LAYER_CREATION_STATUS](context, id) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/getCreateLayer', { root: true });
        try {
            const response = await AtlasService.getLayerCreationStatus(id);
            context.commit(SET_LAYER_CREATION_STATUS, response);
            return response

        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/getCreateLayer', { root: true });
        }
    },
    async [UPDATE_LAYER](context, { id, values }) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/update', { root: true });
        try {
            return await AtlasService.updateLayer(id, values);
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/update', { root: true });
        }
    },
    async [DO_UPLOAD](context, payload) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/upload', { root: true });
        try {
            const { uploadAccessToken, files } = payload;
            return await AtlasService.upload(uploadAccessToken, files);
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/upload', { root: true });
        }
    },
    [GET_LAYERS](context, isStaff) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/get', { root: true });
            context.commit(SET_LOADED_LAYERS, false);
            const params =  {
                limit: 500,
                sort: 'created_date'
            };
            const serviceMethod = isStaff ? 'getLayers' : 'getPublicLayers';
            AtlasService[serviceMethod]({params})
                .then(async (data) => {
                    context.commit(SET_LAYERS, data);
                    context.commit(SET_SIMPLE_LAYERS, data);
                    context.commit(SET_SYSTEM_LAYERS, data);

                    // Open all folders at first init
                    const isOpenConaintersKeyDefined = await AtlasService.persistedContainersKeyDefined();
                    if (!isOpenConaintersKeyDefined) {
                        const containers =  await context.getters.containerLayers;
                        const containersIds = containers.map(container => container.id);
                        context.commit(SET_OPEN_CONTAINER, containersIds);
                        AtlasService.persistContainers(containersIds);
                    }
                    context.commit(SET_LOADED_LAYERS, true);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/get', { root: true });
                });
        });
    },
    [GET_SYSTEM_LAYERS](context, isStaff) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/getInitialSystemLayers', { root: true });
            const params =  {
                limit: 100,
                sort: 'created_date',
                query: 'is_system_layer eq true',
            };
            const method = isStaff ? 'getSystemLayers' : 'getPublicSystemLayers';
            AtlasService[method]({params})
                .then(async (data) => {
                    context.commit(SET_INITIAL_SYSTEM_LAYERS, data);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/getInitialSystemLayers', { root: true });
                });
        });
    },
    [ADD_LAYER](context, layer) {
        context.commit(SET_LAYER, layer);
    },
    [ADD_LAYER_TO_CONTAINER](context, layer) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/addLayerToContainer', { root: true });
            AtlasService.getSimpleLayer(layer.layer_id)
                .then((data) => {
                    context.commit(SET_LAYER_TO_CONTAINER, {data, target_component_id: layer.target_component_id});
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/addLayerToContainer', { root: true });
                });
        });

    },
    [GENERATE_TOKEN](context) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/generateToken', { root: true });
            AtlasService.generateToken()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/generateToken', { root: true });
                });
        });
    },
    [SELECT_LAYER](context, layer) {
        context.commit(SET_SELECTED_LAYER, layer);
    },
    [HIDE_SUBLAYER_SIDEBAR](context) {
        context.commit(REMOVE_SELECTED_LAYER);
    },
    [SHOW_PUBLISH_MODAL](context, layer) {
        context.commit(SET_PUBLISHING_LAYER, layer);
        context.commit(SET_PUBLISH_MODAL_VISIBILITY, true);
    },
    [HIDE_PUBLISH_MODAL](context) {
        context.commit(REMOVE_PUBLISHING_LAYER);
        context.commit(SET_PUBLISH_MODAL_VISIBILITY, false);
    },
    [SHOW_COMPARE_LAYERS_MODAL](context, layer) {
        context.commit(SET_COMPARE_LAYER, layer);
        context.commit(SET_COMPARE_MODAL_VISIBILITY, true);
    },
    [HIDE_COMPARE_LAYERS_MODAL](context) {
        context.commit(REMOVE_COMPARE_LAYER);
        context.commit(SET_COMPARE_MODAL_VISIBILITY, false);
    },
    async [PUBLISH_LAYER](context, payload) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/publish', { root: true });
        const simpleLayers = context.getters.simpleLayers;
        try {
            const { data } = await AtlasService.publishLayer(payload);
            context.commit(SET_PUBLISH_LAYER_IN_QUEUE, {data, simpleLayers});
            return data;
        } catch(error) {
            return error;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/publish', { root: true });
        }
    },
    async [COMPARE_LAYERS](context, payload) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/compareLayers', { root: true });
        try {
            const data = await AtlasService.compareLayers(payload);
            context.commit(SET_COMPARED_LAYERS, data.data);
            return data.data;
        }
        finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/compareLayers', { root: true });
        }
    },
    [SHOW_EDIT_MODAL](context, {layer, isOrgLayer}) {
        context.commit(SET_EDITING_LAYER, layer);
        context.commit(SET_IS_ORG_LAYER, isOrgLayer);
        context.commit(SET_EDIT_MODAL_VISIBILITY, true);
    },
    [HIDE_EDIT_MODAL](context) {
        context.commit(SET_EDIT_MODAL_VISIBILITY, false);
        context.commit(REMOVE_EDITING_LAYER);
    },
    [DELETE_LAYER](context, {layer, isOrgLayer}) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/deleteLayer', { root: true });
            const method = layer.layer_type === 'simple' && layer.container_id ? 'deleteSimpleLayer' : 'deleteLayer';
            AtlasService[method](layer.id)
                .then(() => {
                    context.commit(REMOVE_LAYER, {layer, isOrgLayer});
                    const selectedLayers = context.getters.selectedLayers;
                    AtlasService.persistLayers(selectedLayers);
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/deleteLayer', { root: true });
                });
        });
    },
    [DELETE_SIMPLE_LAYER](context, layer) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/deleteSimpleLayer', { root: true });
            AtlasService.deleteSimpleLayer(layer.id)
                .then(() => {
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/deleteSimpleLayer', { root: true });
                });
        });
    },
    async [ADD_LAYER_INTO_CONTAINER](context, values) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/addLayerIntoContainer', { root: true });
        try {
            const response = await AtlasService.addLayerIntoContainer(values);
            const uploadAccessToken = response.headers['x-file-upload-access-token'];
            const data = response.data;
            return { data, uploadAccessToken };
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/addLayerIntoContainer', { root: true });
        }
    },
    async [UPDATE_LAYER_INTO_CONTAINER](context, { id, payload }) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/updateLayerIntoContainer', { root: true });
        try {
            return await AtlasService.updateLayerIntoContainer(id, payload);
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/updateLayerIntoContainer', { root: true });
        }
    },
    async [UPDATE_LAYER_FROM_CONTAINER](context, { id, layer }) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/updateLayerFromContainer', { root: true });
        try {
            return await AtlasService.updateLayerFromContainer(id, layer);
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/updateLayerFromContainer', { root: true });
        }
    },
    async [UPDATE_LAYER_CONTAINER](context, { layer, payload }) {
        context.commit('shared/activateLoading', 'atlas-layer-manager/updateLayerContainer', { root: true });
        try {
            const response = await AtlasService.updateLayerContainer(layer.id, payload);
            const {components, ...values} = response;
            context.commit(UPDATE_EDITING_LAYER, {...layer , ...values});

            if (layer.layer_type === 'simple') {
                context.commit(SET_EDIT_MODAL_VISIBILITY, false);
            }

            return response;
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'atlas-layer-manager/updateLayerContainer', { root: true });
        }
    },
    async [UPDATE_SUBLAYER](context, {layer, sublayer}) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/updateSublayer', { root: true });
            const endpointByLayerType = layer.layer_type === 'simple' && layer.container_id ? 'layers-simple' : 'layers';
            AtlasService.updateSublayer({
                ...sublayer,
                layerId: layer.id,
                endpointByLayerType,
            })
            .then(() => {
                Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'atlas-layer-manager/updateSublayer', { root: true });
            });
        });
    },
    [GET_SELECTED_PARCEL](context) {
        const persistedParcel = AtlasService.getPersistedParcel();
        context.commit(SET_PARCEL, persistedParcel);
    },
    [GIS_VIEW_LOADED](context, data) {
        context.commit(SET_GIS_VIEW, data);
    },
    [RE_SYNC_LAYER](context, layer) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/reSyncLayers', { root: true });
            const method = layer.isSimpleLayer ? 'reSyncSimpleLayers' : 'reSyncLayers';
            AtlasService[method](layer.id)
            .then((response) => {
                Message.success(i18n.t('ATLAS.LAYER_MANAGER.LAYER_SYNC_SUCCESS'));
                resolve();
            })
            .catch(( error ) => {
                Message.error('ATLAS.LAYER_MANAGER.LAYER_SYNC_ERROR');
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'atlas-layer-manager/reSyncLayers', { root: true });
            });
        })
    },
    [ORGANISATION_SELECTED](context, {orgId, layers}) {
        context.commit(SET_ORG_SELECTED, orgId);
        context.commit(UPDATE_ORG_LAYERS, layers);
    },
};

const mutations = {
    [INIT_STATE](state, payload) {
        Object.assign(state, getDefaultState(payload));
    },
    [SET_SELECTED_LAYER](state, layer) {
        state.layer = layer;
        if (!state.selected[layer.gis_layer_id]) {
            const sublayers = {};
            layer.sublayers.forEach((sublayer) => {
                sublayers[sublayer.gis_id] = {
                    visible: false,
                };
            });
            Vue.set(state.selected, layer.gis_layer_id, {visible: false, sublayers});
        }
    },
    [REMOVE_SELECTED_LAYER](state) {
        state.layer = null;
    },
    [SET_VISIBLE_LAYER](state, layer) {
        const layerId = layer.gis_layer_id;
        let layerOrderIndex;
        SYSTEM_LAYERS_ORDER.forEach((item) => {
            if (item.feature_service_url.search(layer.feature_service_url) >= 0) {
                layerOrderIndex = item.index;
            }
        });
        if (state.selected[layerId]) {
            state.selected[layerId].visible = true;
            state.selected[layerId].layerOrderIndex = layerOrderIndex;
            state.selected[layerId].gis_layer_id = layerId;
            const sublayers = state.selected[layerId].sublayers;
            // check if any sublayer is selected
            let showAll = true
            Object.keys(sublayers).forEach((key) => {
                // layer visible exist
                if (sublayers[key].visible) {
                    state.selected[layerId].sublayers[key].visible = sublayers[key].visible;
                    showAll = false
                }
            })
            if (showAll) {
                Object.keys(sublayers).forEach((key) => {
                    state.selected[layerId].sublayers[key].visible = true;
                })
            }
        } else {
            const sublayers = {};
            layer.sublayers.forEach((sublayer, index) => {
                sublayers[sublayer.gis_id] = {
                    visible: true,
                    gisId: sublayer.gis_id,
                    indexOrder: index
                };
            });

            state.selected[layerId] = {
                visible: true,
                layerOrderIndex,
                parentIndex: layer.parentIndex,
                gis_layer_id: layerId,
                sublayers,
            };
        }
    },
    [REMOVE_VISIBLE_LAYER](state, layer) {
        const layerId = layer.gis_layer_id;
        if (state.selected[layerId]) {
            Vue.set(state.selected[layerId], 'visible', false);
            const sublayers = state.selected[layerId].sublayers;
            // check if any sublayer is selected and maintain state
            let hideAll = true;
            Object.keys(sublayers).forEach((key) => {
                const visibility = sublayers[key].visible;
                if (!sublayers[key].visible) {
                    hideAll = false;
                    state.selected[layerId].sublayers[key].visible = visibility;
                }
            })
            if(hideAll) {
                Object.keys(sublayers).forEach((key) => {
                    state.selected[layerId].sublayers[key].visible = false;
                })
            }
        } else {
            state.selected[layerId] = {
                visible: false,
            };
        }
    },
    [SET_VISIBLE_SUBLAYER](state, {layerId, sublayerId}) {
        Vue.set(state.selected[layerId].sublayers[sublayerId], 'visible', true);
        const sublayers = state.selected[layerId].sublayers
        // Check layer switch in case at least one sublayer is active
        Object.values(sublayers).forEach((sublayer) => {
            if(sublayer.visible) {
                Vue.set(state.selected[layerId], 'visible', true);
            }
        })
    },
    [REMOVE_VISIBLE_SUBLAYER](state, {layerId, sublayerId}) {
        Vue.set(state.selected[layerId].sublayers[sublayerId], 'visible', false);
         // Uncheck layer switch in case all sublayers are inactive
        let layerVisible = false;
        const sublayers = state.selected[layerId].sublayers;
        Object.values(sublayers).forEach((sublayer) => {
            if(sublayer.visible) {
                layerVisible = true
            }
        })
        Vue.set(state.selected[layerId], 'visible', layerVisible);
    },
    [SET_VISIBLE_LAYERS](state, layers) {
        layers.forEach((layer) => {

            let layerOrderIndex;
            SYSTEM_LAYERS_ORDER.forEach((item) => {
                if (item.feature_service_url.search(layer.feature_service_url) >= 0) {
                    layerOrderIndex = item.index;
                }
            });

            const layerId = layer.gis_layer_id;
            const sublayers = {};
            layer.sublayers.forEach((sublayer, index) => {
                sublayers[sublayer.gis_id] = {
                    visible: true,
                    gisId: sublayer.gis_id,
                    indexOrder: index
                };
            });
            Vue.set(
                state.selected,
                layerId,
                {
                    visible: true,
                    gis_layer_id: layerId,
                    sublayers,
                    layerOrderIndex,
                    parentIndex: layer.parentIndex,
                }
            );
        });
    },
    [REMOVE_VISIBLE_LAYERS](state, layers) {
        layers.forEach((layer) => {
            const layerId = layer.gis_layer_id;
            if (state.selected[layerId]) {
                Vue.set(state.selected[layerId], 'visible', false);
            } else {
                state.selected[layerId] = {
                    visible: false,
                };
            }
        });
    },
    [SET_OPEN_CONTAINER](state, layers) {
        state.openContainers = layers;
    },
    [SET_LAYERS](state, layers) {
        state.layers = layers;
        const selectedLayers = state.selected;
        let j = 0;
        const containers = layers.filter((el) => el.layer_type === "container")
        containers.forEach((container , index)=> {
            const components = container.components
            components.forEach((component , index)=> {
                if (selectedLayers[component.gis_layer_id]) {
                    selectedLayers[component.gis_layer_id].parentIndex = j;
                }
                component.parentIndex = j;
                j++;
            })
        });
    },
    [SET_SYSTEM_LAYERS](state, layers) {
        const systemLayer = layers.find((layer) => layer.layer_type === 'container' && layer.is_system_layer);
        state.systemLayers = systemLayer && systemLayer.components.length ? systemLayer.components : [];
    },
    [SET_INITIAL_SYSTEM_LAYERS](state, layers) {
        const systemLayers = layers.find((layer) => layer.is_system_layer).components;
        const mappedSystemLayers = systemLayers.map((layer) => {
            let has_index = false;
            let index_order = null;
            SYSTEM_LAYERS_ORDER.forEach( (item) => {
                if (item.feature_service_url.search(layer.feature_service_url) >= 0) {
                    index_order = item.index
                    has_index = true;
                }
            });
            return {
                ...layer,
                has_index,
                index_order,
            }
        });
        state.initialSystemLayers = mappedSystemLayers;
    },
    [SET_SIMPLE_LAYERS](state, layers) {
        const STATUS = {
            new: 'info',
            pending: 'warning',
            completed: 'success',
            error: 'danger',
            published: 'success',
            not_published:'dark'
        }

        const mappedLayers = state.layers.filter((el) => el.layer_type === 'simple').map(layer => {
                let publish_status = layer?.is_published ? 'published' : 'not_published';
                if (layer?.published_simple_layer_status) {
                    publish_status = layer?.published_simple_layer_status
                }
                return {
                    ...layer,
                    publish_status,
                    type: STATUS[publish_status]
                }
            })
        state.simpleLayers = mappedLayers;
        state.organisationLayers = mappedLayers.filter((el) =>  el.private_layer_ownership_type == PLOT_ORGANISATION) || [];
        if (state.selectedOrg) {
            state.organisationLayers = state.organisationLayers.filter((el) =>  el.owner_org_id == state.selectedOrg) || [];
        }
    },
    [SET_LAYER](state, layer) {
        const layerId = layer.layer_id;
        // Switch the layer creation request id with the layer_id
        const newLayer = {
            ...layer,
            id: layerId,
            publish_status: 'not_published'
        };
        state.layers.push(newLayer);
        state.simpleLayers.push(newLayer);
    },
    [SET_PUBLISH_MODAL_VISIBILITY](state, isVisible) {
        state.isLayerPublishModalVisible = isVisible;
    },
    [SET_PUBLISHING_LAYER](state, layer) {
        state.publishingLayer = layer;
    },
    [REMOVE_PUBLISHING_LAYER](state) {
        state.publishingLayer = null;
    },
    [SET_EDIT_MODAL_VISIBILITY](state, isVisible) {
        state.isLayerEditModalVisible = isVisible;
    },
    [SET_COMPARE_MODAL_VISIBILITY](state, isVisible) {
        state.isCompareLayersModalVisible = isVisible;
    },
    [SET_COMPARE_LAYER](state, layer) {
        state.compareLayersData = layer;
    },
    [REMOVE_COMPARE_LAYER](state) {
        state.compareLayersData = null;
    },
    [SET_EDITING_LAYER](state, layer) {
        state.editingLayer = layer;
    },
    [SET_IS_ORG_LAYER](state, layer) {
        state.isOrganisationLayer = layer;
    },
    [UPDATE_EDITING_LAYER](state, layer) {
        if (layer.components && layer.components.length) {
            const components = layer.components;
            const templateLayerId = layer.template_layer_id;
            components.forEach((layer) => {
                layer.is_template_layer = layer.id === templateLayerId;
            })
        } else {
            // update simple (private) layer (on PATCH)
            const index = state.simpleLayers.findIndex((item) => item.id === layer.id);
            Vue.set(state.simpleLayers, index, layer);

            // update (simple) organisation layer
            if (state.isOrganisationLayer) {
                const index = state.organisationLayers.findIndex((item) => item.id === layer.id);
                Vue.set(state.organisationLayers, index, layer);
            }
        }
        state.editingLayer = layer;
    },
    [REMOVE_EDITING_LAYER](state) {
        state.editingLayer = null;
    },
    [SET_COMPARED_LAYERS](state, data) {
        state.comparedLayersResult = data;
    },
    [SET_LAYER_CREATION_STATUS](state, status) {
        state.layerCreationStatus = status;
    },
    [SET_PUBLISH_LAYER_IN_QUEUE](state, {data, simpleLayers}) {
        const index = simpleLayers.findIndex((item) => item.id === data.published_from_layer_id);
        state.publishedLayerQueue.push({listIndex: index, layer: data})
    },
    [SET_LAYER_TO_CONTAINER](state, {data, target_component_id}) {
        const { container_id } = data;
        let layerIndex = null;
        const container =  state.layers.find((el, index) => {
            if (el.id === container_id) layerIndex = index
            return  el.id === container_id
        });
        const components = container.components
        const newComponets = components.map(item => item.id == target_component_id ? data : item);
        container.components = newComponets;

        Vue.set(state.layers, layerIndex, container);
    },
    [REMOVE_LAYER](state, {layer, isOrgLayer}) {
        // Remove layer from the list
        const index = state.layers.findIndex((item) => item.id === layer.id);
        state.layers.splice(index, 1);

        // check on simple layers, removed if found
        const indexSL = state.simpleLayers.findIndex((item) => item.id === layer.id);
        if (indexSL) state.simpleLayers.splice(indexSL, 1);

        if(isOrgLayer) {
            // Remove layer from the org layer list
            const indexOL = state.organisationLayers.findIndex((item) => item.id === layer.id);
            if (indexOL) state.organisationLayers.splice(indexOL, 1);
        }

        // check if deleted layer is from container
        if (layer.container_id) {
            // remove layer from container
            const container = state.layers.find(item =>  item.id === layer.container_id);
            const layerIndex = state.layers.findIndex(item =>  item.id === layer.container_id);
            if (container) {
                const components = container.components;
                const componentIndex = components.findIndex(item =>  item.id === layer.id);
                components.splice(componentIndex, 1);
                Vue.set(state.layers, layerIndex, {...container, components});
            }
        }
        // Remove selected layer
        if (state.selected?.[layer.gis_layer_id]) {
            delete state.selected[layer.gis_layer_id];
        }
        // Remove selected layer and hide sublayer sidebar
        if (state.layer?.id === layer.id) {
            state.layer = null;
            state.isSublayerSidebarVisible = false;
        }
    },
    [SET_LOADED_LAYERS](state, isLoaded) {
        state.layersLoaded = isLoaded;
    },
    [SET_PARCEL](state, parcel) {
        state.parcel = parcel;
    },
    [SET_GIS_VIEW](state, data) {
        state.gisView = data;
    },
    [UPDATE_ORG_LAYERS](state, layers) {
        state.organisationLayers = layers;
    },
    [SET_ORG_SELECTED](state, id) {
        state.selectedOrg = id;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
