<template>
    <div>
        <st-modal
            ref="modal"
            :id="modalId"
            hide-footer-delimiter
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            size="lg"
            hideable
            :hideHeaderClose="false"
            :title="$t('ATLAS.VISIBILITY_MANAGER.TITLE')"
        >
            <template #body>
                <layer-container :layers="layers" :simpleLayers="simpleLayers"/>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import LayerContainer from "@/modules/atlas/components/layer-manager/LayerContainer";
import { generateRandomId } from '@/core/helpers/globalMethods';
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'LayersVisibilitySettingsModal',
    components: {
        LayerContainer
    },
    data() {
        return {
            modalId: `st-layers-visibility-settings-modal-${generateRandomId()}`,
        }
    },
    computed: {
        ...mapGetters({
            layers: "atlas/layer-manager/layers",
            simpleLayers: "atlas/layer-manager/simpleLayers",

        }),
    },
    mounted() {
        this.$root.$on('bv::modal::hide', (modalObj) => {
            if (modalObj.componentId === this.modalId) {
                this.showVisibilitySettings(false);
            }
        });
    },
    methods: {
        ...mapActions({
            showVisibilitySettings: 'atlas/sidebar/showVisibilitySettings',
        }),
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
            this.showVisibilitySettings(false);
        },
    }
};
</script>

