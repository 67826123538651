import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';
import BooleanField from '@/shared/fields/boolean-field';
import { OrganizationField } from '@/modules/administrator/fields/organisations-field';
import { ContainerLayerField } from '@/modules/atlas/fields/container-layer-field';
import { SimpleLayerField } from '@/modules/atlas/fields/simple-layer-field';
import { TerritorialAdministrativeUnitWithCountyField } from '@/shared/common-fields/territorial-administrative-unit-with-county-field';
import { DecisionField } from '@/modules/administrator/fields/decision-field';
import { ApplicationTypeField } from '@/modules/applications/fields/application-type-field';
import { PLOT_ORGANISATION, PLOT_PRIVATE } from "@/modules/atlas/utils/constants";

const label = (name) => i18n.t(`ATLAS.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`ATLAS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), {
        required: true,
    }),
    layer_type: new EnumField('layer_type', label('layer_type'), [
        { value: 'simple', text: enumLabel('layer_type', 'simple') },
        { value: 'container', text: enumLabel('layer_type', 'container') },
    ], {
        autocompleteRequired: true
    }),
    access_level: new EnumField('access_level', label('access_level'), [
        { value: 'private', text: enumLabel('access_level', 'private') },
        { value: 'public', text: enumLabel('access_level', 'public') },
        { value: 'protected', text: enumLabel('access_level', 'protected') }
    ], {
        autocompleteRequired: true
    }),
    ownership_type: new EnumField('ownership_type', label('ownership_type'), [
        { value: 'centralized', text: enumLabel('ownership_type', 'centralized') },
        { value: 'distributed', text: enumLabel('ownership_type', 'distributed') },
    ], {
        autocompleteRequired: true
    }),
    owner_org_id: OrganizationField.relationToOne(
        'owner_org_id',
        label('organization'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    layer_name: new StringField('layer_name', label('name'), {
        required: true,
    }),
    container_id: ContainerLayerField.relationToOne(
        'container_id',
        label('container_layer'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    layer_id: SimpleLayerField.relationToOne(
        'layer_id',
        label('layer'),
        { autocompleteRequired: true },
        {},
        [
            // {
            //     key: 'parent_id',
            //     name: 'container_id',
            // }
        ],
        { asyncData: true }
    ),
    template_layer_id: SimpleLayerField.relationToOne(
        'template_layer_id',
        label('template_layer_id'),
        { autocompleteRequired: true },
        {},
        [
            {
                key: 'parent_id',
                name: 'container_id',
            }
        ],
        { asyncData: true }
    ),
    ru_scope: new EnumField('ru_scope', label('ru_scope'), [
        { value: 'pug', text: enumLabel('ru_scope', 'PUG') },
        { value: 'puz', text: enumLabel('ru_scope', 'PUZ') },
        { value: 'pud', text: enumLabel('ru_scope', 'PUD') },
        { value: 'other', text: enumLabel('ru_scope', 'OTHER')}
    ], {
        autocompleteRequired: true
    }),
    feature_service_url: new StringField('feature_service_url', label('feature_service_url'), {
        required: false,
        arcgisFeatureServiceUrl: true,
    }),
    gis_layer_id: new StringField('gis_layer_id', label('gis_layer_id'), {
        required: false,
    }),
    uat_id: TerritorialAdministrativeUnitWithCountyField.relationToOne(
        'uat_id',
        label('uat'),
        { autocompleteRequired: true, firstLetterFilter: true },
        {},
        [],
        { asyncData: true }
    ),
    use_as_template: new BooleanField(
        'use_as_template',
        label('use_as_template'),
    ),
    decision_id: DecisionField.relationToOne(
        'decision_id',
        label('decision'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    has_decision: new BooleanField(
        'has_decision',
        label('has_decision'),
    ),
    arcgis_imported_item: new StringField('arcgis_imported_item', label('arcgis_imported_item'), {
        required: true,
    }),
    arcgis_imported_item_type: new EnumField('arcgis_imported_item_type', label('arcgis_imported_item_type'), [
        { value: 'feature_server', text: enumLabel('arcgis_imported_item_type', 'feature_server') },
        { value: 'shapefile', text: enumLabel('arcgis_imported_item_type', 'shapefile') },
    ], {
        autocompleteRequired: true
    }),
    arcgis_imported_item_is_linked: new BooleanField(
        'arcgis_imported_item_is_linked',
        label('arcgis_imported_item_is_linked'),
    ),
    private_layer_ownership_type : new EnumField('private_layer_ownership_type', label('private_layer_ownership_type'), [
        { value: PLOT_PRIVATE, text: enumLabel('private_layer_ownership_type', 'plot_private') },
        { value: PLOT_ORGANISATION, text: enumLabel('private_layer_ownership_type', 'plot_organisation') },
    ], {
        autocompleteRequired: true
    }),
    can_access_citizen: new BooleanField(
        'can_access_citizen',
        label('can_access_citizen'),
    ),
    can_access_staff: new BooleanField(
        'can_access_staff',
        label('can_access_staff'),
    ),
    application_type: ApplicationTypeField.relationToOne(
        'application_type',
        label('application_type'),
        { autocompleteRequired: true },
    ),
};

export class AtlasLayerModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
