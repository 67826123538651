<template>
    <div class="map-view-container">
        <div id="viewDiv" class="mb-8"></div>
        <div id="measurementWidget" class="esri-widget" v-if="showMeasurement">
            <button
                class="action-button esri-icon-measure-line"
                :class="{ active: activeWidgetType === 'distance' }"
                id="distanceButton"
                type="button"
                title="Measure distance between two or more points"
                @click="setActiveWidget('distance')"
                v-if="showLineMeasurement"
            ></button>
            <button
                class="action-button esri-icon-measure-area"
                :class="{ active: activeWidgetType === 'area' }"
                id="areaButton"
                type="button"
                title="Measure area"
                @click="setActiveWidget('area')"
                v-if="showAreaMeasurement"
            ></button>
            <button
                id="clearMeasurements"
                type="button"
                class="action-button esri-icon-trash"
                title="Clear Measurements"
                @click="clearMeasurements"
                v-if="showAreaMeasurement || showLineMeasurement">
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Map from "@arcgis/core/Map";
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import Point from "@arcgis/core/geometry/Point";
import Zoom from "@arcgis/core/widgets/Zoom";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Expand from "@arcgis/core/widgets/Expand";
import DistanceMeasurement2D from "@arcgis/core/widgets/DistanceMeasurement2D";
import AreaMeasurement2D from "@arcgis/core/widgets/AreaMeasurement2D";
import Fullscreen from "@arcgis/core/widgets/Fullscreen";
import LayerList from "@arcgis/core/widgets/LayerList";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import * as projection from "@arcgis/core/geometry/projection";
import * as promiseUtils from "@arcgis/core/core/promiseUtils";
import esriConfig from "@arcgis/core/config.js";
import esriConfigJson from "@/config/esri.config.json";
import Print from "@arcgis/core/widgets/Print";
import TemplateOptions from "@arcgis/core/widgets/Print/TemplateOptions";
// import Sketch from "@arcgis/core/widgets/Sketch.js";
import { generateRandomId } from '@/core/helpers/globalMethods';
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import * as rendererJsonUtils from "@arcgis/core/renderers/support/jsonUtils";
import * as locator from "@arcgis/core/rest/locator";
import { getPolygonCentroid } from '@/modules/atlas/utils/graphic-utils'
import {
    popupTemplate,
    addressPopupTemplate,
    simpleFillSymbol,
    MEASUREMENT_TYPE_PIN,
    MEASUREMENT_TYPE_AREA,
    STEREO70_WKID,
    serviceUrl,
    marker,
} from "@/modules/atlas/utils/constants";


let GIS = {};

export default {
  name: "StMap",
  props: {
    showLayers: {
      type: Boolean,
      default: false,
    },
    showPrintBtn: {
      type: Boolean,
      default: false,
    },
    showMeasurement: {
      type: Boolean,
      default: true,
    },
    showLineMeasurement: {
      type: Boolean,
      default: true,
    },
    showAreaMeasurement: {
      type: Boolean,
      default: true,
    },
    showFullscreen: {
      type: Boolean,
      default: true,
    },
    captureClickLocation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleLayers: {},
      visibleLayerGroups: [],
      activeWidget: null,
      activeWidgetType: "",
      mapObj: {},
      layerList: {},
      currentExpandedWidget: {id: `id-${generateRandomId()}`},
      layerListWidget: null,
      expandLayerList: null,
      zoomFunc: null,
      expandMeasurementRef: null,
    };
  },
  computed: {
    ...mapGetters({
      uatIdsFromRealEstates: "applications/form/getUatIdsFromRealEstates",
      layers: "applications/atlas/layers",
      systemLayers: "applications/atlas/systemLayers",
      selectedLayers: "applications/atlas/selectedLayers",
      isStaff: 'auth/isStaff',
      record: 'applications/form/record',
      defaultCountyUatId: "shared/defaultCountyUatId",
    }),
  },
  async created() {
    await this.initLayersState();
  },
  async mounted() {
    await this.loadMap([]);

    this.visibleLayerGroups = await this.getVisiblePrintWidgetLayers();
    GIS.view?.when(() => {
        this.visibleLayerGroups.forEach((gisId) => {
            if(this.layerList[gisId]) {
                this.layerList[gisId].visible = true;
                this.setLayerVisibility(this.layerList[gisId]);
            }
        });
        this.setVisiblePrintWidgetLayers([]);
    });
  },

  methods: {
    ...mapActions({
        getLayersByRealEstateUatIds:"applications/form/getLayersByRealEstateUatIds",
        getGisToken: "applications/form/getGisToken",
        getLayers: "applications/atlas/getLayers",
        initLayersState: 'applications/atlas/doInit',
        setLayerVisibility: 'applications/atlas/setLayerVisibility',
        getVisiblePrintWidgetLayers: 'applications/atlas/getVisiblePrintWidgetLayers',
        setVisiblePrintWidgetLayers: 'applications/atlas/setVisiblePrintWidgetLayers',
        setMeasurement: 'applications/atlas/doSetMeasurement',
    }),
    async clearMap () {
        GIS.map?.layers?.removeAll();
    },
    async loadMap(parcels, selectedGisFileLayers, pinpoints) {
      esriConfig.apiKey = esriConfigJson.apiKey;
      const layers = await this.getGisLayers();

      const map = new Map({
        basemap: "topo-vector",
        layers,
      });
      // assign map to this view
      const view = new MapView({
        container: this.$el,
        map: map,
        center: [23.45, 46.845],
        zoom: 10.5,
        navigation: {
          mouseWheelZoomEnabled: true,
        },
        ui: {
          components: ["attribution"], // removes default widgets except for attribution
        },
      });
      GIS.view = view;
      GIS.map = map;
      GIS.center = {}

      this.mapObj.map = map;
      this.mapObj.view = view;

      this.setupMapZoom(view);
      this.setupBasemapGallery(view);

      if(this.showMeasurement) {
        this.addMeasurement(view);
      }

      if(this.showPrintBtn) {
        this.addPrintBtn(view);
      }

      if(this.showFullscreen) {
        this.addFullscreen(view);
      }

      if(this.captureClickLocation) {
        this.setupClickEventHandler();
        // this.setupSketchWidget();
      }

      GIS.center.x = 0;
      GIS.center.y = 0;

      if (parcels?.length || selectedGisFileLayers?.length || pinpoints?.length) {
        await this.addRuLayers(parcels);

        const that = this;

        view
          .when()
          //.then(this.initLayerGroups())
          .then(this.setupLayerList())
          .then(this.updateSelectedSystemLayersOrder())
          .then(getGraphics)
          .then(getFeaturesFromPromises)
          .then(createLayer)
          .then(addToView)
          .then(that.zoomToCoordonates)
          .catch((error) => {
            // For internal usage
            console.error("The view's resources failed to load: ", error);
          })

        view.when(() => {
            reactiveUtils.watch(
                () => GIS.map.allLayers.map((layer) => layer),
                (layers) => {
                    layers.forEach((layer) => {
                        layer.watch('visible', () => {
                            this.setLayerVisibility(layer).then(() => {
                                if(!layer.skipSystemOrder) {
                                    this.updateSelectedSystemLayersOrder();
                                } else {
                                    layer.skipSystemOrder = false;
                                }
                            });
                        });
                    });
                },
                {once: true}
            );
        });

        function getGraphics() {
            const graphicPromises = [];
            parcels?.forEach((item) => {
                const { coordinates, cadastral_number } = item;
                const attributes = {
                    Name: cadastral_number,
                    Description: item.text,
                };

                const polygonRings = [];
                coordinates.forEach((el) => {
                    polygonRings.push([el.x, el.y]);
                });

                const polygon = new Polygon({
                    type: "polygon",
                    rings: polygonRings,
                    spatialReference: {
                        wkid: 31700,
                    },
                });

                const polygonGraphicPromise = polygonToGraphic(polygon, attributes);
                graphicPromises.push(polygonGraphicPromise);

                const textSymbol = {
                    type: "text", // autocasts as new TextSymbol()
                    color: "black",
                    text: cadastral_number,
                };

                let recalculatedCentroid = getPolygonCentroid(polygon);

                GIS.center.x = recalculatedCentroid.x;
                GIS.center.y = recalculatedCentroid.y;

                const pointText = {
                    //Create a point
                    type: "point",
                    longitude: recalculatedCentroid.x,
                    latitude: recalculatedCentroid.y,
                    spatialReference: {
                        wkid: 31700,
                    },
                };

                const pointTextGraphicPromise = pointToGraphic(
                    pointText,
                    textSymbol,
                    popupTemplate,
                );
                graphicPromises.push(pointTextGraphicPromise);
            });

            selectedGisFileLayers?.forEach((fileLayer) => {
                fileLayer.layers.forEach((item) => {
                    const features = item.featureSet?.features ?? [];
                    const itemName = item.layerDefinition?.name;
                    const description = item.layerDefinition?.description;
                    const attributes = {
                        Name: itemName,
                        Description: description,
                    };
                    const itemRenderer = rendererJsonUtils.fromJSON(item.layerDefinition?.drawingInfo?.renderer);

                    features.forEach((feature) => {
                        switch (item.featureSet.geometryType) {
                            case "esriGeometryPolygon":
                                if(!feature.geometry?.rings?.length) return;

                                const polygon = new Polygon({
                                    type: "polygon",
                                    rings: feature.geometry?.rings,
                                    spatialReference: {
                                        wkid: feature.geometry.spatialReference?.wkid ?? STEREO70_WKID,
                                    },
                                });

                                GIS.center.x = polygon.centroid.x;
                                GIS.center.y = polygon.centroid.y;

                                const polygonGraphicPromise = polygonToGraphic(polygon, attributes, itemRenderer.symbol);
                                graphicPromises.push(polygonGraphicPromise);

                                break;
                            case "esriGeometryPoint":
                                const defaultSymbol = {
                                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                                    style: "circle",
                                    color: "green",
                                    size: "8px",  // pixels
                                    outline: {  // autocasts as new SimpleLineSymbol()
                                        color: [ 136, 8, 8 ],
                                        width: 1  // points
                                    }
                                };
                                const pointText = {
                                    type: "point",
                                    longitude: feature.geometry?.x,
                                    latitude: feature.geometry?.y,
                                    spatialReference: {
                                        wkid: feature.geometry.spatialReference?.wkid ?? STEREO70_WKID,
                                    },
                                };

                                GIS.center.x = pointText.longitude;
                                GIS.center.y = pointText.latitude;

                                const pointTextGraphicPromise = pointToGraphic(
                                    pointText,
                                    itemRenderer.symbol ?? defaultSymbol
                                );
                                graphicPromises.push(pointTextGraphicPromise);
                                break;
                            case "esriGeometryPolyline":
                                const lineGraph =  new Polyline({
                                    type: "polyline",
                                    paths: feature.geometry?.paths,
                                    spatialReference: {
                                        wkid: feature.geometry.spatialReference?.wkid ?? STEREO70_WKID,
                                    },
                                });

                                const lineLength = feature.geometry?.paths[0].length;
                                const pathIndex = Math.floor(lineLength / 2);
                                const centerPointOfLine = lineGraph.getPoint(0, pathIndex);

                                if(centerPointOfLine?.x && centerPointOfLine.y) {
                                    GIS.center.x = centerPointOfLine.x;
                                    GIS.center.y = centerPointOfLine.y;
                                }

                                const lineGraphicPromise = polygonToGraphic(
                                    lineGraph,
                                    attributes,
                                    itemRenderer.symbol ?? defaultSymbol
                                );
                                graphicPromises.push(lineGraphicPromise);
                                break;
                            default:
                                break;
                        }
                    });
                });
            });

            pinpoints?.forEach((item) => {
                const textSymbol = {
                    type: "text", // autocasts as new TextSymbol()
                    color: "black",
                    text: item.cadastral_number ?? item.identification_number ?? '-',
                };
                GIS.center.x = item.centroid.x;
                GIS.center.y = item.centroid.y;

                const pointText = {
                    //Create a point
                    type: "point",
                    longitude: item.centroid.x,
                    latitude: item.centroid.y,
                    spatialReference: {
                        wkid: 31700,
                    },
                };
                const pointTextGraphicPromise = pointToGraphic(
                    pointText,
                    textSymbol,
                    addressPopupTemplate,
                    {
                        Description: item.locality_name ?? item.siruta,
                        Name: item.locality_name ?? item.siruta,
                        Address: item.cadastral_number,
                    }
                );
                graphicPromises.push(pointTextGraphicPromise);
            });

            return promiseUtils.eachAlways(graphicPromises);
        }

        // Filters only promises that resolve with valid values (a graphic
        // in this case) and resolves them as an array of graphics.
        function getFeaturesFromPromises(eachAlwaysResponses) {
          return eachAlwaysResponses
            .filter((graphicPromise) => {
              return graphicPromise.value;
            })
            .map((graphicPromise) => {
              return graphicPromise.value;
            });
        }

        function createLayer(graphics) {
            GIS.graphicsLayer = new GraphicsLayer({
                graphics,
            });
            return GIS.graphicsLayer;
        }

        function addToView(layer) {
          view.map.add(layer);
        }

        function polygonToGraphic(geo, attr, symbol, customPopupTemplate) {
          return new Promise((resolve) => {
            resolve(
              new Graphic({
                geometry: geo,
                symbol: symbol ?? simpleFillSymbol,
                attributes: attr,
                popupTemplate: customPopupTemplate ?? popupTemplate,
              })
            );
          });
        }

        function pointToGraphic(geo, symbol, customPopupTemplate, attr) {
          return new Promise((resolve) => {
            resolve(
              new Graphic({
                geometry: geo,
                symbol: symbol,
                popupTemplate: customPopupTemplate ?? popupTemplate,
                attributes: attr ?? {}
              })
            );
          });
        }
      }
    },
    zoomToCoordonates(location, zoomOptions) {
        projection
        .load()
        .then(() => {
            let opts = {
                duration: zoomOptions?.duration ?? 3000,
            };

            let zoomPointStereo70 = new Point({
                x: location?.x ?? GIS.center.x,
                y: location?.y ?? GIS.center.y,
                spatialReference: location?.wkid ?? 31700,
            });

            let defaultSpatialProjectionZoomPoint;
            if(zoomPointStereo70.spatialReference.wkid !== 102100) {
                //default spatial projection
                let outSpatialReference = new SpatialReference({
                    wkid: 102100, //Sphere_Sinusoidal projection
                });
                defaultSpatialProjectionZoomPoint = projection.project(
                    zoomPointStereo70,
                    outSpatialReference
                );
            } else {
                defaultSpatialProjectionZoomPoint = zoomPointStereo70;
            }

            // go to point at LOD 18 with custom duration
            GIS.view.goTo({
                target: defaultSpatialProjectionZoomPoint,
                zoom: zoomOptions?.zoomLevel ?? 18,
            }, opts);
        })
        .catch((error) => {
            // For internal use
            console.error("Failed to load projection ", error);
        });
    },
    updateSelectedSystemLayersOrder() {
        const selectedSystemLayers = this.visibleSystemLayers({systemLayers: this.systemLayers, selectedLayers: this.selectedLayers});
        const indexedSystemLayers = selectedSystemLayers.filter((layer) => layer.has_index);
        const layersLength = GIS.map.layers.length;
        indexedSystemLayers.forEach(layer => {
            const selectedFeatureLayer = this.layerList[layer.gis_layer_id];
            if (selectedFeatureLayer) {
                GIS.map.layers.reorder(selectedFeatureLayer, layersLength - layer.index_order);
            }
        });
    },
    visibleSystemLayers ({systemLayers, selectedLayers}) {
        return systemLayers.filter((el)=> selectedLayers[el.gis_layer_id] && selectedLayers[el.gis_layer_id].visible);
    },
    async getGisLayers() {
      if (!this.showLayers) return [];

      const uat_list = this.uatIdsFromRealEstates;
      uat_list.push(this.defaultCountyUatId);
      const layers = await this.getLayersByRealEstateUatIds(uat_list);
      const { token } = await this.getGisToken(this.isStaff);
      const visibleLayers = [];
      for (let i = 0; i < layers?.length; i++) {
        const layer = layers[i];
        const feature_service_url = layer.feature_service_url;
        esriConfig.request.interceptors.push({
            urls: feature_service_url,
            before: function (params) {
                params.requestOptions.query = params.requestOptions.query || {};
                params.requestOptions.query.token = token;
            },
        });
        for (let j = layer.sublayers.length - 1; j >= 0; j--) {
            const gisId = layer.sublayers[j].gis_id
            const featureLayer = new FeatureLayer({
                url: `${feature_service_url}`,
                layerId: gisId,
            });
            visibleLayers.push(featureLayer);
        }
      }
      return visibleLayers;
    },
    setupMapZoom(view) {
      var zoom = new Zoom({
        view: view,
        layout: "vertical",
      });
      // add to view
      view.ui.add(zoom, "bottom-right");
    },
    setupBasemapGallery(view) {
      let basemapGallery = new BasemapGallery({
        view: view,
        container: document.createElement("div"),
      });
      const bgExpand = new Expand({
        view: view,
        content: basemapGallery,
      });
        bgExpand.watch('expanded', () => {
            if (bgExpand.expanded && this.currentExpandedWidget?.id && this.currentExpandedWidget.id !== bgExpand.id) {
                this.currentExpandedWidget.expanded = false
            }
            this.currentExpandedWidget = bgExpand;
        })
      // close the expand whenever a basemap is selected
      basemapGallery.watch("activeBasemap", () => {
        const mobileSize =
          view.heightBreakpoint === "xsmall" ||
          view.widthBreakpoint === "xsmall";

        if (mobileSize) {
          bgExpand.collapse();
        }
      });
      view.ui.add(bgExpand, "bottom-right");
    },
    addMeasurement(view) {
      const expandMeasurement = new Expand({
        view,
        content: document.getElementById("measurementWidget"),
        expandIconClass: "esri-icon-measure",
      });

      expandMeasurement.watch('expanded', () => {
        if (expandMeasurement.expanded && this.currentExpandedWidget?.id && this.currentExpandedWidget.id !== expandMeasurement.id) {
            this.currentExpandedWidget.expanded = false;
            this.currentExpandedWidget = expandMeasurement;
        }
      })
      this.expandMeasurementRef = expandMeasurement;
      view.ui.add(expandMeasurement, "bottom-right");
    },
    async addPrintBtn(view) {
      const { token } = await this.getGisToken();
      esriConfig.request.interceptors.push({
        urls: [
          window.VUE_APP_ARCGIS_PRINTSERVER_URL,
        ],
        before: function (params) {
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query.token = token;
        },
      });
      let templateOptions = new TemplateOptions({
        attributionEnabled: false,
        scale: 3000,
        scaleEnabled: true,
        layout: 'a4-landscape',
        title: this.record?.identification_number,
      })
      const printWidget = new Print({
        view: view,
        container: document.createElement("div"),
        printServiceUrl: `${window.VUE_APP_ARCGIS_PRINTSERVER_URL}/Export%20Web%20Map%20Task`,
        // allowedFormats: 'pdf',
        templateOptions: templateOptions,
        attributionEnabled: false,
      });

      const expandPrint = new Expand({
        view,
        content: printWidget,
        expandIconClass: "esri-icon-printer",
      });

      expandPrint.watch('expanded', () => {
        if(expandPrint.expanded) {
            if (this.currentExpandedWidget?.id && this.currentExpandedWidget.id !== expandPrint.id) {
                this.currentExpandedWidget.expanded = false;
                this.currentExpandedWidget = expandPrint;
            }

            this.visibleLayerGroups = []
            Object.keys(this.layerList).forEach((gisId) => {
                if(this.layerList[gisId].visible) {
                    this.visibleLayerGroups.push(gisId);
                    this.layerList[gisId].skipSystemOrder = true;
                    this.layerList[gisId].visible = false;
                }
            });

            GIS.view.map.basemap = 'arcgis-imagery';

            this.setVisiblePrintWidgetLayers(this.visibleLayerGroups);
        } else {
            GIS.view.map.basemap = 'topo-vector';
            this.visibleLayerGroups.forEach((gisId) => {
                this.layerList[gisId].skipSystemOrder = true;
                this.layerList[gisId].visible = true;
            });

            this.visibleLayerGroups = [];
        }
      });
      view.ui.add(expandPrint, "bottom-right");
    },
    clearMeasurements() {
        GIS.view.ui.remove('distance');
        GIS.view.ui.remove('area');
        if(this.activeWidget) {
            GIS.view.ui.remove(this.activeWidget);
            this.activeWidget?.destroy();
            this.activeWidget = null;
            this.activeWidgetType = "";
        }
    },
    setActiveWidget(type) {
        if (this.activeWidgetType === type) {
            this.clearMeasurements();
            return;
        }
        switch (type) {
            case "distance":
                this.activeWidget = new DistanceMeasurement2D({
                    view: GIS.view,
                });
                // skip the initial 'new measurement' button
                this.activeWidget.viewModel.start();

                GIS.view.ui.add(this.activeWidget, "bottom-right");
                this.activeWidgetType = "distance";
                break;
            case "area":
                this.activeWidget = new AreaMeasurement2D({
                    view: GIS.view,
                    unit: "metric",
                });

                // skip the initial 'new measurement' button
                this.activeWidget.viewModel.start();

                this.activeWidget.watch("viewModel.state", (state) => {
                    if (state === 'measured') {
                        projection
                        .load()
                        .then(() => {
                            const surface = Number((this.activeWidget.viewModel.measurement.area/1000000).toFixed(2));
                            const perimeter = Number((this.activeWidget.viewModel.measurement.perimeter/1000).toFixed(2));

                            let outSpatialReference = new SpatialReference({
                                wkid: 31700, //Stereo70
                            });

                            const standardCentroid = new Point({
                                x: this.activeWidget.viewModel.measurement.geometry.centroid.x,
                                y: this.activeWidget.viewModel.measurement.geometry.centroid.y,
                                spatialReference: 102100, //Sphere_Sinusoidal projection
                            });

                            const stereo70Centroid = projection.project(
                                standardCentroid,
                                outSpatialReference
                            );

                            const standardPolygon = new Polygon({
                                hasZ: this.activeWidget.viewModel.measurement.geometry.hasZ,
                                hasM: this.activeWidget.viewModel.measurement.geometry.hasM,
                                rings: this.activeWidget.viewModel.measurement.geometry.rings,
                                spatialReference: { wkid: 102100 },
                            });

                            const stereo70Polygon = projection.project(
                                standardPolygon,
                                outSpatialReference
                            );

                            let coordinates = []
                            stereo70Polygon.rings[0].forEach((ring) => {
                                coordinates.push({
                                    x: ring[0],
                                    y: ring[1]
                                })
                            })

                            const transformedMeasurement = {
                                surface,
                                perimeter,
                                coordinates,
                                centroid: stereo70Centroid,
                                type: MEASUREMENT_TYPE_AREA,
                            }

                            this.setMeasurement(transformedMeasurement);
                        });
                    }
                });

                GIS.view.ui.add(this.activeWidget, "bottom-right");
                this.activeWidgetType = "area";

            break;
        }
    },
    addFullscreen(view) {
      const fullscreen = new Fullscreen({ view });
      view.ui.add(fullscreen, "top-right");
    },
    setupClickEventHandler() {
        this.clickEventHandler = GIS.view.on("double-click", (event) => {
            if(this.expandMeasurementRef.expanded) {
                // don't locate while measuring
                return;
            }

            event.stopPropagation();

            // clean drawings when selecting pin
            this.clearMeasurements();
            GIS.graphicsLayer?.removeAll();

            const params = {
                location: event.mapPoint,
            };
            locator.locationToAddress(serviceUrl, params).then(
                (response) => {
                    const address = response.address;
                    let locationWithStereo70 = {
                        latitude: response.location.latitude,
                        longitude: response.location.longitude,
                        latitude_stereo70: null,
                        longitude_stereo70: null,
                    };

                    projection
                    .load()
                    .then(() => {
                        const gpsPoint = new Point({
                            x: response.location.longitude,
                            y: response.location.latitude,
                        });

                        let outSpatialReferenceS70 = new SpatialReference({
                            wkid: 31700, //stereo70
                        });

                        const s70Point = projection.project(
                            gpsPoint,
                            outSpatialReferenceS70
                        )

                        locationWithStereo70.latitude_stereo70 = s70Point.y;
                        locationWithStereo70.longitude_stereo70 = s70Point.x;

                        this.setMeasurement({
                            address,
                            centroid: s70Point,
                            location: locationWithStereo70,
                            type: MEASUREMENT_TYPE_PIN,
                        });

                        if(!GIS.graphicsLayer) {
                            GIS.graphicsLayer = new GraphicsLayer();
                        } else {
                            GIS.graphicsLayer?.removeAll();
                        }

                        const popupDescription = `${locationWithStereo70.longitude}, ${locationWithStereo70.latitude}`;
                        const attributes = {
                            Address: address,
                            Description: popupDescription,
                        };

                        const pointGraphic = new Graphic({
                            geometry: s70Point,
                            symbol: marker,
                            attributes,
                            addressPopupTemplate,
                        });

                        GIS.graphicsLayer.add(pointGraphic);

                        const title = address != '' ? address : this.$t('GENERAL.MISSING_ADDRESS');
                        GIS.view.popup.open({
                            title,
                            content: popupDescription,
                            location: gpsPoint,
                        });
                    });
                },
                (err) => {
                    // Show no address found
                    this.showAddress(this.$t("ERRORS.ATLAS_NO_SEARCH_RESULT"), event.mapPoint);
                }
            );
        });
    },
    setupSketchWidget() {
        if(!GIS.graphicsLayer) {
            GIS.graphicsLayer = new GraphicsLayer();
        }
        const sketch = new Sketch({
            layer: GIS.graphicsLayer,
            view: GIS.view,
            availableCreateTools: ["point", "polygon"]
        });
    },
    async addRuLayers(parcels) {
        const { token } = await this.getGisToken(this.isStaff);
        if (parcels) {
            await this.getLayers({isStaff: this.isStaff, realEstates: parcels});
            this.fetchLayers(this.layers, token, parcels);
        }
        this.fetchSystemLayers(this.systemLayers, token);
    },
    fetchSystemLayers(layers, token) {
        if (layers.length) {
            layers.forEach(layer => {
                this.fetchArcgisLayers(layer, token);
            })
        }
    },
    fetchLayers(layers, token, parcels) {
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        if (layer?.components?.length) {
          for (let j = 0; j < layer.components.length; j++) {
            const componentLayer = layer.components[j];
            const isRUdefined = parcels.map((el)=> el.territorial_administrative_unit_id).includes(componentLayer.uat_id);
            if (isRUdefined || componentLayer.uat_id == this.defaultCountyUatId) {
                this.fetchArcgisLayers(componentLayer, token);
            }
          }
        } else {
          this.fetchArcgisLayers(layer, token);
        }
      }
    },
    fetchArcgisLayers(layer, token) {
        if(!layer.container_id || !layer.sublayers?.length) {return}

        const feature_service_url = layer.feature_service_url;
        esriConfig.request.interceptors.push({
          urls: feature_service_url,
          before: (params) => {
            params.requestOptions.query = params.requestOptions.query || {};
            params.requestOptions.query.token = token;
          },
        });
        const sublayers = [];
        for (let j = layer.sublayers.length - 1; j >= 0; j--) {
            const gisId = layer.sublayers[j].gis_id;
            const featureLayer = new FeatureLayer({
                url: `${feature_service_url}`,
                layerId: gisId,
            });
            sublayers.push(featureLayer);
        }

        const groupLayer = new GroupLayer({
          title: layer.name,
          visible: false,
          visibilityMode: 'independent',
          layers: sublayers,
          opacity: 0.75,
          id:layer.gis_layer_id
        });
        if(this.layerList[layer.gis_layer_id]) {
            this.mapObj.map.remove(this.layerList[layer.gis_layer_id]);
        }
        this.layerList[layer.gis_layer_id] = groupLayer;
        this.mapObj.map.add(groupLayer);
    },
    async initLayerGroups() {
        Object.values(this.layerList).forEach( v => {
            this.mapObj.map.remove(v);
        });
    },
    async setupLayerList() {
        this.layerListWidget = new LayerList({
            view: GIS.view,
            id: 'layers_legend_custom',
            container: document.createElement("div"),
            listItemCreatedFunction: (event) => {
                let item = event.item;
                if (this.selectedLayers[item.layer.id] && this.selectedLayers[item.layer.id].visible) {
                    item.visible = true;
                }

                if (!item.title) {
                    item.title = this.$t( "REAL_ESTATE.REAL_ESTATES");
                }
            },
        });
        if(this.expandLayerList) {
            GIS.view.ui.remove(this.expandLayerList);
        }
        this.expandLayerList = new Expand({
            view: GIS.view,
            content: this.layerListWidget
        });
        this.expandLayerList.watch('expanded', () => {
            if (this.expandLayerList.expanded && this.currentExpandedWidget?.id && this.currentExpandedWidget.id !== this.expandLayerList.id) {
                this.currentExpandedWidget.expanded = false;
                this.currentExpandedWidget = this.expandLayerList;
            }
        })
        GIS.view.ui.add(this.expandLayerList, { position: "bottom-right" });
    },
  },
};
</script>

<style lang="scss">
/* esri styles */
@import url("https://js.arcgis.com/4.26/esri/themes/light/main.css");

.map-view-container {
    height: inherit;

    .esri-popup .esri-popup-header .esri-title {
        font-size: 18px;
        font-weight: bolder;
    }

  .esri-popup .esri-popup-body .esri-popup-content {
    font-size: 14px;
  }

  #measurementWidget {
    background: #fff;
    padding: 10px;
  }

  .action-button {
    font-size: 16px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
    color: #6e6e6e;
    height: 32px;
    width: 32px;
    text-align: center;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }

  .action-button:hover,
  .action-button:focus {
    background: #0079c1;
    color: #e4e4e4;
  }

  .active {
    background: #0079c1;
    color: #e4e4e4;
  }
}
#viewDiv {
    height: inherit;
}
.esri-widget--button.active,
.esri-widget--button.active:hover,
.esri-widget--button.active:focus {
    cursor: default;
    background-color: #999696;
}
.esri-widget--button.active path,
.esri-widget--button.active:hover path,
.esri-widget--button.active:focus path {
    fill: #E4E4E4;
}
</style>
