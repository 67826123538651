<template>
    <div class="interest-area-container">
        <div class="mx-4">
            <i class="mr-2 fas fa-info-circle text-primary"></i>
            <span class="font-weight-normal">{{$t('ATLAS.INTEREST_AREAS_MANAGER.TITLE_TOOLTIP')}}</span>
        </div>
        <b-img :src="image" alt="image" class="area-image"></b-img>
        <hr>
        <template v-for="area in areas">
            <st-section
                :key="area.id"
                collapsible
                isCollapsed
                :collapsibleId="area.id"
                customClass="st-interest-area-container-section"
                :class="[areasSelected.includes(area.id) ? 'expanded' : '']"
                marginTopSize="0"
                @show='show'
            >
                <template #header>
                    <span>{{area.title}}</span>
                </template>
                <template #body>
                    <area-details :area="area"/>
                </template>
            </st-section>
            <hr :key="`${area.id}-hr`">
        </template>
        <st-button
            v-if="false"
            :callback="newArea"
            variant="link"
        >
            <span class="d-flex align-items-center">
                <i class="fas fa-plus"></i>
                <span class="font-size-h6">{{ $t('ATLAS.INTEREST_AREAS_MANAGER.BUTTONS.NEW_AREA') }}</span>
            </span>
        </st-button>
    </div>
</template>

<script>
import AreaDetails from "@/modules/atlas/components/interest-areas-manager/AreaDetails";
import { mapActions } from "vuex";
export default {
    name: 'InterestAreasContainer',
    components: { AreaDetails },
    props: {
        areas: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            areasSelected: [],
            image: `${process.env.BASE_URL}media/area.png`,
        }
    },
    computed: {},
    methods: {
        ...mapActions({
            newInterestArea: 'atlas/interest-areas/newInterestArea',
        }),
        newArea() {
            this.newInterestArea(true);
        },
        show(data) {
            if (data.extended) {
                this.areasSelected.push(data.areaId);
            } else {
                this.areasSelected =  this.areasSelected.filter(area => area !== data.areaId);
            }
        }
    },
    beforeDestroy() {
        this.newInterestArea(false);
    }
  };
</script>
<style lang="scss" scoped>
@import "./src/assets/sass/pages/atlas/atlas";

.area-image {
    width: 100%;
}
</style>
