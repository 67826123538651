<template>
    <div class="st-section-body">
        <loader v-if="isSyncLoading" />
        <div class="row">
            <div :class="colClasses">
                <div class="form-group">
                    <label v-if="isAtlasPublicView">
                        {{ fields.county_id.label }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.county_id.name]"
                            :options="fields.county_id.options"
                            :ref="fields.county_id.name"
                            :name="fields.county_id.name"
                            :disabled="true"
                            :fields="fields"
                            :model="model"
                            :formValidation="fv"
                            :placeholder="countyPlaceholder"
                        >
                        </st-autocomplete>
                    </div>
                </div>
            </div>
            <div :class="colClasses">
                <div class="form-group">
                    <label v-if="isAtlasPublicView">
                        {{ fields.territorial_administrative_unit_id.label }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.territorial_administrative_unit_id.name]"
                            :ref="fields.territorial_administrative_unit_id.name"
                            :name="fields.territorial_administrative_unit_id.name"
                            :options="fields.territorial_administrative_unit_id.options"
                            :fields="fields"
                            :model="model"
                            :formValidation="fv"
                            :placeholder="uatPlaceholder"
                        >
                        </st-autocomplete>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div :class="colClasses">
                <div class="form-group">
                    <label v-if="isAtlasPublicView">
                        {{ fields.cadastral_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.cadastral_number.name"
                            :name="fields.cadastral_number.name"
                            v-model="model[fields.cadastral_number.name]"
                            :disabled="!hasUatSelected"
                            :placeholder="cadastralNumberPlaceholder"
                        />
                    </div>
                </div>
            </div>

            <div class="col-12">
                <span v-if="searchStatus === 'notfound'" class="text-danger">
                    {{ $t('APPLICATION.TERRITORIAL_ATLAS.NOT_FOUND') }}
                </span>
                <span v-if="searchStatus === 'error'" class="text-danger">
                    {{ $t('APPLICATION.TERRITORIAL_ATLAS.ERROR') }}
                </span>
            </div>
        </div>

        <div>
            <div class="d-flex justify-content-between">
            <st-button
                v-if="isAtlasPublicView"
                :size=btnSize
                variant="link"
                :callback="hideModal"
                :disabled="isSyncLoading"
            >
                {{ $t("GENERAL.BUTTON.CANCEL") }}
            </st-button>
            <st-button
                :size=btnSize
                variant="primary"
                :callback="doParcelSearch"
                :spinner="isSyncLoading"
            >
                <span>{{ $t("GENERAL.BUTTON.SEARCH") }}</span>
            </st-button>

            </div>
        </div>

        <div>
            <parcel-item-cards @zoomToParcel="zoomToParcel" @deleteParcel="removeParcelAndGraphic"/>
        </div>
    </div>
</template>

<script>

import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import Polygon from "@arcgis/core/geometry/Polygon";
import * as projection from "@arcgis/core/geometry/projection";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { ParcelSearchModel } from "@/modules/atlas/models/parcel-search-model";
import ParcelItemCards from "@/modules/atlas/components/parcel-search/ParcelItemCards.vue"
import LocalAppConfig from '@/config/app.config.json';
import { getPolygonCentroid } from '@/modules/atlas/utils/graphic-utils'
import {
    STEREO70_WKID,
    DEFAULT_PARCEL_SEARCH_ZOOM_LEVEL,
    simpleFillSymbol,
    popupTemplate
} from "@/modules/atlas/utils/constants";

const { fields } = ParcelSearchModel;

const formSchema = new FormSchema([
    fields.id,
    fields.cadastral_number,
    fields.county_id,
    fields.territorial_administrative_unit_id,
]);

export default {
    name: 'ParcelSearchForm',
    components: {
        ParcelItemCards
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            polling: null,
            searchStatus: '',
            isSyncLoading: false,
            fv: null,
            gisLoaded: false,
        };
    },
    props: {
        isMobileDevice: Boolean,
        isSidebar: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        'model.territorial_administrative_unit_id': function (newVal, oldVal) {
            if (oldVal) {
                this.searchStatus = '';
                this.model[this.fields.cadastral_number.name] = "";
                this.fv.revalidateField(this.fields.cadastral_number.name, true);
            }
        },
        'gisView.view': function (newVal) {
            if(this.isSidebar && newVal) {
                if(!this.gisLoaded) {
                    this.initParcels();
                    this.gisLoaded = true;
                }
            }
        },
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            appConfig: 'shared/appConfig',
            defaultCountyId: "shared/defaultCountyId",
            parcels: "atlas/parcels/parcels",
            isAtlasPublicView: 'shared/isAtlasPublicView',
            gisView: 'atlas/layer-manager/activeGisView',
            parcelLayer: "atlas/parcels/parcelLayerRef",
            parcelSearchExpandRef: "atlas/parcels/parcelSearchExpandRef",
        }),
        hasUatSelected() {
            return !!this.model[this.fields.territorial_administrative_unit_id.name];
        },
        colClasses() {
            return {
                'col-12': true,
                'col-md-6': this.isAtlasPublicView,
            };
        },
        btnSize() {
            return this.isAtlasPublicView ? 'large' : 'medium';
        },
        countyPlaceholder() {
            return this.$t('ATLAS.PARCEL_SEARCH.COUNTY_PLACEHOLDER');
        },
        uatPlaceholder() {
            return this.$t('ATLAS.PARCEL_SEARCH.UAT_PLACEHOLDER');
        },
        cadastralNumberPlaceholder() {
            return this.$t('ATLAS.PARCEL_SEARCH.CADASTRAL_NUMBER_PLACEHOLDER');
        },
    },
    methods: {
        ...mapActions({
            searchParcel: 'atlas/parcels/searchParcel',
            removeParcel: 'atlas/parcels/doRemoveParcel',
        }),
        async doParcelSearch() {
            const { cadastral_number, territorial_administrative_unit_id } = this.model;

            if (cadastral_number && territorial_administrative_unit_id) {

                const county_name = this.fields.county_id.options.find(
                    (el) => el.value === this.model[this.fields.county_id.name]
                )?.text

                const uat_name = this.fields.territorial_administrative_unit_id.options.find(
                    (el) => el.value === this.model[this.fields.territorial_administrative_unit_id.name]
                )?.text

                const payload = {
                    topo_number: cadastral_number,
                    siruta_code: territorial_administrative_unit_id,
                    county_name,
                    uat_name,
                };

                try {
                    this.isSyncLoading = true;
                    const { data } = await this.searchParcel(payload);
                    this.searchStatus = data.status;
                    if (data.status === 'pending' || data.status === 'new') {
                        this.polling = setInterval(() => {
                            this.checkSearchStatus(payload);
                        }, 1500);
                    } else if (data.status === 'completed') {
                        if(data?.coordinates?.length) {
                            this.zoomToParcel({...data, county_name, uat_name});
                            this.$emit('closeModal');
                        } else {
                            this.searchStatus = 'error';
                        }
                    }
                } catch(error) {
                    this.searchStatus = 'error';
                } finally {
                    if (this.searchStatus !== 'pending') {
                        this.isSyncLoading = false;
                    }
                    this.model[this.fields.cadastral_number.name] = "";
                }
            }
        },
        async checkSearchStatus(payload) {
            try {
                const { data } = await this.searchParcel(payload);
                if (data?.status) {
                    this.searchStatus = data.status;
                    if (this.searchStatus === 'completed') {
                        clearInterval(this.polling);
                        if(data?.coordinates?.length) {
                            this.zoomToParcel({...data, county_name: payload.county_name, uat_name: payload.uat_name});
                            this.$emit('closeModal');
                        } else {
                            this.searchStatus = 'error';
                        }
                    } else if (this.searchStatus === 'notfound') {
                        clearInterval(this.polling);
                    } else if (this.searchStatus === 'error') {
                        clearInterval(this.polling);
                    }
                }
            } catch(error) {
                this.searchStatus = 'error';
                clearInterval(this.polling);
            } finally {
                if (this.searchStatus !== 'pending') {
                    this.isSyncLoading = false;
                }
            }
        },

        zoomToParcel (data) {
            const that = this;
            this.gisView?.view?.when(() => {
                projection
                .load()
                .then(() => {
                    let opts = {
                        duration: 400,
                    };

                    const { parcelPolygon, realEstateGraphic } = this.getParcelGraphic(data);

                    const oldGraphics = that.parcelLayer?.graphics?.filter( v => {
                        return v.attributes?.siruta_code == data.siruta_code
                            && v.attributes?.topo_number == data.topo_number;
                    });

                    that.parcelLayer.add(realEstateGraphic);

                    oldGraphics.forEach( (v) => {
                        that.parcelLayer.remove(v);
                    });

                    const centroid = getPolygonCentroid(parcelPolygon);

                    let zoomPointStereo70 = new Point({
                        x: centroid.x,
                        y: centroid.y,
                        spatialReference: STEREO70_WKID,
                    });

                    const outSpatialReference = new SpatialReference({
                        wkid: STEREO70_WKID,
                    });

                    let defaultSpatialProjectionZoomPoint = projection.project(
                        zoomPointStereo70,
                        outSpatialReference,
                    );

                    that.gisView.view.goTo({
                        target: defaultSpatialProjectionZoomPoint,
                        zoom: DEFAULT_PARCEL_SEARCH_ZOOM_LEVEL,
                    }, opts);

                    if(this.isAtlasPublicView) {
                        this.hideModal();
                    }
                }).catch((error) => {
                    console.error("Failed to load projection ", error);
                });
            });
        },
        getParcelGraphic(data) {
            const attributes = {
                Name: data.topo_number,
                Description: `${data.county_name}, ${data.uat_name}`,
                topo_number: data.topo_number,
                siruta_code: data.siruta_code,
            };

            const polygonRings = [];
            data.coordinates.forEach((el) => {
                polygonRings.push([el.x, el.y]);
            });

            const parcelPolygon = new Polygon({
                type: "polygon",
                rings: [polygonRings],
                spatialReference: { wkid: STEREO70_WKID},  //STEREO 70 / ancpi
            });

            const realEstateGraphic = new Graphic({
                geometry: parcelPolygon,
                symbol: simpleFillSymbol,
                attributes,
                popupTemplate: popupTemplate,
                layer: this.parcelLayer,
            });

            return { parcelPolygon, realEstateGraphic };
        },
        removeParcelAndGraphic(data) {
            this.removeParcel(data);
            const removedGraphics = this.parcelLayer?.graphics?.filter( v => {
                return v.attributes?.siruta_code == data.siruta_code
                    && v.attributes?.topo_number == data.topo_number;
            });
            removedGraphics.forEach( (v) => {
                this.parcelLayer.remove(v);
            });
        },
        initParcels() {
            this.gisView.view.when(() => {
                this.parcelLayer.removeAll();
                this.parcels.forEach( (parcel) => {
                    const { realEstateGraphic } = this.getParcelGraphic(parcel);
                    this.parcelLayer.add(realEstateGraphic);
                });
            });
        },
        hideModal() {
            this.$emit('hideModal');
        },
    },
    beforeDestroy() {
        if (this.polling) {
            clearInterval(this.polling);
        }
    },
    async created() {
        // we need to fall back to local appconfig on the atlas public view
        let localDefaultCountyId;
        if (this.isAtlasPublicView) {
            localDefaultCountyId = LocalAppConfig?.find(v => v.key == 'DEFAULT_COUNTY_ID')?.value;
        }
        const record = {
            county_id: this.defaultCountyId ?? localDefaultCountyId,
        };
        this.model = formSchema.initialValues(record);

        if(!this.isSidebar) {
            this.initParcels();
            this.gisLoaded = true;
        }
    },
    mounted() {
        this.fv = createFormValidation("st_parcel_search_form", this.rules);
    },
}
</script>
<style lang="scss">

</style>
