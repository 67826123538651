import { AtlasService } from '@/modules/atlas/atlas-service';
import Errors from '@/shared/error/errors';

// action types
export const UPDATE_VISIBILITY_ORDER = 'updateLayerVisibilityOrder';

// mutation types

const getDefaultState = (state) => ({});

const state = getDefaultState({});

const getters = {};

const mutations = {};

const actions = {
    async [UPDATE_VISIBILITY_ORDER](context, { layer, value }) {
        context.commit('shared/activateLoading', 'visibility-settings/updateLayerVisibilityOrder', { root: true });
        const method = layer.layer_type === 'simple' && layer.container_id ?
                     'updateLayerFromContainer' : 'updateLayerContainer';
        try {
            return await AtlasService[method](layer.id, {view_order: value})
        } catch(error) {
            Errors.handle(error);
            return data;
        } finally {
            context.commit('shared/deactivateLoading', 'visibility-settings/updateLayerVisibilityOrder', { root: true });
        }
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
