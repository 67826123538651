<template>
    <div>
        <st-modal
            :id="modalId"
            hide-header-delimiter
            hide-footer-delimiter
            hide-footer
            :noCloseOnBackdrop="true"
            centered="true"
            size="md"
            ref="modal"
            customClass="form-modal"
            @close="closeModal"
            @hidden="hideModal"
        >
            <template #header>
                <p class="font-size-h6 font-weight-bold mb-0 pb-0">
                    {{ $t("ATLAS.PARCEL_SEARCH.MODAL.HEADER") }}
                </p>
            </template>
            <template #body>
                <form class="form" novalidate="novalidate" id="st_parcel_search_form">
                    <parcel-search-form
                        v-if="loaded"
                        :isMobileDevice="isMobileDevice"
                        :isSidebar="false"
                        ref="parcelSearchForm"
                        @hideModal="hideModal"
                        @closeModal="closeModal"
                    />
                </form>
            </template>

        </st-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateRandomId } from '@/core/helpers/globalMethods';
import ParcelSearchForm from '@/modules/atlas/components/parcel-search/ParcelSearchForm';

export default {
    name: 'ParcelSearchModal',
    components: {
        ParcelSearchForm,
    },
    data() {
        return {
            modalId: `st-parcel-search-modal-${generateRandomId()}`,
            model: null,
            loaded: false,
        };
    },
    props: {
        isMobileDevice: Boolean,
    },
    computed: {
        ...mapGetters({
            parcelSearchExpandRef: "atlas/parcels/parcelSearchExpandRef",
        }),
    },
    methods: {
        ...mapActions({
            // initState: 'atlas/parcels/doInit',
        }),
        closeModal() {
            this.parcelSearchExpandRef?.collapse();
        },
        hideModal() {
            this.parcelSearchExpandRef?.collapse();
        },
    },
    beforeDestroy() {
        this.parcelSearchExpandRef?.collapse();
    },
    async created() {
        this.loaded = true;
    },
    mounted() {},
}
</script>
<style lang="scss">

</style>
