import { AtlasService } from '@/modules/atlas/atlas-service';
import Errors from '@/shared/error/errors';
// action types
export const DO_INIT_STATE = 'doInit';
export const SAVE_INTERSECTED_LAYERS = 'saveIntersectedLayers';
export const SAVE_POINT_OF_INTERSECTION = 'savePointOfIntersection';
export const GET_URBANISM_REGULATIONS = 'getUrbanismRegulations';
export const GET_LAYER_POPUP_INFO = 'getLayerPopupInfo';
export const GET_URBANISM_REGULATION_ZONE = 'getUrbanismRegulationZone';
export const UPDATE_LAYERS_LOADING = 'updateLayersLoading';
export const EXTRACT_INTERSECTION_DATA_TO_FILE = 'extractIntersectionDataToFile';
export const UPDATE_MEASURED_LAYERS_LOADING = 'updateMeasuredLayersLoading';

// mutation types
export const INIT_STATE = 'init';
export const SET_LAYERS = 'setLayers';
export const SET_POINT_OF_INTERSECTION = 'setPointOfIntersection';
export const SET_URBAN_REGULATIONS = 'setUrbanRegulation';
export const SET_LAYERS_LOADING = 'setLayersLoading';
export const SET_MEASURED_LAYERS_LOADING = 'setMeasuredLayersLoading';

const getDefaultState = () => ({
    layers: [],
    pointOfIntersection: null,
    urbanismRegulations: [],
    layersLoading: true,
    measuredLayerLoading: false,
    measuredLayerPolygon: []
});

const state = getDefaultState();

const getters = {
    layers: (state) => state.layers,
    pointOfIntersection: (state) => state.pointOfIntersection,
    urbanismRegulations: (state) => state.urbanismRegulations,
    layersLoading: (state) => state.layersLoading,
    measuredLayerLoading: (state) => state.measuredLayerLoading,
    measuredLayerPolygon: (state) => state.measuredLayerPolygon,
};

const actions = {
    [DO_INIT_STATE](context) {
        context.commit(INIT_STATE);
    },
    [SAVE_INTERSECTED_LAYERS](context, layers) {
        context.commit(SET_LAYERS, layers);
    },
    [SAVE_POINT_OF_INTERSECTION](context, point) {
        context.commit(SET_POINT_OF_INTERSECTION, point);
    },
    [GET_URBANISM_REGULATIONS](context, {isPublicView, key}) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "atlas/getUrbanismRegulations", { root: true,});
            const method = isPublicView ? 'getPublicUrbanismRegulations' : 'getUrbanismRegulations';
            AtlasService[method](key)
                .then((data) => {
                    context.commit(SET_URBAN_REGULATIONS, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading",  "atlas/getUrbanismRegulations",{ root: true });
                });
        });
    },
    [GET_LAYER_POPUP_INFO](context, {layer, gisToken}) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "atlas/getLayerPopupInfo", { root: true,});
            const method = layer.container_id ? 'getSimpleLayerPopupInfo' : 'getLayerPopupInfo';
            AtlasService[method](layer.id, gisToken)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading",  "atlas/getLayerPopupInfo",{ root: true });
                });
        });
    },
    [GET_URBANISM_REGULATION_ZONE](context, {key, isPublicView}) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "atlas/getUrbanismRegulationZone", { root: true,});
            const method = isPublicView ? 'getPublicUrbanismRegulationZone' : 'getUrbanismRegulationZone';
            AtlasService[method](key)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading",  "atlas/getUrbanismRegulationZone",{ root: true });
                });
        });
    },
    [UPDATE_LAYERS_LOADING](context, loading) {
        context.commit(SET_LAYERS_LOADING, loading);
    },
    [EXTRACT_INTERSECTION_DATA_TO_FILE](context, data) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "atlas/extractIntersectionDataToFile", { root: true,});
            AtlasService.extractIntersectionDataToFile(data)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading",  "atlas/extractIntersectionDataToFile",{ root: true });
                });
        });
    },
    [UPDATE_MEASURED_LAYERS_LOADING](context, loading) {
        context.commit(SET_MEASURED_LAYERS_LOADING, loading);
    },
};

const mutations = {
    [INIT_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_LAYERS](state, layers) {
        state.layers = layers;
    },
    [SET_POINT_OF_INTERSECTION](state, point) {
        state.pointOfIntersection = point;
    },
    [SET_URBAN_REGULATIONS](state, data) {
        state.urbanismRegulations = data;
    },
    [SET_LAYERS_LOADING](state, loading) {
        state.layersLoading = loading;
    },
    [SET_MEASURED_LAYERS_LOADING](state, {loading, measurement}) {
        state.measuredLayerLoading = loading;
        state.measuredLayerPolygon = measurement;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
