<template>
    <div class="mx-6">
        <div class="d-flex justify-content-between w-100">
            <st-button
                variant="link"
                class="font-size-h6 py-3 my-3 mr-3"
                :callback="close"
            > {{ $t('GENERAL.BUTTON.CANCEL') }}
            </st-button>
            <st-button
                variant="primary"
                class="font-size-h6 py-3 my-3"
                :callback="download"
                :disabled="disableDld"
                :spinner="loading"
                v-if="!url"
            >
                <span> {{ $t('GENERAL.BUTTON.DOWNLOAD') }}</span>
            </st-button>
        </div>
  </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';

export default {
    name: 'IntersectionManagerMeasuredLayerForm',
    props: {},
    data() {
        return {
            title: null,
            loading: false,
            url: null,
        };
    },
    computed: {
        ...mapGetters({
            measuredLayerPolygon: "atlas/layer-intersection/measuredLayerPolygon"
        }),
        disableDld() {
            return this.loading;
        }
    },
    methods: {
        ...mapActions({
            hideIntersectionManager: "atlas/sidebar/hideIntersectionManager",
            updateMeasuredLayersLoading: "atlas/layer-intersection/updateMeasuredLayersLoading",
            extractData: 'atlas/layer-intersection/extractIntersectionDataToFile',
        }),
        close() {
            this.updateMeasuredLayersLoading({
                loading: false,
                measurement: {}
            });
            this.hideIntersectionManager();
        },
        async download() {
            this.loading = true;

            const layers = this.measuredLayerPolygon.layers.map(el => [el.id, el.sublayer_ids]);
            const dataExtracted = await this.extractData({
                clipArea: this.measuredLayerPolygon.polygon,
                layers,
                name: '-',
                spatialReference: { wkid: 102100}});

            if (dataExtracted.url) {
                this.url = `${dataExtracted.url}?token=${dataExtracted.downloadToken}`;

                const fileLink = document.createElement('a');
                fileLink.href = this.url;
                fileLink.setAttribute('rel', 'noreferrer');
                document.body.appendChild(fileLink);

                fileLink.click();
            }

            this.loading = false;

            this.close();
        }
    },
};
</script>
