<template>
    <div v-if="parcels.length">
        <div class="mt-6 mb-4 d-block">
            {{ $t('ATLAS.PARCEL_SEARCH.SEARCH_HISTORY_LABEL') }}
        </div>
        <div class="row">
            <template v-for="opt in reversedParcels">
                <div :key="opt.parcel_key" class="cursor-pointer st-ta-item d-flex mt-2">
                    <div class="form-check align-middle w-75 mt-1" @click="zoomToParcel(opt)">
                        {{opt.topo_number}} {{opt.uat_name}}
                    </div>
                    <div class="ml-4 mr-4 mt-1 align-middle" @click="deleteParcel(opt)">
                        <i class="st-remove-file fa fa-trash text-primary align-right"></i>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ParcelItemCards',
    components: {},
    computed: {
        ...mapGetters({
            parcels: "atlas/parcels/parcels",
        }),
        reversedParcels() {
            return [...this.parcels.map(v => { return { ...v, parcel_key: `${v.topo_number}_${v.siruta_code}`} })].reverse();
        }
    },
    methods: {
        zoomToParcel(data) {
            this.$emit('zoomToParcel', data);
        },
        deleteParcel(data) {
            this.$emit('deleteParcel', data);
        },
    },
};
</script>

<style lang="scss" scoped>
    .st-ta-item {
        padding: 4px 8px;
        background-color: white !important;
        border: 1px solid rgb(226, 230, 234);
        margin-left: 4px;
        border-radius: 4px;
    }
</style>
