import { AtlasService } from '@/modules/atlas/atlas-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';
import Vue from 'vue';

// action types
export const GET_ARCGIS_APPS = 'getArcgisApps';
export const DELETE_ARCGIS_APP = 'deleteArcgisApp';
export const SHOW_APP_ADD_MODAL = 'showAppAddModal';
export const HIDE_APP_ADD_MODAL = 'hideAppAddModal';
export const SHOW_APP_EDIT_MODAL = 'showAppEditModal';
export const HIDE_APP_EDIT_MODAL = 'hideAppEditModal';
export const SELECT_ARCGIS_APP = 'selectArcgisApp';
export const ADD_ARCGIS_APP = 'addArcgisApp';
export const UPDATE_ARCGIS_APP = 'updateArcgisApp';

// mutation types
export const SET_ARCGIS_APPS = 'setArcgisApps';
export const SET_SELECTED_ARCGIS_APP = 'setSelectedArcgisApp';
export const SET_ADD_APP_MODAL_VISIBILITY = 'setAddAppModalVisibility';
export const SET_EDIT_APP_MODAL_VISIBILITY = 'setEditAppModalVisibility';
export const SET_EDITED_APP = 'setEditedApp';
export const REMOVE_EDITED_APP = 'removeEditedApp';
export const REMOVE_ARCGIS_APP = 'removeArcgisApp';


const getDefaultState = () => {
    return {
        arcgisApps: [],
        selectedArcgisApp: null,
        editedApp: null,
        isAppAddModalVisible: false,
        isAppEditModalVisible: false,
    };
};

const state = getDefaultState({});

const getters = {
    arcgisApps: (state) => state.arcgisApps,
    selectedArcgisApp: (state) => state.selectedArcgisApp,
    isAppAddModalVisible: (state) => state.isAppAddModalVisible,
    isAppEditModalVisible: (state) => state.isAppEditModalVisible,
    editedApp: (state) => state.editedApp,
};

const actions = {
    [GET_ARCGIS_APPS](context, isStaff) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-arcgis-apps/getArcgisApps', { root: true });
            const method = 'getArcgisApps';
            const params =  {
                limit: 100,
                sort: 'created_date',
            };

            if(!isStaff) {
                params["access_level"] = 'public';
            }

            AtlasService[method]({params})
                .then(async (data) => {
                    context.commit(SET_ARCGIS_APPS, data);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-arcgis-apps/getArcgisApps', { root: true });
                });
        });
    },
    async [SELECT_ARCGIS_APP](context, app) {
        context.commit(SET_SELECTED_ARCGIS_APP, app);
    },
    [SHOW_APP_ADD_MODAL](context) {
        context.commit(SET_ADD_APP_MODAL_VISIBILITY, true);
    },
    [HIDE_APP_ADD_MODAL](context) {
        context.commit(SET_ADD_APP_MODAL_VISIBILITY, false);
    },
    [SHOW_APP_EDIT_MODAL](context, app) {
        context.commit(SET_EDITED_APP, app);
        context.commit(SET_EDIT_APP_MODAL_VISIBILITY, true);
    },
    [HIDE_APP_EDIT_MODAL](context) {
        context.commit(SET_EDIT_APP_MODAL_VISIBILITY, false);
        context.commit(REMOVE_EDITED_APP);
    },
    [DELETE_ARCGIS_APP](context, app) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-arcgis-apps/deleteArcgisApp', { root: true });
            AtlasService.deleteArcgisApp(app.id)
                .then(() => {
                    context.commit(REMOVE_ARCGIS_APP, app);
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-arcgis-apps/deleteArcgisApp', { root: true });
                });
        });
    },
    async [ADD_ARCGIS_APP] (context, data) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-arcgis-apps/addArcgisApp', { root: true });
            AtlasService.addArcgisApp(data)
            .then((response) => {
                state.arcgisApps.push(response);
                Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'atlas-arcgis-apps/addArcgisApp', { root: true });
            });
        });
    },
    async [UPDATE_ARCGIS_APP] (context, data) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-arcgis-apps/updateArcgisApp', { root: true });
            AtlasService.updateArcgisApp(data.id, data)
            .then((response) => {
                const index = state.arcgisApps.findIndex((item) => item.id === response.id);
                Vue.set(state.arcgisApps, index, response);
                Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'atlas-arcgis-apps/updateArcgisApp', { root: true });
            });
        });
    },
};

const mutations = {
    [SET_ARCGIS_APPS](state, arcgisApps) {
        state.arcgisApps = arcgisApps;
    },
    [SET_SELECTED_ARCGIS_APP](state, selectedApp) {
        state.selectedArcgisApp = selectedApp;
    },
    [SET_ADD_APP_MODAL_VISIBILITY](state, isVisible) {
        state.isAppAddModalVisible = isVisible;
    },
    [SET_EDIT_APP_MODAL_VISIBILITY](state, isVisible) {
        state.isAppEditModalVisible = isVisible;
    },
    [SET_EDITED_APP](state, app) {
        state.editedApp = app;
    },
    [REMOVE_EDITED_APP](state) {
        state.editedApp = null;
    },
    [REMOVE_ARCGIS_APP] (state, app) {
        const index = state.arcgisApps.findIndex((item) => item.id === app.id);
        state.arcgisApps.splice(index, 1);
    },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
