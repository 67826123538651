<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <label> {{ fields.name.label }} * </label>
        <div class="form-group">
          <st-input-text
            v-model="model[fields.name.name]"
            :ref="fields.name.name"
            :name="fields.name.name"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label>
          {{ fields.ru_scope.label }} *
        </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.ru_scope.name]"
            :ref="fields.ru_scope.name"
            :field="fields.ru_scope"
            :formValidation="fv"
            :disabled="isSimpleStaff"
          ></st-select>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="!isSimpleStaff">
        <div class="form-group">
            <label>
                {{ fields.uat_id.label }} *
            </label>
            <st-autocomplete
                v-model="model[fields.uat_id.name]"
                :ref="fields.uat_id.name"
                :name="fields.uat_id.name"
                :options="fields.uat_id.options"
                :fields="fields"
                :model="model"
                :formValidation="fv"
                :disabled="isSimpleStaff"
            >
            </st-autocomplete>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label> {{ fields.owner_org_id.label }} * </label>
        <div class="form-group">
          <st-autocomplete
                v-model="model[fields.owner_org_id.name]"
                :ref="fields.owner_org_id.name"
                :name="fields.owner_org_id.name"
                :options="fields.owner_org_id.options"
                :fields="fields"
                :model="model"
                :formValidation="fv"
                :disabled="isSimpleStaff"
            >
            </st-autocomplete>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="addLayerById">
        <label> {{ fields.arcgis_imported_item.label }} * </label>
        <i class="fas fa-info-circle text-primary ml-2" v-b-popover.hover.blur="popoverConfig"></i>
        <div class="form-group">
          <st-input-text
            v-model="model[fields.arcgis_imported_item.name]"
            :ref="fields.arcgis_imported_item.name"
            :name="fields.arcgis_imported_item.name"
          />
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="addLayerById">
        <label> {{ fields.arcgis_imported_item_type.label }} * </label>
        <div class="form-group">
         <st-select
            v-model="model[fields.arcgis_imported_item_type.name]"
            :ref="fields.arcgis_imported_item_type.name"
            :field="fields.arcgis_imported_item_type"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="canManage">
        <label> {{ fields.private_layer_ownership_type.label }} * </label>
        <div class="form-group">
         <st-select
            v-model="model[fields.private_layer_ownership_type.name]"
            :ref="fields.private_layer_ownership_type.name"
            :field="fields.private_layer_ownership_type"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6"
             v-if="addLayerById && model[fields.arcgis_imported_item_type.name] === 'feature_server'"
        >
            <b-form-checkbox
                v-model="model[fields.arcgis_imported_item_is_linked.name]"
                :value="true"
                :unchecked-value="false"
            >
                {{ fields.arcgis_imported_item_is_linked.label }}
            </b-form-checkbox>
        </div>
      </div>
  </div>
</template>
<script>
import { FormSchema } from "@/shared/form/form-schema";
import { AtlasLayerModel } from "@/modules/atlas/models/atlas-layer-model";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { mapGetters} from "vuex";
const { fields } = AtlasLayerModel;

const formSchema = new FormSchema([
  fields.name,
  fields.owner_org_id,
  fields.ru_scope,
  fields.uat_id,
  fields.arcgis_imported_item,
  fields.arcgis_imported_item_type,
  fields.arcgis_imported_item_is_linked,
  fields.private_layer_ownership_type,
]);

export default {
  name: "SimpleLayerForm",
  props: {
    id: {
      type: String,
      required: true,
    },
    isSimpleStaff: {
      type: Boolean,
      required: true,
    },
    addLayerById: {
        type: Boolean,
        default: false
    },
    canManage: {
        type: Boolean,
        default: false
    },
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      fields,
      fv: null,
      gisUrl: 'https://gis.cluj.archi/portal/',
    };
  },
  computed: {
    ...mapGetters({
        accountDetails: 'auth/accountDetails'
    }),
    popoverConfig() {
        return {
            html: true,
            content: () => {
                let html = '';
                html += `<span>${this.$t('ATLAS.LAYER_MANAGER.TOOLTIP_TEXT')}`;
                html+= `<a href="${this.gisUrl}" target="_blank" class="ml-2">`;
                html+= `${this.$t('ATLAS.LAYER_MANAGER.TOOLTIP_URL')}`;
                html+= '</a></span>';
                return html;
            }
        }
    },
  },
  created() {
    this.model = formSchema.initialValues();
    if (this.isSimpleStaff) {
        const ownerOrgId  = this.fields.owner_org_id;
        ownerOrgId.fetchParams = { listOnlyAccountOrg: true };
        ownerOrgId.updateOptions();
        this.model.ru_scope = 'other';
        this.model.owner_org_id = this.accountDetails.organisation_id;
        this.model.uat_id = null;
    }
  },
  mounted() {
    this.fv = createFormValidation(this.id, this.rules);
  },
};
</script>
<style lang="">
</style>
