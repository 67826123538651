<template>
  <div class="map-view-container">
    <div id="viewDiv" class="mb-8" ref="mapView"></div>
    <div v-if="isMobileDevice">
      <transition name="sidebar-transition">
        <div v-show="showLeftSide">
          <slot name="left-sidebar"></slot>
        </div>
      </transition>
    </div>
    <div v-if="isMobileDevice" id="menuToggle" class="mb-18" @click="toggleMenu()"></div>
    <div id="timeSlider"></div>
    <div id="timeSliderWidget"></div>
    <div id="dateRangeWidget" class="esri-widget">
        <st-date-range
            v-model="dateRange"
            startField="start"
            endField="end"
            @change="onPeriodUpdate(dateRange)"
            :formatOptions="dateFormatOptions"
            position="dropright"
        />
    </div>
    <div v-if="!isMobileDevice" id="scaleWidget" class="esri-widget">
      <label for="ddlScale">Scale 1: </label>
      <select name="ddlScale" id="ddlScale" @change="scaleMap">
        <option value="500" data-tolerance="5">500</option>
        <option value="1000" data-tolerance="5">1.000</option>
        <option value="2000" data-tolerance="5">2.000</option>
        <option value="5000" data-tolerance="10">5.000</option>
        <option value="10000" data-tolerance="10">10.000</option>
        <option value="20000" data-tolerance="20">20.000</option>
        <option value="25000" data-tolerance="25">25.000</option>
        <option value="50000" data-tolerance="500">50.000</option>
        <option value="100000" data-tolerance="10000">100.000</option>
        <option value="150000" data-tolerance="15000">150.000</option>
        <option value="300000" data-tolerance="30000">300.000</option>
        <option value="600000" data-tolerance="60000">600.000</option>
        <option value="1200000" data-tolerance="120000">1.200.000</option>
      </select>
    </div>
    <div id="distance" class="esri-widget">
      <button
        class="action-button esri-icon-measure-line"
        :class="{ active: activeWidgetType === 'distance' }"
        id="distanceButton"
        type="button"
        title="Measure distance between two or more points"
        @click="setActiveWidget('distance', 'bottom-right')"
      ></button>
    </div>
    <div id="area" class="esri-widget">
      <button
        class="action-button esri-icon-measure-area"
        :class="{ active: activeWidgetType === 'area' }"
        id="areaButton"
        type="button"
        title="Measure area"
        @click="setActiveWidget('area', 'bottom-right')"
      ></button>
    </div>
  </div>
</template>

<script>
import Map from "@arcgis/core/Map";
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import esriConfig from "@arcgis/core/config";
import Extent from "@arcgis/core/geometry/Extent";
import Polygon from "@arcgis/core/geometry/Polygon";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Expand from "@arcgis/core/widgets/Expand";
import Home from "@arcgis/core/widgets/Home";
import Zoom from "@arcgis/core/widgets/Zoom";
import DistanceMeasurement2D from "@arcgis/core/widgets/DistanceMeasurement2D";
import AreaMeasurement2D from "@arcgis/core/widgets/AreaMeasurement2D";
import Search from "@arcgis/core/widgets/Search";
import * as promiseUtils from "@arcgis/core/core/promiseUtils";
import * as projection from "@arcgis/core/geometry/projection";
import IdentifyParameters from "@arcgis/core/rest/support/IdentifyParameters";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import Point from "@arcgis/core/geometry/Point";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import Legend from "@arcgis/core/widgets/Legend.js";

import moment from 'moment';
import mapSetupMixin from "@/modules/atlas/mixins/map-setup-mixin";
import timeSliderMixin from "@/modules/atlas/mixins/time-slider-mixin";
import popupTemplateMixin from "@/modules/atlas/mixins/popup-template-mixin";
import {
    SYSTEM_LAYERS_ORDER,
    LODS,
    simpleFillSymbol,
    popupTemplate,
    commentSymbol,
    activeCommentSymbol,
    newCommentSymbol,
    COMMENTS_ZOOM_LEVEL,
    mapLocatorUrl,
} from "@/modules/atlas/utils/constants";

import { mapGetters, mapActions } from "vuex";

let GIS = {};

export default {
  name: "AtlasMap",
  mixins: [mapSetupMixin, timeSliderMixin, popupTemplateMixin],
  props: {
    isMobileDevice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const now = new Date();
    const today = moment(now).format('YYYY-MM-DD');
    return {
      view: null,
      mapView: null,
      activeWidget: null,
      activeWidgetType: "",
      layerList: {},
      identityPointSymbol: null,
      identityLineSymbol: null,
      identityPolygonSymbol: null,
      selectedConflicts: null,
      intersectionEventHandler: null,
      intersectionTolerance: 100,
      selectedPolygon: null,
      measurement: null,
      fieldInfos: [
        'application_status',
        'application_id',
        'cadastral_number',
        'combined_id',
        'application_type',
        'application_type_name',
        'application_request_number',
        'application_number',
        'application_request_date',
        'application_issued_date',
        'real_estate_type',
        'uat_id',
        'county'
      ],
      defaultExtendParams: {
        xmin: 2235009.71520354,
        ymin: 5392339.09295755,
        xmax: 3337702.92266886,
        ymax: 6153183.22280151,
      },
      searchObj: null,
      dateRange: {
        start: '',
        end: today
      },
      today,
      dateFormatOptions: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      loadedFeatureLayers: [],
      loadedPinpointLayers: [],
      expandDateRange: null,
      expandComments: null,
      parcelSearchExpand: null,
      parcelGraphicsLayer: null,
      mapObj: {},
      sublayersList: [],
      commentsLayer: null,
      activeCommentGraphic: null,
      showLeftSide: false,
    };
  },
  computed: {
    ...mapGetters({
      allLayers: "atlas/layer-manager/visibleLayers",
      selectedLayers: "atlas/layer-manager/selectedLayers",
      areas: 'atlas/interest-areas/areas',
      newAreaSelected: 'atlas/interest-areas/newAreaSelected',
      token: "atlas/token/token",
      isInterestAreaVisible: 'atlas/sidebar/isInterestAreaVisible',
      systemLayers: 'atlas/layer-manager/systemLayers',
      initialSystemLayers: 'atlas/layer-manager/initialSystemLayers',
      isIntersectionManagerVisible: 'atlas/sidebar/isIntersectionManagerVisible',
      mapComments: 'atlas/comments/comments',
      commentThreadId: 'atlas/comments/parentId',
      isAtlasPublicView: 'shared/isAtlasPublicView',
      isStaff: "auth/isStaff",
    }),
  },
  watch: {
    newAreaSelected(value) {
        if (value) {
            this.setActiveWidget('area', 'bottom-right')
        }
    },
    isIntersectionManagerVisible(value) {
        if(!value) {
            this.hidePopup();
        }
    },
    isInterestAreaVisible(value) {
        if(value) {
            GIS.view.ui.remove(this.expandComments);
            this.hideCommentsSidebar();
        } else {
            GIS.view.ui.add(this.expandComments, "bottom-right");
        }
    },
    mapComments(rootComments) {
        if(!rootComments?.length) return;

        this.displayComments(rootComments?.filter(v => !v.parent_id));
    }
  },
  methods: {
    ...mapActions({
      saveIntersectedLayers: "atlas/layer-intersection/saveIntersectedLayers",
      savePointOfIntersection: "atlas/layer-intersection/savePointOfIntersection",
      showIntersectionManager: "atlas/sidebar/showIntersectionManager",
      showNewInterestArea: 'atlas/interest-areas/showNewInterestArea',
      gisViewLoaded: 'atlas/layer-manager/gisViewLoaded',
      getLayerPopupInfo: 'atlas/layer-intersection/getLayerPopupInfo',
      updateLayersLoading: 'atlas/layer-intersection/updateLayersLoading',
      extractData: 'atlas/layer-intersection/extractIntersectionDataToFile',
      updateMeasuredLayersLoading: 'atlas/layer-intersection/updateMeasuredLayersLoading',
      getComments: 'atlas/comments/getComments',
      resetComments: 'atlas/comments/resetComments',
      showCommentsSidebar: 'atlas/comments/showComments',
      hideCommentsSidebar: 'atlas/comments/hideComments',
      setCommentsExtent: 'atlas/comments/setExtent',
      setNewCommentCoordinates: 'atlas/comments/setNewCommentCoordinates',
      showLayer: 'atlas/layer-manager/showLayer',
      setParcelSearchExpandRef: 'atlas/parcels/doSetParcelSearchExpandRef',
      setParcelSearchVisibility: 'atlas/parcels/doSetParcelSearchVisibility',
      setParcelLayerRef: 'atlas/parcels/doSetParcelLayerRef',
    }),
    async loadMap(extendParam) {
      const initialX = this.$route.query.x;
      const initialY = this.$route.query.y;

      this.selectedConflicts = new GraphicsLayer();

      // parcel search display layer
      this.initParcelSearchLayer();

      const map = new Map({
        basemap: "topo-vector",
        layers: [this.selectedConflicts, this.parcelGraphicsLayer],
      });

      let initialExtend = new Extent({
        xmin: extendParam.xmin,
        ymin: extendParam.ymin,
        xmax: extendParam.xmax,
        ymax: extendParam.ymax,
        spatialReference: {
          wkid: 102100,
        },
      });
      // assign map to this view
      const view = new MapView({
        container: this.$el,
        map: map,
        extent: initialExtend,
        ui: {
          components: ["attribution"], // removes default widgets except for attribution
        },
      });
      var zoom = new Zoom({
        view: view,
        layout: "vertical",
      });
      // add to view
      view.ui.add(zoom, 'bottom-right');

      GIS.map = map;
      GIS.view = view;
      this.mapObj.map = map;
      this.mapObj.view = view;
      this.loadSelectedLayers();
      if (!this.isMobileDevice) {
        this.addLegendWidget('bottom-right');
        this.addBasemapGallery('bottom-right');
        this.addHome('bottom-right');
        this.addMeasurement('bottom-right');
        this.addScaleSelect('bottom-right');
        this.handleLayerComments('bottom-right');
        this.addSearchWidget(view, 'bottom-right');
        await this.addCustomSearchBar(view, 'bottom-right');
        await this.addSearchByDateRangeWidget('bottom-right');
        this.addTimeSlider('bottom-right');
        this.addParcelSearch('bottom-right');
      } else {
        this.addMenuToggle('top-right');
        this.addHome('top-right');
        await this.addCustomSearchBar(view, 'top-left');
        this.addSearchExpandWidget(view, 'top-left');
        await this.addSearchByDateRangeWidget('top-left');
        this.addLegendWidget('top-right');
        this.addBasemapGallery('top-right');
        this.addMeasurement('top-left');
        this.addTimeSlider('top-left');
        this.addParcelSearch('top-right');
      }

      this.addIdentityParams();
      this.attachIntersectionEvent();
      this.loadSystemLayers();
      this.extentWatch();
      this.timeSliderWatch();

      if(initialX && initialY) {
        this.zoomToCoordonates(initialX, initialY);
      }

      view.popup.defaultPopupTemplateEnabled = true;

      GIS.view.when(() => {
        this.gisViewLoaded({
            view: GIS.view,
            map: GIS.map,
            layerList: this.layerList,
        });
      });
    },
    addBasemapGallery(position) {
      const view = GIS.view;
      let basemapGallery = new BasemapGallery({
        view: view,
        container: document.createElement("div"),
      });
      const bgExpand = new Expand({
        view: view,
        content: basemapGallery,
        mode: 'floating',
      });

      // close the expand whenever a basemap is selected
      basemapGallery.watch("activeBasemap", () => {
        const mobileSize =
          view.heightBreakpoint === "xsmall" ||
          view.widthBreakpoint === "xsmall";

        if (mobileSize) {
          bgExpand.collapse();
        }
      });
      view.ui.add(bgExpand, position);
    },
    addScaleSelect(position) {
      GIS.view.constraints = {
        minZoom: 0,
        maxZoom: 20,
        lods: LODS,
      };

      reactiveUtils.watch(
        () => GIS.view?.stationary === true,
        () => {
            const $el = document.getElementById("ddlScale");
            if ($el) {
                $el.value = parseInt(GIS.view.scale);
            }
        });

      GIS.view.ui.add("scaleWidget", position);
    },
    addHome(position) {
      const homeBtn = new Home({
        view: GIS.view,
      });

      GIS.view.ui.add(homeBtn, position);
    },
    addMeasurement(position) {
      const area = document.getElementById("area");
      GIS.view.ui.add(area, position);

      const dist = document.getElementById("distance");
      GIS.view.ui.add(dist, position);
    },
    scaleMap(ev) {
      GIS.view.scale = ev.target.value;
      this.intersectionTolerance =
        ev.target.getAttribute("data-tolerance") || 10;
    },
    addMenuToggle(position) {
      const menuExpand = new Expand({
        view: GIS.view,
        expandIconClass: "esri-icon-drag-horizontal",
        collapseIconClass: "esri-icon-expand",
        container: document.getElementById("menuToggle"),
        mode: 'floating',
      })

      GIS.view.ui.add(menuExpand, position);
    },
    addLegendWidget(position) {
        const legend = new Legend({
            view: GIS.view,
            headingLevel: 5,
        });
        legend.style = {
            type: "classic",
            layout: "auto"
        };

        const legendExpand = new Expand({
            view: GIS.view,
            content: legend,
            collapseTooltip: this.$t('ATLAS.LAYER_MANAGER.HIDE_LEGEND'),
            expandTooltip:  this.$t('ATLAS.LAYER_MANAGER.SHOW_LEGEND'),
            expandIconClass: "esri-icon-legend",
            mode: 'floating',
        });

        GIS.view.ui.add(legendExpand, position);
    },
    toggleMenu() {
      this.showLeftSide = !this.showLeftSide;
    },
    addIdentityParams() {
      this.identityPointSymbol = {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        style: "square",
        color: "blue",
        size: "8px", // pixels
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: "#4FFCFF",
          width: 3, // points
        },
      };
      this.identityLineSymbol = {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: "#4FFCFF",
        width: "2px",
        style: "short-dot",
      };
      this.identityPolygonSymbol = {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [51, 51, 204, 0],
        style: "solid",
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: "#4FFCFF",
          width: 1,
        },
      };
    },
    // Interest areas
    loadAreas() {
        this.interestAreaSelected = true;
        if (this.areas && this.areas.length) {
            this.areas.forEach((area) => {
                this.loadArea(area);
            });
        }
    },
    loadArea(areaData, zoom = false) {
        const that = this;
        let centerPointX = 0;
        let centerPointY = 0;
        const view = GIS.view;
        view.when()
          .then((getGraphics))
          .then(getFeaturesFromPromises)
          .then(createLayer)
          .then(addToView)
          .then(zoomAndCenter)
          .catch((error) => {
            // For internal usage
            console.error("The view's resources failed to load: ", error);
          });

        function getGraphics() {
            const graphicPromises = [];
            const { area, title } = areaData;
            const attributes = {
                Name: title,
                Description: areaData.notes,
            };
            const polygonRings = [];
            area.forEach((el) => {
                polygonRings.push([el.x, el.y]);
            });

            const polygon = new Polygon({
                type: "polygon",
                rings: polygonRings,
                // spatialReference: { wkid: 102100}, //Sphere_Sinusoidal projection
                spatialReference: { wkid: 31700},  //STEREO 70 / ancpi
            });

            centerPointX = polygon.centroid.x;
            centerPointY = polygon.centroid.y;

            const polygonGraphicPromise = polygonToGraphic(polygon, attributes);
            graphicPromises.push(polygonGraphicPromise);
            const textSymbol = {
              type: "text",
              color: "black",
              text: title,
            };

            const pointText = {
              //Create a point
              type: "point",
              longitude: polygon.centroid.x,
              latitude: polygon.centroid.y,
              spatialReference: {
                wkid: 31700,
              },
            };

            const pointTextGraphicPromise = pointToGraphic(
              pointText,
              textSymbol
            );
            graphicPromises.push(pointTextGraphicPromise);

            return promiseUtils.eachAlways(graphicPromises);
        }

        // Filters only promises that resolve with valid values (a graphic
        // in this case) and resolves them as an array of graphics.
        function getFeaturesFromPromises(eachAlwaysResponses) {
          return eachAlwaysResponses
            .filter((graphicPromise) => {
              return graphicPromise.value;
            })
            .map((graphicPromise) => {
              return graphicPromise.value;
            });
        }

        function createLayer(graphics) {
          return new GraphicsLayer({
            graphics,
          });
        }
        function addToView(layer) {
            layer.title = areaData.text;
            if (zoom) {
                that.layerList[areaData.title] = layer;
            }
            view.map.add(layer);
        }
        function polygonToGraphic(geo, attr) {
          return new Promise((resolve) => {
            resolve(
              new Graphic({
                geometry: geo,
                symbol: simpleFillSymbol,
                attributes: attr,
                popupTemplate: popupTemplate,
              })
            );
          });
        }
        function pointToGraphic(geo, symbolItem) {
          return new Promise((resolve) => {
            resolve(
              new Graphic({
                geometry: geo,
                symbol: symbolItem,
              })
            );
          });
        }
        function zoomAndCenter() {
            if (zoom) {
              that.zoomToCoordonates(centerPointX, centerPointY, 18);
            }
        }
    },
    loadSelectedLayers() {
        Object.keys(this.selectedLayers)?.forEach((layerId) => {
            if (this.selectedLayers?.[layerId]?.visible) {
                this.loadSelectedLayer(layerId);
            }
        });
        GIS.view.when(() => {
            this.updateSelectedSystemLayersOrder()
        })
    },
    updateSelectedSystemLayersOrder(action = '') {
        const layersLength = GIS.map.layers.length;
        let selectedLayersList = [];
        Object.keys(this.selectedLayers).forEach( (layer, index)=> {
            if (this.selectedLayers[layer]?.visible)  {
                selectedLayersList.push( {
                    index: this.selectedLayers[layer].parentIndex,
                    gis_layer_id: this.selectedLayers[layer].gis_layer_id,
                    sublayers: Object.values(this.selectedLayers[layer].sublayers)
                })
            }
        })
        selectedLayersList = selectedLayersList.sort((a, b) => parseFloat(b.index) - parseFloat(a.index));
        let i = 0;
        selectedLayersList.forEach(element => {
            const layerSelected = element.gis_layer_id;
            const sublayers = element.sublayers;
            const selectedFeatureLayer = this.layerList[layerSelected];
            if (selectedFeatureLayer) {
                // Descending  reoder sublayers
                const descSublayer = sublayers.sort((a, b) => parseFloat(b.indexOrder) - parseFloat(a.indexOrder));
                descSublayer.forEach((sublayer, indexS) => {
                    GIS.map.layers.reorder(selectedFeatureLayer[sublayer.indexOrder], i);
                    i++;
                });
            }
        });
        if (action === 'addLayer' && this.selectedParcel?.cadastral_number) {
            const parcelLayer = this.layerList[this.selectedParcel.cadastral_number];
            // Pacel layer visible on top
            GIS.map.layers.reorder(parcelLayer, layersLength );
        }
    },
    loadSelectedLayer(layerId) {
      const service_url = this.allLayers.find((l) => l.gis_layer_id == layerId)?.feature_service_url;
      this.fetchLayers(layerId, this.token, service_url);
    },
    addLayer(layerInfo, mutation = '') {
      const layerId = layerInfo.gis_layer_id;
      if (this.layerList[layerId]) {
        const layer = this.layerList[layerId];
        let areAllLayersLoaded = true;
        if (Object.keys(layer).length) {
          Object.keys(layer).forEach((sublayerId) => {
            layer[sublayerId].visible = !!this.selectedLayers?.[layerId]?.sublayers?.[sublayerId]?.visible;
            areAllLayersLoaded = areAllLayersLoaded && layer[sublayerId].loaded;
          });
        }
        // if the layer is already loaded into the map, readjust the timeslider
        if(areAllLayersLoaded && mutation != 'setVisibleLayers') {
          this.calculateTimesliderStartEndDate();
        }
      } else {
        this.fetchLayers(layerId, this.token, layerInfo.feature_service_url);
      }

      const action = 'addLayer';
      const selectedSystemLayers = this.visibleSystemLayers({
        systemLayers: this.initialSystemLayers,
        selectedLayers: this.selectedLayers
      });

      GIS.view.when(() => {
        this.configurePopupTemplate({layersLoaded: selectedSystemLayers, layerList: this.layerList});
        if (selectedSystemLayers.length) {
            this.showCustomSearchBar(selectedSystemLayers);
            this.expandDateRange.visible = true;
        }

        this.updateSelectedSystemLayersOrder(action);
      });
    },
    addSublayer({ layerId, sublayerId }) {
      if (!this.layerList[layerId]) {
        this.loadSelectedLayer(layerId);
      }
      this.layerList[layerId][sublayerId].visible = true;
    },
    removeSublayer({ layerId, sublayerId }) {
      if (!this.layerList[layerId]) {
        this.loadSelectedLayer(layerId);
      }
      this.layerList[layerId][sublayerId].visible = false;
    },
    fetchLayers(layerId, token, feature_service_url) {
      const isSystemLayer = this.initialSystemLayers.some((layer)=> layer.gis_layer_id === layerId);
      if (feature_service_url) {
        esriConfig.request.interceptors.push({
          // set the `urls` property to the URL of the FeatureLayer so that this
          // interceptor only applies to requests made to the FeatureLayer URL
          urls: feature_service_url,
          // use the BeforeInterceptorCallback to add token to query
          before: function (params) {
            params.requestOptions.query = params.requestOptions.query || {};
            params.requestOptions.query.token = token;
          },
        });

        const sublayerList = {};
        const sublayers =  this.allLayers.find((el) => el.gis_layer_id === layerId)?.sublayers || [];
        for (let i = sublayers.length - 1; i >= 0; i--) {
            const featureLayer = new FeatureLayer({
                url: `${feature_service_url}/${sublayers[i].gis_id}`,
                layerId: sublayers[i].gis_id,
            });

            if (isSystemLayer) this.loadedFeatureLayers.push(featureLayer);

            const isPinpointLayer = SYSTEM_LAYERS_ORDER.some((layerCfg) => {
              return layerCfg.feature_service_url == `${feature_service_url}/${sublayers[i].gis_id}`
            });

            if(isPinpointLayer) {
                this.loadedPinpointLayers.push(featureLayer);
            }

            this.sublayersList.push(featureLayer);

            featureLayer.visible = this.selectedLayers[layerId]?.sublayers?.[i]?.visible ?? true;
            const gisId = sublayers[i].gis_id;
            sublayerList[gisId] = featureLayer;

            GIS.map.add(featureLayer);
        }
        this.layerList[layerId] = sublayerList;

        // apply custom layer styles
        const atlasLayer = this.findLayerByGIsID(layerId);
        if(atlasLayer) {
            this.configureStyles(atlasLayer, sublayerList);
        }
      }
    },
    fieldsInfoContent() {
        const fieldInfos = this.fieldInfos.map((field) => {
            return {
                fieldName: field,
                label: this.$t(`ATLAS.POPUP_TEMPLATE_INFO_FIELDS.${field.toUpperCase()}`)
            }
        })
        return [
            {
                type: "fields",
                fieldInfos
            }
        ]
    },
    removeLayer(layerInfo, mutation = '') {
        const selectedSystemLayers = this.visibleSystemLayers({systemLayers: this.initialSystemLayers, selectedLayers: this.selectedLayers});
        if (selectedSystemLayers.length) {
            this.showCustomSearchBar(selectedSystemLayers)
        } else {
            this.searchObj.visible = false
            this.searchObj.sources = [];
            this.expandDateRange.visible = false;
        }
        const layerId = layerInfo.gis_layer_id;
        const layer = this.layerList[layerId];
        if (layer && Object.keys(layer).length) {
            Object.keys(layer).forEach((sublayerId) => {
                layer[sublayerId].visible = false;
            });
        }

        if(mutation != 'removeVisibleLayers') {
            this.calculateTimesliderStartEndDate();
        }
    },
    removeArea() {
        this.clearLayers();
        this.areas.forEach((area) => {
            this.loadArea(area);
        });
    },
    clearLayers() {
        const map = GIS.map;
        if (map) {
            map.layers.removeAll();
        }
    },
    initParcelSearchLayer() {
        this.parcelGraphicsLayer = new GraphicsLayer();
        this.setParcelLayerRef(this.parcelGraphicsLayer);
    },
    checkParcelSearchLayer() {
        const map = GIS.map;
        let parcelSearchLayerExists = false;
        if(map && this.parcelGraphicsLayer) {
            parcelSearchLayerExists = map.layers.some( (layer) => {
                return layer.id === this.parcelGraphicsLayer.id;
            });
        }

        if(!map || !parcelSearchLayerExists) {
            // this.initParcelSearchLayer();
            // map.layers.add(this.parcelGraphicsLayer);
            this.loadMap(this.defaultExtendParams);
            this.zoomToInitialExtent();
        }
    },
    setActiveWidget(type, position) {
      if (this.activeWidgetType === type) {
        GIS.view.ui.remove(this.activeWidget);
        this.activeWidget.destroy();
        this.activeWidget = null;
        this.activeWidgetType = "";
        return;
      }
      switch (type) {
        case "distance":
          this.activeWidget = new DistanceMeasurement2D({
            view: GIS.view,
          });
          // skip the initial 'new measurement' button
          this.activeWidget.viewModel.start();

          GIS.view.ui.add(this.activeWidget, position);
          this.activeWidgetType = "distance";
          break;
        case "area":
          this.activeWidget = new AreaMeasurement2D({
              view: GIS.view,
              unit: "metric",
          });

          // skip the initial 'new measurement' button
          this.activeWidget.viewModel.start();

          this.activeWidget.watch("viewModel.measurement", (measurement) => {
              if (measurement) {
                this.measurement = measurement;
              }
          });

          this.activeWidget.watch("viewModel.state", (state)=> {
            if (state === 'measured') {
                const surface = Number((this.measurement.area/1000000).toFixed(2));
                const perimeter = Number((this.measurement.perimeter/1000).toFixed(2));
                const rings = this.measurement.geometry.rings[0];
                let area = []
                rings.forEach((ring) => {
                    area.push({
                        x: ring[0],
                        y: ring[1]
                    })
                })

                this.selectedPolygon = {
                    surface,
                    perimeter,
                    area,
                }

                if(this.isInterestAreaVisible) {
                    // add interest area
                    this.showNewInterestArea({status: true, selectedPolygon: this.selectedPolygon});
                } else {
                    // layer selection/measurement export to shapefile
                    const allVisibleLayers = this.getAllVisibleLayers();
                    const hasVisibleSublayer = allVisibleLayers.map(v => v.sublayers.length).some(v => v > 0);

                    if(hasVisibleSublayer) {
                        this.showIntersectionManager();

                        const polygonRings = [];
                        this.selectedPolygon.area.forEach((el) => {
                            polygonRings.push([el.x, el.y]);
                        });

                        this.updateMeasuredLayersLoading({
                            loading: true,
                            measurement: {
                                polygon: polygonRings,
                                layers: allVisibleLayers
                            }
                        });
                    }
                }
            }
          });

          GIS.view.ui.add(this.activeWidget, position);
          this.activeWidgetType = "area";
          break;
      }
    },
    executeIntersectionIdentification(event) {
        if (!this.interestAreaSelected && !this.parcelSearchSelected) {
            this.hideMeasuredLayers();

            const that = this;
            const xMapPoint = event.mapPoint.x;
            const yMapPoint = event.mapPoint.y;
            this.savePointOfIntersection({
                x: event.mapPoint.latitude,
                y: event.mapPoint.longitude,
            });

            const allVisibleLayers = this.getAllVisibleLayers();
            const numberOfVisibleLayers = Object.values(this.layerList).reduce(
                (acc, el) => acc + Object.keys(el).length,
                0
            );

            this.selectedConflicts.removeAll();
            const tolerance = 100;
            const rings = [
                [
                [xMapPoint - tolerance, yMapPoint - tolerance],
                [xMapPoint - tolerance, yMapPoint + tolerance],
                [xMapPoint + tolerance, yMapPoint + tolerance],
                [xMapPoint + tolerance, yMapPoint - tolerance],
                [xMapPoint - tolerance, yMapPoint - tolerance], // same as first vertex
                ],
            ];

            const polygon = new Polygon({
                hasZ: true,
                hasM: true,
                rings: rings,
                spatialReference: { wkid: 102100 },
            });

            this.updateLayersLoading(true);
            const promises = [];
            let layersLoaded = []
            let layerSearchedCount = 0;
            for (let i = 0; i < allVisibleLayers.length; i++) {
                const currentLayerId = allVisibleLayers[i].id;
                const visibleLayers = allVisibleLayers[i]?.sublayers || [];
                for (let j = 0; j < visibleLayers.length; j++) {
                    const prms =  new Promise((resolve,reject) => {
                        visibleLayers[j].queryFeatures({
                            //query object
                            geometry: polygon,
                            spatialRelationship: "intersects",
                            returnGeometry: true,
                            outFields: ["*"],
                        })
                        .then((featureSet) => {
                            layerSearchedCount++;
                            let resultFeatures = featureSet.features;
                            if (resultFeatures.length > 0) {
                                for (let k = 0; k < resultFeatures.length; k++) {
                                let mySymbol = null;
                                let myAttributes = null;
                                switch (resultFeatures[k].geometry.type) {
                                    case "point":
                                        mySymbol = that.identityPointSymbol;
                                        const {
                                            application_id,
                                            application_issued_date,
                                            application_number,
                                            application_request_number,
                                            application_request_date,
                                            cadastral_number,
                                            application_status,
                                            application_type,
                                            application_type_name,
                                            real_estate_type,
                                            uat_id,
                                            uat_name,
                                        } = resultFeatures[k].attributes;
                                        myAttributes = {
                                            isApplication: true,
                                            uid: resultFeatures[k].layer.id,
                                            name: `${application_type_name} ${application_request_number}`, // Feature name
                                            application_request_number,
                                            application_request_date,
                                            cadastral_number,
                                            application_id,
                                            application_status,
                                            application_number,
                                            application_issued_date,
                                            uat_id,
                                            uat_name,
                                            real_estate_type,
                                            gis_layer_id: currentLayerId,
                                            sublayer_id: resultFeatures[k].layer?.layerId,
                                        };
                                    break;
                                    case "polyline":
                                        mySymbol = that.identityLineSymbol;
                                        myAttributes = {
                                            uid: resultFeatures[k].layer.id,
                                            layer_name: resultFeatures[k].attributes.Layer,
                                            gis_layer_id: currentLayerId,
                                            sublayer_id: resultFeatures[k].layer?.layerId,
                                            rlu_id: resultFeatures[k].attributes?.rlu_id,
                                        };
                                    break;
                                    case "polygon":
                                        mySymbol = that.identityPolygonSymbol;
                                        myAttributes = {
                                            uid: resultFeatures[k].layer.id,
                                            layer_name: resultFeatures[k].attributes.Layer,
                                            gis_layer_id: currentLayerId,
                                            sublayer_id: resultFeatures[k].layer?.layerId,
                                            rlu_id: resultFeatures[k].attributes?.rlu_id,
                                            uat_name: resultFeatures[k].attributes?.uat_name ?? resultFeatures[k].attributes?.uat,
                                            uat_id: resultFeatures[k].attributes?.siruta,
                                        };
                                    break;
                                }
                                if (myAttributes.rlu_id || myAttributes.uat_id) {
                                  // Open layer intersection manager sidebar
                                  that.showIntersectionManager();
                                }
                                let graphic = new Graphic({
                                    geometry: resultFeatures[k].geometry,
                                    symbol: mySymbol,
                                    attributes: myAttributes,
                                });
                                that.selectedConflicts.graphics.add(graphic);
                                }
                            }

                            let intersectedLayers = [];
                            for ( let p = 0; p < that.selectedConflicts.graphics.items.length; p++) {
                                let myItem = that.selectedConflicts.graphics.items[p];
                                const { uid, gis_layer_id, layer_name, sublayer_id, rlu_id } = myItem.attributes;
                                const layerData = this.allLayers.find(
                                    (el) => el.gis_layer_id === gis_layer_id
                                );
                                if (layerData) {
                                    const matchedIntersectionLayer = intersectedLayers.find(
                                        (el) => el.gis_layer_id === gis_layer_id
                                    );

                                    const matchedSublayer = layerData.sublayers.find(
                                        (el) => el.gis_id === sublayer_id
                                    );

                                    if (matchedIntersectionLayer) {
                                        const unmatchedIntersectionLayers =
                                            intersectedLayers.filter(
                                                (el) => el.gis_layer_id !== gis_layer_id
                                            );
                                        matchedIntersectionLayer.sublayers.push({...matchedSublayer, rlu_id });
                                        if (matchedIntersectionLayer?.urbanism_regulations?.includes(rlu_id )) {
                                            matchedIntersectionLayer?.urbanism_regulations.push(rlu_id);
                                        }
                                        intersectedLayers = [
                                            ...unmatchedIntersectionLayers,
                                            {...matchedIntersectionLayer, },
                                        ];
                                    } else {
                                        const isIndexedSystemLayer = this.initialSystemLayers.some((layer)=> layer.gis_layer_id === layerData.gis_layer_id && layer.has_index);
                                        if (myItem.geometry.type === "point" && layerSearchedCount === numberOfVisibleLayers) {
                                            const formattedLayerData = {
                                                ...layerData,
                                                ...myItem.attributes,
                                                isIndexedSystemLayer
                                            };
                                            intersectedLayers.push(formattedLayerData);
                                        } else {
                                            const formattedLayerData = {
                                                ...layerData,
                                                uat_name: myItem.attributes.uat_name,
                                                uat_id: myItem.attributes.uat_id,
                                                sublayers: [],
                                                urbanism_regulations: [],
                                                isApplication: false,
                                                isIndexedSystemLayer
                                            };
                                            if (matchedSublayer) {
                                                formattedLayerData.sublayers.push({...matchedSublayer, rlu_id });
                                            }
                                            if (rlu_id) {
                                                formattedLayerData.urbanism_regulations.push(rlu_id);
                                            }
                                            intersectedLayers.push(formattedLayerData);
                                        }
                                    }
                                }
                            }
                            this.saveIntersectedLayers(intersectedLayers);
                            layersLoaded = intersectedLayers;
                            resolve();
                        });
                    })
                    promises.push(prms);
                }
            }
            Promise.all(promises)
              .finally(()=> {
                  this.updateLayersLoading(false);
                  this.configurePopupTemplate({layersLoaded, layerList: this.layerList});
              });
        }
    },
    attachIntersectionEvent() {
      GIS.view?.when(() => {
        if (!this.intersectionEventHandler) {
          this.intersectionEventHandler = GIS.view.on(
            "click",
            this.executeIntersectionIdentification
          );
          // Set the parameters for the identify
          const params = new IdentifyParameters();
          params.tolerance = 3;
          params.layerIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
          params.layerOption = "visible";
          params.width = GIS.view.width;
          params.height = GIS.view.height;
        }
      });
    },

    async handleLayerComments(position) {
        if(!this.isStaff) return;

        this.hideCommentsSidebar();

        GIS.view?.when(() => {
            this.expandComments = new Expand({
                view: GIS.view,
                content: document.getElementById("right-sidebar-comments"),
                expandIconClass: "esri-icon-comment",
                collapseIconClass: "esri-icon-collapse",
                container: document.createElement("div"),
                expandTooltip:  this.$t('ATLAS.COMMENTS_MANAGER.COMMENTS'),
                collapseTooltip:  this.$t('ATLAS.COMMENTS_MANAGER.COMMENTS'),
            });

            this.commentsLayer = new GraphicsLayer();

            if(!this.isInterestAreaVisible) {
                GIS.view.ui.add(this.expandComments, position);
            }

            this.expandComments.watch('expanded', (expanded) => {
                if(expanded) {
                    GIS.view.map.add(this.commentsLayer);
                    // arata comentarii doar pe nivel UAT
                    if(!this.commentsVisibleByZoom()) return;

                    this.redrawComments();
                    this.showCommentsSidebar();
                } else {
                    this.commentsLayer.removeAll();
                    GIS.view.map.remove(this.commentsLayer);
                    this.hideCommentsSidebar();
                }
            });

            reactiveUtils.watch(
            () => GIS.view?.stationary === true,
            () => {
                    // arata comentarii doar pe nivel UAT
                    if(!this.isInterestAreaVisible && this.expandComments.expanded && this.commentsVisibleByZoom()) {
                        this.redrawComments();
                        this.showCommentsSidebar();
                    } else {
                        this.commentsLayer.removeAll();
                        this.hideCommentsSidebar();
                    }
                }
            );

            this.doubleclickEventHandler = GIS.view.on(
                "immediate-double-click",
                (event) => {
                    if(this.isInterestAreaVisible || !this.expandComments.expanded || !this.commentsVisibleByZoom()) {
                        return;
                    }

                    const that = this;
                    event.stopPropagation();
                    GIS.view.hitTest(event).then(function (response) {
                        let existingCommentClicked = false;

                        response.results.forEach(hit => {
                            // existing comment pin clicked
                            if(hit.graphic?.attributes?.type == "customCommentPin") {
                                // this happens if a "new" pin with no comments is clicked again
                                if(!hit.graphic?.attributes?.commentId) {return}

                                that.resetComments();
                                that.getComments({parentId: hit.graphic.attributes.commentId});
                                that.showCommentsSidebar();

                                if(that.activeCommentGraphic?.attributes?.commentId) {
                                    that.resetActiveCommentGraphic();
                                } else {
                                    that.commentsLayer.remove(that.activeCommentGraphic);
                                }

                                that.addActiveCommentGraphic(hit.graphic);
                                existingCommentClicked = true;
                                return;
                            }
                        });

                        // new point selected on the map
                        if(!existingCommentClicked) {
                            that.resetComments();

                            if(that.activeCommentGraphic?.attributes?.commentId) {
                                that.resetActiveCommentGraphic();
                            } else {
                                that.commentsLayer.remove(that.activeCommentGraphic);
                            }

                            projection
                            .load()
                            .then(() => {
                                let point = new Point({
                                    x: event.mapPoint.x,
                                    y: event.mapPoint.y,
                                    spatialReference: 102100,
                                });

                                const outSpatialReference = new SpatialReference({
                                    wkid: 31700, //Stereo70 ?
                                });
                                let newCommentPoint = projection.project(
                                    point,
                                    outSpatialReference
                                );

                                let newCommentPointCoordinates = {
                                    x: newCommentPoint.x,
                                    y: newCommentPoint.y,
                                }

                                that.activeCommentGraphic = that.createCommentGraphic(newCommentPointCoordinates);
                                that.commentsLayer.add(that.activeCommentGraphic);
                                that.setNewCommentCoordinates(newCommentPointCoordinates);
                                that.showCommentsSidebar();
                            }).catch((error) => {
                                console.error("Failed to load projection ", error);
                            });
                        }
                    });
                }
            );
        });
    },
    commentsVisibleByZoom() {
        return GIS.view.zoom > COMMENTS_ZOOM_LEVEL;
    },
    async redrawComments() {
        projection
        .load()
        .then(() => {
            const outSpatialReference = new SpatialReference({
                wkid: 31700, //Stereo70 ?
            });
            const stereo70Extent = projection.project(
                GIS.view.extent.extent,
                outSpatialReference
            );
            this.setCommentsExtent(stereo70Extent);
            this.getComments({extent: stereo70Extent});
        });
    },
    async displayComments(filteredComments) {
        if(!this.commentsLayer) return;
        const graphics = [];

        filteredComments?.forEach(comment => {
            try {
                const coords = comment.coordinates?.pop();
                if (!coords) return;
                graphics.push(this.createCommentGraphic(coords, comment));
            } catch {}
        });

        this.commentsLayer.removeAll();
        if(!graphics?.length) return;
        this.commentsLayer.addMany(graphics);
    },
    createCommentGraphic(coords, comment) {
        const pointText = {
            //Create a point
            type: "point",
            longitude: coords.x,
            latitude: coords.y,
            spatialReference: {
                wkid: 31700,
            },
        };

        let usedCommentSymbol = commentSymbol;
        if(comment && comment.id == this.commentThreadId) {
            usedCommentSymbol = activeCommentSymbol;
        } else if (!comment) {
            usedCommentSymbol = newCommentSymbol;
        }

        const commentPoint = new Graphic({
            geometry: pointText,
            symbol:  usedCommentSymbol,
            attributes: {
                type: "customCommentPin",
                commentId: comment?.id,
            }
        });

        if(comment?.id == this.commentThreadId) {
            this.activeCommentGraphic = commentPoint;
        }

        return commentPoint;
    },
    addActiveCommentGraphic(graphic) {
        this.activeCommentGraphic = graphic.clone();
        this.activeCommentGraphic.symbol = activeCommentSymbol;
        this.commentsLayer.remove(graphic);
        this.commentsLayer.add(this.activeCommentGraphic);
    },
    resetActiveCommentGraphic() {
        if(this.activeCommentGraphic?.attributes?.commentId) {
            const commentExists = this.mapComments.some(v => {
                return v.id == this.activeCommentGraphic.attributes.commentId;
            });
            // this can happen if comment thread is deleted
            if(!commentExists) {
                return;
            }
        }
        const restoredGraphic = this.activeCommentGraphic.clone();
        restoredGraphic.symbol = commentSymbol;
        this.commentsLayer.remove(this.activeCommentGraphic);
        this.commentsLayer.add(restoredGraphic);
    },
    extentWatch() {
        GIS.view.when(() => {
            const mapSpatialRef = new SpatialReference({
                wkid: GIS.view.extent.extent.spatialReference.wkid,
            });

            projection
            .load()
            .then(() => {
              reactiveUtils.watch(
                () => GIS.view?.stationary === true,
                () => {
                    if (!this.interestAreaSelected) {
                      Object.values(this.selectedLayers)?.forEach((layerValues) => {
                        if (layerValues.visible) {
                          const subLayers = layerValues.sublayers;

                          Object.keys(subLayers).forEach((sublayerGisId) => {
                            try {
                              if (!this.layerList[layerValues.gis_layer_id] || !this.layerList[layerValues.gis_layer_id][sublayerGisId]) {
                                return;
                              }

                              const sublayerWkID = this.layerList[layerValues.gis_layer_id][sublayerGisId].fullExtent?.spatialReference?.wkid;

                              let subLayerExtent;
                              if(GIS.view.extent.extent.spatialReference.wkid != sublayerWkID) {
                                if(this.layerList[layerValues.gis_layer_id][sublayerGisId].projectedFullExtent) {
                                  subLayerExtent = this.layerList[layerValues.gis_layer_id][sublayerGisId].projectedFullExtent;
                                } else {
                                  subLayerExtent = projection.project(
                                    this.layerList[layerValues.gis_layer_id][sublayerGisId].fullExtent,
                                    mapSpatialRef
                                  );

                                  this.layerList[layerValues.gis_layer_id][sublayerGisId].projectedFullExtent = subLayerExtent;
                                }
                              } else {
                                subLayerExtent = this.layerList[layerValues.gis_layer_id][sublayerGisId].fullExtent
                              }

                              this.layerList[layerValues.gis_layer_id][sublayerGisId].visible = subLayers[sublayerGisId].visible
                                    && GIS.view.extent.extent.intersects(subLayerExtent);
                            } catch (error) {
                              //console.log('Error in extentWatch(),', error);
                            }
                          });
                        }
                      });
                    }
                }
              );
            });
        });
    },
    timeSliderWatch() {
        GIS.view.when(() => {
            // TODO: investigate why this fires twice
            reactiveUtils.watch(
                () => GIS.map.allLayers.every((layer) => layer.loaded),
                () => {
                    this.calculateTimesliderStartEndDate();
                }
            );
        });
    },
    addSearchWidget(view, position) {
      const search = new Search({
        sources: [{
          url: mapLocatorUrl,
          countryCode: 'RO',
        }],
        includeDefaultSources: false,
        view: view,
      });
      view.ui.add(search, { position });
    },
    addSearchExpandWidget(view, position) {
      const search = new Search({
        sources: [{
          url: mapLocatorUrl,
          countryCode: 'RO',
        }],
        includeDefaultSources: false,
        view: view,
      });
      const expand = new Expand({
        view: view,
        content: search,
        expandIconClass: "esri-icon-search",
        mode: 'floating',
      })
      view.ui.add(expand, { position })
    },
    addSearchByDateRangeWidget(position) {
        this.expandDateRange = new Expand({
            view: GIS.view,
            content: document.getElementById("dateRangeWidget"),
            expandIconClass: "esri-icon-calendar",
            mode: 'floating',
        });
        GIS.view.ui.add(this.expandDateRange, position);

        this.expandDateRange.watch('expanded', () => {
            if(!this.expandDateRange.expanded) {
                // Reset search by date range on close widget
                this.dateRange = {
                    start: '',
                    end: this.today
                }
                this.loadedFeatureLayers.forEach(layer => {
                    this.filterLayerView(layer)
                });
            }
        });
        return new Promise((resolve, reject) => {
            this.expandDateRange.when(() => {
                this.expandDateRange.visible = false;
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    addParcelSearch(position) {
        // small parcel search btn is only on the public view, where there's no menuitem in the left sidebar
        if(!this.isAtlasPublicView) return;

        this.parcelSearchExpand = new Expand({
            view: GIS.view,
            expandIconClass: "esri-icon-search",
            collapseIconClass: "esri-icon-collapse",
            container: document.createElement("div"),
            expandTooltip:  this.$t('ATLAS.PARCEL_SEARCH.SHOW_PARCEL_SEARCH'),
            collapseTooltip:  this.$t('ATLAS.PARCEL_SEARCH.HIDE_PARCEL_SEARCH'),
        });
        GIS.view.ui.add(this.parcelSearchExpand, position);
        this.setParcelSearchExpandRef(this.parcelSearchExpand);
        this.parcelSearchExpand.watch('expanded', () => {
            if(!this.parcelSearchExpand.expanded) {
                this.setParcelSearchVisibility(false);
            } else {
                this.setParcelSearchVisibility(true);
            }
        });
    },
    onPeriodUpdate(dateRange) {
        if (dateRange.start && dateRange.end) {
            this.loadedFeatureLayers.forEach(layer => {
                this.filterLayerView(layer, dateRange)
            });
        }
    },
    filterLayerView(layer, dateRange) {
        GIS.view.whenLayerView(layer).then((layerView) =>{
            let where = '';
            if (dateRange) {
                const start = dateRange.start;
                const end = dateRange.end;
                const endDate = new Date(end);
                const endDateFormated = moment(endDate).add(1, 'days').format('YYYY-MM-DD');
                where = "application_request_date >= '"+ start +"' AND application_request_date <= '"+ endDateFormated +"'";
            }
            layerView.filter = new FeatureFilter({
                where
            });
        });
    },
    initialExtentParams () {
        return new Promise((resolve, reject) => {
            const token = this.token;
            const feature_service_url = window.VUE_ATLAS_BASE_LAYER;

            esriConfig.request.interceptors.push({
                urls: feature_service_url,
                before: function (params) {
                    params.requestOptions.query = params.requestOptions.query || {};
                    params.requestOptions.query.token = token
                },
                error:(error) => {
                    reject(error);
                }
            });
            const featureLayer = new FeatureLayer({
                url: feature_service_url
            });

            featureLayer.queryExtent()
            .then((results) =>{
                resolve(results);
            }).catch((error) => {
                reject(error);
            });
        })
    },
    addCustomSearchBar(view, position) {
        this.searchObj = new Search({
            view: view,
            locationEnabled: false,
            sources: [],
            allPlaceholder: this.$t('ATLAS.POPUP_TEMPLATE_INFO_FIELDS.PLACEHOLDER'),
            includeDefaultSources: false,
            visible: false
        });
        view.ui.add(this.searchObj, { position });
        return new Promise((resolve, reject) => {
            this.searchObj.when(() => {
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    loadSystemLayers() {
        GIS.view.when(() => {
            const selectedSystemLayers = this.visibleSystemLayers({
                systemLayers: this.initialSystemLayers,
                selectedLayers: this.selectedLayers
            });
            if (selectedSystemLayers.length) {
                this.configurePopupTemplate({layersLoaded: selectedSystemLayers, layerList: this.layerList});

                this.showCustomSearchBar(selectedSystemLayers)
                this.expandDateRange.visible = true;
            } else {
                this.expandDateRange.visible = false;
            }
        });
    },
    showCustomSearchBar(layers) {
      const token = this.token;
      let sources = [];
      layers.forEach(layer => {
        const feature_service_url = layer.feature_service_url;
        esriConfig.request.interceptors.push({
            urls: feature_service_url,
            before: (params) => {
                params.requestOptions.query = params.requestOptions.query || {};
                params.requestOptions.query.token = token
            },
        });
        const sublayers = layer.sublayers;
        if(sublayers.length) {
            const layerSources = [];
            sublayers.forEach(element => {
                if (!this.layerList[layer.gis_layer_id]) {
                    return;
                }
                layerSources.push({
                    layer: this.layerList[layer.gis_layer_id][element.gis_id],
                    searchFields: [
                      'uat_name',
                      'uat_name_ascii',
                      'cadastral_number',
                      'application_request_number',
                      'searchable_application_number',
                      'combined_uat_cadastral',
                      'combined_cadastral_uat',
                      'combined_half_cadastral_uat',
                      'combined_uat_cadastral_ascii',
                      'combined_cadastral_uat_ascii',
                      'half_cadastral_uat_ascii',
                    ],
                    suggestionTemplate: "{uat_name} {cadastral_number} , {application_request_number} {application_number}",
                    displayField: "cadastral_number",
                    exactMatch: false,
                    outFields: ["*"],
                    name: `${layer.name} - ${element.name}`,
                    placeholder: this.$t('ATLAS.POPUP_TEMPLATE_INFO_FIELDS.CADASTRAL_NUMBER'),
                    maxResults: 6,
                    maxSuggestions: 6,
                    suggestionsEnabled: true,
                    // popupTemplate: {
                    //     title: "{cadastral_number}",
                    //     content: this.fieldsInfoContent()
                    // }
                })
            });
            sources = [...sources, ...layerSources];
          }
        });

        GIS.view.when(() => {
            if (sources.length) {
                this.searchObj.sources = sources;
                this.searchObj.visible = true;
            }
        })
    },
    getAllVisibleLayers() {
        return Object.entries(this.layerList).map((el) => ({
            id: el[0],
            sublayers: Object.values(el[1]).filter((layer) => layer?.visible),
            sublayer_ids: Object.entries(el[1]).map((layerMap) => layerMap[1].visible ? layerMap[0] : null).filter(v => v),
        })) || [];
    },
    findLayerByGIsID(id) {
        return this.allLayers.find(v => v.gis_layer_id == id);
    },
    visibleSystemLayers ({systemLayers, selectedLayers}) {
        return systemLayers.filter((el)=> selectedLayers[el.gis_layer_id] && selectedLayers[el.gis_layer_id]?.visible && el.has_index);
    },
    hidePopup() {
        GIS.view.popup.close();
    },
    hideMeasuredLayers() {
        this.updateMeasuredLayersLoading({
            loading: false,
            measurement: {}
        });
    },
    zoomToCoordonates (x, y, zoom) {
      this.mapObj.view.when(() => {
        projection
        .load()
        .then(() => {
            let opts = {
                duration: 3000, // Duration of animation will be 3 seconds
            };
            let zoomPointStereo70 = new Point({
                x: x,
                y: y,
                spatialReference: 31700,
            });
            const outSpatialReference = new SpatialReference({
                wkid: 31700, //Stereo70 ?
            });
            let defaultSpatialProjectionZoomPoint = projection.project(
                zoomPointStereo70,
                outSpatialReference
            );
            this.mapObj.view.goTo({
                target: defaultSpatialProjectionZoomPoint,
                zoom: zoom ?? 15,
            }, opts);
        }).catch((error) => {
            console.error("Failed to load projection ", error);
        });
      });
    },
    zoomToInitialExtent() {
        this.initialExtentParams()
        .then((results) =>{
            const extent = results?.extent;
            if(extent) {
                this.mapObj.view.when(() => {
                    this.mapObj.view.extent = extent;
                });
            }
        })
    },
  },
  async mounted() {
    this.loadMap(this.defaultExtendParams);
    this.checkRequestedLayerForPreviewParcel();
    this.loadAtlasPreviewParcel();

    this.zoomToInitialExtent();
  },
  async created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if(mutation.type === "atlas/sidebar/setSelectedMenu") {
          // Load interest areas
          if (mutation.payload !== 'interest-areas') {
              if (mutation.payload !== 'parcel-search' || this.interestAreaSelected) {
                this.clearLayers();
              }
              this.interestAreaSelected = false;
          } else {
            this.zoomToInitialExtent();
            this.setActiveWidget('area');
          }

          if (mutation.payload == 'parcel-search') {
            this.parcelSearchSelected = true;
            this.checkParcelSearchLayer();
          } else {
            this.parcelSearchSelected = false;
          }
      }
      if (mutation.type === "atlas/interest-areas/setAreas") {
        this.clearLayers();
        this.loadAreas();
      }
      if (mutation.type === "atlas/interest-areas/addArea") {
        this.loadAreas();
      }
      if (mutation.type === "atlas/interest-areas/removeArea") {
        this.removeArea(mutation.payload);
      }
      if (mutation.type === "atlas/layer-manager/setVisibleLayer") {
        this.addLayer(mutation.payload);
      }
      if (
        mutation.type === "atlas/layer-manager/removeVisibleLayer" ||
        mutation.type === "atlas/layer-manager/removeLayer"
      ) {
        this.removeLayer(mutation.payload);
      }
      if (mutation.type === "atlas/layer-manager/setVisibleSublayer") {
        this.addSublayer(mutation.payload);
      }
      if (mutation.type === "atlas/layer-manager/removeVisibleSublayer") {
        this.removeSublayer(mutation.payload);
      }
      if (mutation.type === "atlas/layer-manager/setVisibleLayers") {
        mutation.payload.forEach((layer) => {
          this.addLayer(layer, 'setVisibleLayers');
        });
        this.calculateTimesliderStartEndDate();
      }
      if (mutation.type === "atlas/layer-manager/removeVisibleLayers") {
        mutation.payload.forEach((layer) => {
          this.removeLayer(layer, 'removeVisibleLayers');
        });
        this.calculateTimesliderStartEndDate();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style lang="scss">
/* esri styles */
@import url("https://js.arcgis.com/4.26/esri/themes/light/main.css");
.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: 0;
}
.map-view-container {
  height: inherit;

  #viewDiv {
    height: inherit;
  }

  .esri-popup .esri-popup-header .esri-title {
    font-size: 18px;
    font-weight: bolder;
  }

  .esri-popup .esri-popup-body .esri-popup-content {
    font-size: 14px;
  }

  #measurementWidget {
    background: #fff;
    padding: 10px;
  }

  .action-button {
    font-size: 16px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
    color: #6e6e6e;
    height: 32px;
    width: 32px;
    text-align: center;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }

  .action-button:hover,
  .action-button:focus {
    background: #0079c1;
    color: #e4e4e4;
  }

  .active {
    background: #0079c1;
    color: #e4e4e4;
  }

  #dateRangeWidget {
    width: 250px;
  }
  #timeSlider {
    position: absolute;
    right: 0;
    bottom: 20px;
  }
}

.atlas-sidebar-public-view {
  z-index: 900;
}
</style>
