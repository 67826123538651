import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';
import { storeAsync } from '@/app-module';

export class PublishSimpleLayerField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        const store = storeAsync();
        return new RelationToOneField(
            name,
            label,
            (params) => {
                const isSuperAdmin = store.getters['auth/isSuperAdmin'];
                const accountDetails = store.getters['auth/accountDetails'];

                let query;
                if(isSuperAdmin) {
                    query = `container_id eq ${params.parent_id}`;
                } else {
                    query = `container_id eq ${params.parent_id} and owner_org_id eq ${accountDetails.organisation_id}`;
                }

                return new Promise((resolve) => {
                    if(params.parent_id) {
                        ApiService.query('layers-simple/me', {params: { query, limit: 100}})
                        .then(({ data }) => {
                            resolve(data?.data ?? [])
                        });
                    } else {
                        resolve([]);
                    }
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
