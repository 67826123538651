import TimeSlider from "@arcgis/core/widgets/TimeSlider";
import TimeInterval from "@arcgis/core/TimeInterval";
import Expand from "@arcgis/core/widgets/Expand";

import moment from 'moment';
export default {
    data() {
        return {
            timeSlider: {},
            minDate: '',
            maxDate: '',
        }
    },
    methods: {
        addTimeSlider(position) {
            const timeInterval= new TimeInterval({
                unit: 'days',
                value: 1
            })

            const defaultStartDate = new Date(2022, 0, 1);
            const defaultEndDate = new Date();
            defaultEndDate.setDate(defaultEndDate.getDate() + 1);
            defaultEndDate.setHours(0, 0, 0, 0)

            this.mapObj.view.when(() => {
                this.timeSlider = new TimeSlider({
                    container: "timeSlider",
                    view: this.mapObj.view,
                    mode: 'time-window',
                    timeVisible: true,
                    fullTimeExtent: {
                        start: defaultStartDate,
                        end: defaultEndDate
                    },
                    timeExtent: {
                        start: defaultStartDate,
                        end: defaultEndDate
                    },
                    stops: {
                        interval: timeInterval
                    },
                    visible: false
                });

                this.calculateTimesliderStartEndDate(true);

                this.addTimeSliderButton(position);
            });
        },
        calculateTimesliderStartEndDate(init=false) {
            // no need to continue if everything is hidden on the map
            if(
                !init &&
                (!this.sublayersList?.length || !this.mapObj.map?.allLayers?.length)
            ) return;

            let maxArray = [];
            let minArray = [];

            this.mapObj.map.allLayers.filter(l => l.type == 'feature' && l.visible && l.loaded).forEach(layer => {
                const fullTimeExtent = layer.timeInfo?.fullTimeExtent;
                if (fullTimeExtent) {
                    maxArray.push(fullTimeExtent.end);
                    minArray.push(fullTimeExtent.start);
                }
            });

            // leave the timeslider alone if every time sensible layer is hidden
            if (!init && !maxArray.length) return;

            const maxDatesList = maxArray.map(date => moment(date));
            const minDatesList = minArray.map(date => moment(date));

            this.maxDate = moment.max(maxDatesList).add(1, 'days').startOf('day');
            this.minDate = moment.min(minDatesList).startOf('day');

            this.timeSlider.fullTimeExtent.start = this.minDate.toDate();
            this.timeSlider.fullTimeExtent.end = this.maxDate.toDate();

            this.timeSlider.timeExtent.start = this.minDate.toDate();
            this.timeSlider.timeExtent.end = this.maxDate.toDate();
        },
        addTimeSliderButton(position) {
            const timeSliderButton = new Expand({
                view: this.mapObj.view,
                content: document.getElementById("timeSliderWidget"),
                expandIconClass: "esri-icon-time-clock",
                mode: 'floating',
            });
            this.mapObj.view.ui.add(timeSliderButton, position);
            timeSliderButton.watch('expanded', () => {
                if (timeSliderButton.expanded) {
                    this.timeSlider.visible = timeSliderButton.expanded;
                } else {
                    // Reset time extent before close the time slider
                    this.timeSlider.fullTimeExtent.start = this.minDate.toDate();
                    this.timeSlider.fullTimeExtent.end = this.maxDate.toDate();

                    this.timeSlider.timeExtent.start = this.minDate.toDate();
                    this.timeSlider.timeExtent.end = this.maxDate.toDate();

                    this.timeSlider.visible = timeSliderButton.expanded;
                }
            })
        },
    }
}
