import Polygon from "@arcgis/core/geometry/Polygon";
import polylabel from 'polylabel';

export const getCentroid = (coordinates) => {
    const polygonRings = [];

    coordinates.forEach((el) => {
        polygonRings.push([el.x, el.y]);
    });

    const polygon = new Polygon({
        type: "polygon",
        rings: polygonRings,
        spatialReference: {
            wkid: 31700,
        },
    });

    let centerPointX;
    let centerPointY;

    if(polygon.contains(polygon.centroid)) {
        centerPointX = polygon.centroid.x;
        centerPointY = polygon.centroid.y;
    } else {
        // if the arcgis calculated centroid falls outside of the actual (irregular)polygon, use "polylabel" to find a better centroid.
        const centerPointPL = polylabel(polygon.rings, 1.0, false);
        centerPointX = centerPointPL[0];
        centerPointY = centerPointPL[1];
    }

    return { x: centerPointX, y: centerPointY };
}

export const getPolygonCentroid = (polygon) => {
    let centerPointX;
    let centerPointY;

    if(polygon.contains(polygon.centroid)) {
        centerPointX = polygon.centroid.x;
        centerPointY = polygon.centroid.y;
    } else {
        const centerPointPL = polylabel(polygon.rings, 1.0, false);
        centerPointX = centerPointPL[0];
        centerPointY = centerPointPL[1];
    }

    return { x: centerPointX, y: centerPointY };
}
