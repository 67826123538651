<template lang="">
    <div>
        <portal to="atlas-sidebar-title">
          <span>{{ $t('ATLAS.APPLICATIONS_MANAGER.TITLE') }}</span>
        </portal>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: 'ApplicationManager',
    methods: {
        ...mapActions({
            saveSelectedMenu: 'atlas/sidebar/saveSelectedMenu',
        }),
    },
    created() {
        this.saveSelectedMenu('applications');
    }
}
</script>
<style lang="">
    
</style>