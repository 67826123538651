<template>
    <div>
        <st-modal
            ref="modal"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            hide-header-close
            size="lg"
            :id="modalId"
        >
            <template #header>
                <p class="font-size-h6 font-weight-bold mb-0 pb-0">
                    {{ $t('ATLAS.INTEREST_AREAS_MANAGER.EDIT_NEW_ZONE_TITLE') }}
                </p>
            </template>
            <template #body>
                <InterestAreaForm :id="modalId" :polygon="area" @close="hide" :editMode="true"/>
            </template>
        </st-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { generateRandomId } from "@/core/helpers/globalMethods";

export default {
    inheritAttrs: false,
    name: 'InterestAreaEditModal',
    props: {

    },
    components: {
        InterestAreaForm: () => import(/* webpackChunkName: "atlas" */ '@/modules/atlas/components/interest-areas-manager/InterestAreaForm')
    },
    data() {
        return {
            modalId: `st-modal-edit-interest-area-${generateRandomId()}`,
        };
    },
    computed: {
        ...mapGetters({
            area: 'atlas/interest-areas/editingArea',
        }),
    },
    methods: {
        ...mapActions({
            hideEditModal: 'atlas/interest-areas/hideEditModal',
        }),
        show() {
            this.$refs["modal"].show();
        },
        hide() {
            this.$refs["modal"].hide();
            this.hideEditModal();
        },
    },
};
</script>

