import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';

const label = (name) => i18n.t(`ATLAS.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`ATLAS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    name: new StringField('name', label('template_name') ),
    geometryType: new StringField('geometryType', label('geometry_type') ),
    status: new EnumField(
        'status',
        label('status'),
        [
            { value: 'ok', text: enumLabel('status', 'ok'), type: 'success', name: 'ok' },
            { value: 'missing', text: enumLabel('status', 'missing'), type: 'danger', name: 'missing' },
            { value: 'new', text: enumLabel('status', 'new'), type: 'primary' , name: 'new'},
        ],
    ),
};

export class TemplateLayerModel extends GenericModel {
    static get templateFields() {
        return fields;
    }
};
