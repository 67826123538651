<template>
    <div>
        <div class="row p-8">
            <div class="col-12 col-md-4">
                <p class="font-size-h6 font-weight-bold mb-8 pb-0">
                   {{ $t('ATLAS.LAYER_MANAGER.UPDATE_LAYER_TITLE') }}
                </p>
                 <form
                    class="form"
                    novalidate="novalidate"
                    id="st_modal_edit_simple_layer_form"
                >
                    <div>
                        <label> {{ fields.name.label }} * </label>
                       <div class="form-group">
                           <st-input-text
                               v-model="model[fields.name.name]"
                               :ref="fields.name.name"
                               :name="fields.name.name"
                           />
                       </div>
                    </div>
                    <div>
                        <b-form-checkbox
                            size="md"
                            v-model="model[fields.can_access_citizen.name]"
                            :value="true"
                            :unchecked-value="false"
                        >
                            {{ fields.can_access_citizen.label }}
                        </b-form-checkbox>
                    </div>
                    <div>
                        <b-form-checkbox
                            size="md"
                            v-model="model[fields.can_access_staff.name]"
                            :value="true"
                            :unchecked-value="false"
                        >
                            {{ fields.can_access_staff.label }}
                        </b-form-checkbox>
                    </div>
                </form>
                <st-button
                    size="md"
                    variant="primary"
                    :callback="doSubmit"
                    :spinner="isLoading"
                    class="mt-8"
                >
                    <span>{{ $t("GENERAL.BUTTON.SAVE") }}</span>
                </st-button>
            </div>
            <div class="col-12 col-md-8">
                <p class="font-size-h6 font-weight-bold mb-0 pb-0">
                    {{ $t('ATLAS.EDIT_MODAL.SUBLAYERS_LIST') }}
                </p>
                <div v-if="items.length" class="ml-5">
                    <b-table :items="items" :fields="tableFields"></b-table>
                </div>
                <div v-else class="text-center my-6">
                    <span class="font-size-h5">
                        {{ $t('ATLAS.SUBLAYER_MANAGER.EMPTY_SUBLAYERS_TEXT') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { AtlasLayerModel } from "@/modules/atlas/models/atlas-layer-model";
import { FormSchema } from "@/shared/form/form-schema";
import { mapGetters, mapActions } from "vuex";

const { fields } = AtlasLayerModel;
const formSchema = new FormSchema([
    fields.name,
    fields.can_access_citizen,
    fields.can_access_staff,
]);

export default {
    name: "SimpleLayerEdit",
    props: {
        tableFields: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        containerItems: {
            type: Array,
            default: () => [],
        },
        layers: {
            type: Array,
            default: () => [],
        },
        layer: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            isLoading: false
        };
    },
    computed: {
        ...mapGetters({
            simpleLayers: "atlas/layer-manager/simpleLayers",
        }),
    },
    created() {
        this.model = formSchema.initialValues(this.layer);
    },
    mounted() {
        this.fv = createFormValidation('st_modal_edit_simple_layer_form', this.rules);
    },
    methods: {
        ...mapActions({
            updateLayerFromContainer: 'atlas/layer-manager/updateLayerFromContainer',
            updateOrgLayer: "atlas/layer-manager/updateLayerContainer",
            hideEditModal: "atlas/layer-manager/hideEditModal",
        }),
        async doSubmit() {
            const layers = this.layer.container_id ? this.containerItems : this.simpleLayers;
            const uniqueName = layers.some((el) => {
                // In edit mode, same name is accepted
                if (el.id === this.layer.id) return false;
                return el.name === this.model.name
            });

            if (uniqueName) {
                this.fv.resetField('name');
                this.fv.addField('name', {
                    validators: {
                        callback: {
                            message: this.$t('ATLAS.EDIT_MODAL.DUPLICATE_LAYER_NAME'),
                            callback: (input)=> {
                                return !layers.some((el) => el.name === input.value);
                            },
                        },
                    }
                });
                this.fv.revalidateField('name');
            }
            const validate = await this.fv.validate();
            if (validate === "Valid") {
                this.updateLayer(this.layer)
            }
        },
        updateLayer(layer) {
            const payload = {
                access_level: layer.access_level,
                layer_type: layer.layer_type,
                name: this.model.name,
                owner_org_id: layer.owner_org_id,
                ru_scope: layer.ru_scope,
                uat_id: layer.uat_id,
                uat_name: layer.uat_name,
                can_access_citizen: this.model.can_access_citizen,
                can_access_staff: this.model.can_access_staff,
            }
            if(layer.container_id) {
                // update container layer
                payload.container_id = layer.container_id;
                payload.owner_org_name = layer.owner_org_name;
                payload.decision_id = layer.decision_id;

                this.updateLayerFromContainer({ id:layer.id, layer: payload})
                .then((data)=> {
                    const layerContainer = this.layers.find((item) => item.id === layer.container_id);
                    const layerIndex = this.layers.findIndex(item =>  item.id === layer.container_id);
                    if (layerContainer) {
                        const components = layerContainer.components;
                        const componentIndex = components.findIndex(item =>  item.id === layer.id);
                        components[componentIndex] = data;
                        this.$set(this.layers, layerIndex, {...layerContainer, components});
                    }
                    this.hideEditModal();
                    this.$notify({
                        type: 'success',
                        message: this.$t('GENERAL.UPDATED_SUCCESS'),
                    });
                })

            } else {
                // update organisation layer
                this.updateOrgLayer({layer, payload})
                .then(()=> {
                    this.$notify({
                        type: 'success',
                        message: this.$t('GENERAL.UPDATED_SUCCESS'),
                    });
                })
            }
        }
    }

};
</script>

