<template>
    <div>
        <st-modal
            id="st_layer_details-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="st-layer_details-modal"
            customClass="form-modal"
        >
            <template #header>
                <div class="text-center w-100">
                    <h5>{{ layer.name }} </h5>
                </div>
            </template>
            <template #body>
                <h5 class="mb-6">{{ $t('ATLAS.LAYER_DETAILS.GENERAL_INFO') }}</h5>
                <ul class="list-unstyled">
                    <dl class="row mx-0 my-0 px-0" v-for="(field, index) in generalInfoFields" :key="index">
                        <dt class="col-sm-5 px-0">{{ $t(`ATLAS.LAYER_DETAILS.${field.toUpperCase()}`) }}
                        </dt>
                        <dd class="col-sm-7 px-0">{{ layer[field] ?? 'N/A' }}</dd>
                    </dl>
                </ul>
                <h5 class="mb-6">{{ $t('ATLAS.LAYER_DETAILS.GIS_DETAILS') }}</h5>
                <ul class="list-unstyled mb-0 pb-0">
                    <dl class="row mx-0 my-0 px-0" v-for="(field, index) in gisDetailsFields" :key="index">
                        <dt class="col-sm-5 px-0">{{ $t(`ATLAS.LAYER_DETAILS.${field.toUpperCase()}`) }}</dt>
                        <dd class="col-sm-7 px-0 text-break">{{ layer[field] ?? 'N/A' }}</dd>
                    </dl>
                </ul>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.CLOSE') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
export default {
    name: 'LayerViewModal',
    data() {
        return {
            layer: null,
            generalInfoFields: ['id', 'layer_type', 'access_level', 'container_id','created_date', 'ru_scope' , 'uat_name', 'uat_id', 'owner_org_name', 'ownership_type'],
            gisDetailsFields: ['feature_service_url', 'gis_layer_id']
        }
    },
    methods: {
        hide() {
            this.$refs['st-layer_details-modal'].hide();
        },
        show(data) {
            this.layer = data;
            this.$refs['st-layer_details-modal'].show();
        },
    }
};
</script>
