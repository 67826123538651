<template>
    <div>
        <div class="d-flex justify-content-between align-items-center my-4 mx-4">
            <p class="font-size-base font-weight-bold">{{ $t('ATLAS.INTEREST_AREAS_MANAGER.ADD_NEW_ZONE_TITLE') }}</p>
            <p @click="doHideSidebar"><i class="far fa-times-circle text-dark"></i></p>
        </div>
        <InterestAreaForm :id="modalId" :polygon="polygon" @close="doHideSidebar"/>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateRandomId } from "@/core/helpers/globalMethods";

export default {
    name: 'NewAreaSidebar',
    components: {
        InterestAreaForm: () => import('@/modules/atlas/components/interest-areas-manager/InterestAreaForm'),
    },
    data() {
        return {
            modalId: `st-modal-interest-area-${generateRandomId()}`,
        }
    },
    computed: {
        ...mapGetters({
            polygon: 'atlas/interest-areas/polygon',
        }),
    },
    methods: {
        doHideSidebar() {
           this.$emit("hideSidebar");
        }
    },
}
</script>
