<template>
    <div class="interest-areas">
        <portal to="atlas-sidebar-title">
            <span>{{ $t('ATLAS.INTEREST_AREAS_MANAGER.TITLE') }}</span>
        </portal>
        <interest-areas-container v-if="isLoaded" :areas="areas"/>
        <interest-area-edit-modal ref="interestAreaEditModal"/>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VBTooltip } from 'bootstrap-vue';
import InterestAreasContainer from "@/modules/atlas/components/interest-areas-manager/InterestAreasContainer";
import InterestAreaEditModal from "@/modules/atlas/components/interest-areas-manager/InterestAreaEditModal";

export default {
    name: 'InterestAreasManager',
    directives: {
        'b-tooltip': VBTooltip,
    },
    components: {
        InterestAreasContainer,
        InterestAreaEditModal
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    computed: {
        ...mapGetters({
            areas: 'atlas/interest-areas/areas',
            isAreaEditModalVisible: 'atlas/interest-areas/isAreaEditModalVisible',
        }),
        loading() {
            return false
        }
    },
    watch: {
        isAreaEditModalVisible(newValue) {
            if (newValue) {
                this.$refs.interestAreaEditModal.show();
            } else {
                this.$refs.interestAreaEditModal.hide();
            }
        },
    },
    methods: {
        ...mapActions({
            saveSelectedMenu: 'atlas/sidebar/saveSelectedMenu',
            getInterestAreas: 'atlas/interest-areas/getInterestAreas',
            showNewInterestArea: 'atlas/interest-areas/showNewInterestArea',
            hideSelectedMenu: 'atlas/sidebar/hideSelectedMenu',
        }),

    },
    created() {
        this.getInterestAreas()
        .then(() => {
            this.isLoaded = true;
            this.saveSelectedMenu('interest-areas');
        })
    },
    beforeDestroy() {
        this.hideSelectedMenu();
        this.showNewInterestArea({status: false});
    }
}
</script>
<style lang="scss" scoped>
    .layer-container {
        padding: 0;
        overflow: auto;
    }
</style>
