import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';

const label = (name) => i18n.t(`ATLAS.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`ATLAS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    app_id: new StringField(
        'app_id',
        label('app_id'),
        {
            required: false,
        }
    ),
    app_name: new StringField(
        'app_name',
        label('app_name'),
        {
            required: true,
        }
    ),
    app_url: new StringField(
        'app_url',
        label('app_url'),
        {
            required: true,
            full_url: true,
        }
    ),
    access_level: new EnumField(
        'access_level',
        label('access_level'),
        [
            { value: 'private', text: enumLabel('access_level', 'private') },
            { value: 'public', text: enumLabel('access_level', 'public') },
    ], {
        // required: true,
        autocompleteRequired: true
    }),
};

export class ArcgisAppModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
