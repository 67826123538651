<template>
  <div class="atlas-sidebar"
  :class="[{'atlas-sidebar-public-view': isAtlasPublicView }]"
  >
    <div class="brand" v-if="isAtlasPublicView">
      <div class="brand-logo" role="button">
        <img
          :src="logoImage"
          alt="Logo"
          class="logo-img"
        />
        <div class="brand-name brand-atlas">
          <div class="brand-text">ATLAS</div>
          <div class="brand-text-sub">Teritorial</div>
        </div>
      </div>
    </div>
    <div class="atlas-sidebar-header">
      <div class="atlas-sidebar-title w-100">
        <portal-target name="atlas-sidebar-title" slim></portal-target>
      </div>
      <div
        v-if="
          !isAtlasPublicView ||
          (isAtlasPublicView && isIntersectionManagerVisible)
        "
        class="atlas-sidebar-close"
        @click="hideSidebar"
      >
        <i class="far fa-times-circle text-dark"></i>
      </div>
    </div>
    <template v-if="isAtlasPublicView">
      <layer-manager v-show="!isIntersectionManagerVisible"></layer-manager>
    </template>
    <intersection-manager
      v-show="isIntersectionManagerVisible"
      :isAtlasPublicViewSelected="isAtlasPublicViewSelected"
    ></intersection-manager>
    <router-view
      v-if="!isAtlasPublicView"
    ></router-view>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("atlas/sidebar");

import IntersectionManager from "@/modules/atlas/components/IntersectionManager";
import LayerManager from "@/modules/atlas/components/LayerManager";
export default {
  name: "LeftSidebar",
  components: {
    IntersectionManager,
    LayerManager,
  },
  props: {
    isAtlasPublicViewSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["isIntersectionManagerVisible", "isAtlasPublicView"]),
    logoImage() {
        return `${process.env.BASE_URL}media/logos/logo-GU-CJCJ-stema.png`;
    },
  },
  methods: {
    ...mapActions(["hide", "hideIntersectionManager"]),
    hideSidebar() {
      if (this.isIntersectionManagerVisible) {
        this.hideIntersectionManager();
      } else {
        this.hide();
      }
    },
  },
};
</script>
<style lang="scss">
.atlas-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 360px;
  background-color: #f2f3f7;
  border-right: 1px solid lightgray;
  margin-left: -3px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.16);
}
.atlas-sidebar-header {
  height: 3.75rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  .atlas-sidebar-title {
    font-weight: 500;
  }
  .atlas-sidebar-close {
    margin-left: auto;
    cursor: pointer;
  }
}
.atlas-sidebar-public-view {

  @media (max-width: 360px) {
    width: 300px;
  }

  @media (min-width: 361px) {
    width: 330px;
  }

}
</style>
