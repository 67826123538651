<template>
    <div class="st-area-details">
        <div class="st-area-details-header">
            <div class="row mb-4">
                <div class="col-6 text-dark-50">{{ $t('ATLAS.INTEREST_AREAS_MANAGER.AREA') }}</div>
                <div class="col-6">{{area.surface}} km<sup>2</sup></div>
            </div>
            <div class="row mb-4">
                <div class="col-6 text-dark-50">{{ $t('ATLAS.INTEREST_AREAS_MANAGER.PERIMETER') }}</div>
                <div class="col-6">{{area.perimeter}} km</div>
            </div>
            <div class="row mb-4">
                <div class="col-6 text-dark-50">{{ $t('ATLAS.INTEREST_AREAS_MANAGER.NOTES') }}</div>
                <div class="col-6">{{area.notes}}</div>
            </div>
            <div class="row mb-4">
                <div class="col-6 text-dark-50">{{ $t('ATLAS.INTEREST_AREAS_MANAGER.NOTIFICATIONS') }}</div>
                <div class="col-6"><b>{{notifications}}</b></div>
            </div>
        </div>
        <hr>
        <div class="row mb-4">
            <div class="col-6">
                <st-button :callback="editArea" variant="link">
                    <span class="d-flex align-items-center">
                        <i class="fas fa-edit"></i>
                        {{ $t('GENERAL.BUTTON.UPDATE') }}
                    </span>
                </st-button>
            </div>
            <div class="col-6 text-danger">
                <st-button
                    :callback="() => deleteArea(area)"
                    variant="link"
                    customClass="st-remove-area">
                    <i class="fas fa-trash-alt"></i>
                    {{ $t('GENERAL.BUTTON.ERASE') }}
                </st-button>
            </div>
        </div>
    </div>
</template>

<script>
import {  mapActions } from 'vuex';
export default {
    name: 'AreaDetails',
    components: {  },
    props: {
        area: {
            type: Object,
            default: () => ({ }),
        },
    },
    computed: {
        notifications () {
            return this.area.notification_enabled ? 'Active' : 'Inactive'
        }
    },
    methods: {
         ...mapActions({
            doDeleteArea: 'atlas/interest-areas/deleteArea',
            showEditModal: 'atlas/interest-areas/showEditModal',
        }),
        editArea() {
            this.showEditModal(this.area);
        },
        deleteArea(area) {
            this.$alert({
                type: 'warning',
                    title: this.$t('ATLAS.INTEREST_AREAS_MANAGER.DELETE_CONFIRMATION_TEXT'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                        this.doDeleteArea(area);
                    }
                });
        },
    },
  };
</script>
