import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';
import { storeAsync } from '@/app-module';

export class OrganizationField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        const store = storeAsync();
        return new RelationToOneField(
            name,
            label,
            (params) => {
                if (params?.listOnlyAccountOrg) {
                    const accountDetails = store.getters['auth/accountDetails'];
                    return new Promise((resolve) => {
                        resolve([{name: accountDetails.organisation_name, id:accountDetails.organisation_id}])
                    })
                } else {
                    return new Promise((resolve, reject) => {
                        ApiService.get('/organisations?query=status eq active&limit=100')
                        .then(({data}) => resolve(data.data))
                        .catch((error) => reject(error));
                    })
                }


            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
